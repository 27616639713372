import EventEmitter from 'eventemitter3';
import { differenceWith } from 'lodash-es';
import { handlerData } from '@services/devicemanager/DeviceManager.handlers.js';
import { isFeatureFlagEnabled } from '@services/featureflags/featureFlags.js';

const { priv } = handlerData; // yuck

// events:
//
// "bluetooth-available", "bluetooth-unavailable"
// "device-list-changed"
// "device-connected"

export class DeviceManager extends EventEmitter {
  constructor({ api = null, dataWorld, permissions } = {}) {
    super();
    this[priv] = {
      api,
      usbDeviceList: [],
      bluetoothDeviceList: [],
      bluetoothAvailable: true,
      bluetoothIsDiscovering: false,
      bluetoothDiscoveryMode: 'gdx', // or lqstream
    };

    console.assert(api);
    const { eventHandlers } = handlerData;
    Object.keys(eventHandlers).forEach(key => this.api.on(key, eventHandlers[key].bind(this)));

    // TODO: (ff-smart-fan) remove this when logic is moved to the back-end
    const updateAllGdxCartSmartFans = () => {
      this.combinedDeviceList
        .filter(device => device.orderCode.startsWith('GDX-CART'))
        .forEach(device => this.updateGdxSmartFanState(device));
    };

    this.hasCollectionPrepared = false;
    dataWorld.on('collection-stopped', () => {
      this.hasCollectionPrepared = false;
      if (isFeatureFlagEnabled('ff-smart-fan')) {
        updateAllGdxCartSmartFans();
      }
    });

    dataWorld.on('collection-preparing', () => {
      this.hasCollectionPrepared = true;
      if (isFeatureFlagEnabled('ff-smart-fan')) {
        updateAllGdxCartSmartFans();
      }
    });
    //

    this.permissions = permissions;
    this.dataWorld = dataWorld;
  }

  get api() {
    return this[priv].api;
  }

  get usbDeviceList() {
    return this[priv].usbDeviceList.slice();
  }

  get bluetoothDeviceList() {
    return this[priv].bluetoothDeviceList.slice();
  }

  get bluetoothAvailable() {
    return this[priv].bluetoothAvailable;
  }

  get combinedDeviceList() {
    return this[priv].usbDeviceList.concat(this[priv].bluetoothDeviceList);
  }

  get bluetoothIsDiscovering() {
    return this[priv].bluetoothIsDiscovering;
  }

  get bluetoothDiscoveryMode() {
    return this[priv].bluetoothDiscoveryMode;
  }

  get hasConnectedDevices() {
    return this.getConnectedDevices().length > 0;
  }

  get connectedBluetoothDevices() {
    const connectedDevices = [];
    this[priv].bluetoothDeviceList.forEach(device => {
      if (device.connected) {
        connectedDevices.push(device);
      }
    });
    return connectedDevices;
  }

  get connectedUsbDevices() {
    const connectedDevices = [];
    this[priv].usbDeviceList.forEach(device => {
      if (device.connected) {
        connectedDevices.push(device);
      }
    });
    return connectedDevices;
  }

  get connectedDataLoggingDevices() {
    return [...this[priv].usbDeviceList, ...this[priv].bluetoothDeviceList].filter(
      device => device.connected && device.supportsOfflineCollection,
    );
  }

  getDevice(deviceId) {
    let found;
    const allDeviceList = this[priv].usbDeviceList.concat(this[priv].bluetoothDeviceList);
    allDeviceList.forEach(device => {
      if (device.id === deviceId) {
        found = device;
      }
    });
    return found;
  }

  getConnectedDevices() {
    const connectedDevices = [];
    const allDeviceList = this[priv].usbDeviceList.concat(this[priv].bluetoothDeviceList);
    allDeviceList.forEach(device => {
      if (device.connected) {
        connectedDevices.push(device);
      }
    });
    return connectedDevices;
  }

  async startBluetoothDiscovery() {
    const result = await this.permissions.requestBluetooth();
    if (result.success) {
      this[priv].bluetoothIsDiscovering = true;
      this[priv].bluetoothDiscoveryMode = 'gdx';
      return this.api.startBluetoothDiscovery(this.dataWorld.experimentId);
    }

    return result;
  }

  stopBluetoothDiscovery() {
    this[priv].bluetoothIsDiscovering = false;
    return this.api.stopBluetoothDiscovery(this.dataWorld.experimentId);
  }

  async startLabquestStreamDiscovery() {
    const result = await this.permissions.requestBluetooth();
    if (result.success) {
      this[priv].bluetoothIsDiscovering = true;
      this[priv].bluetoothDiscoveryMode = 'lqstream';
      return this.api.startLabquestStreamDiscovery(this.dataWorld.experimentId);
    }

    return result;
  }

  stopLabquestStreamDiscovery() {
    this[priv].bluetoothIsDiscovering = false;
    return this.api.stopLabquestStreamDiscovery(this.dataWorld.experimentId);
  }

  discoverLabQuestStream() {
    // This method is called from iOS -- we start lqs discovery. Since this is a one shot modal
    // operation on iOS, we do not need to store any state regarding discovery mode etc.
    // See `startLabquestStreamDiscovery()` above.  JK 20181011.
    return this.api.startLabquestStreamDiscovery(this.dataWorld.experimentId);
  }

  connectBluetoothDevice(device) {
    return this.api.connectBluetoothDevice(this.dataWorld.experimentId, device.id);
  }

  disconnectDevice(device) {
    return this.api.disconnectDevice(this.dataWorld.experimentId, device.id);
  }

  enableDeviceChannel(deviceId, channelId, enable) {
    return this.api.enableDeviceChannel(this.dataWorld.experimentId, deviceId, channelId, enable);
  }

  /**
   * This is a specialised method for enabling spec channels for multi-wavelength setup (e.g., from
   * the wavelength chooser).
   *
   * This method call triggers the creation of a new spec sensor, with the wavelength configured, on
   * the specified channel. This is an asynchornous operation, that gets notified to the front end
   * the normal way. Once the sensor is online (this can be tracked listening to the
   * SensorWorld.sensor-online event), the Sensor object deviceId and channelId properties can be
   * queried to obtain the deviceId and channelId for subsenquent use.
   *
   * To subsequently change the wavelength of the sensor, use the SensorWorld.setSelectedWavelength() method.
   *
   * To disable the channel again, use the standard enableDeviceChannel() method.
   *
   * @param {number} deviceId - id of the device to add the channel to
   * @param {number} channelId - channelId; there are 10 channels with ids starting from 1.
   * @param {number} selectedWavelength - the wavelength to selelect on the sensor attached to the new channel
   */
  async enableSpecChannel(deviceId, channelId, selectedWavelength) {
    return this.api.enableSpecChannel(
      this.dataWorld.experimentId,
      deviceId,
      channelId,
      selectedWavelength,
    );
  }

  identifyDevice(deviceId) {
    return this.api.identifyDevice(this.dataWorld.experimentId, deviceId);
  }

  getGdxSensorMap(deviceId) {
    return this.api.getGdxSensorMap(this.dataWorld.experimentId, deviceId);
  }

  writeDeviceAttributes(deviceId, attributes) {
    return this.api.writeDeviceAttributes(this.dataWorld.experimentId, deviceId, attributes);
  }

  startCalibrationProcess(deviceId, processId) {
    return this.api.startCalibrationProcess(this.dataWorld.experimentId, deviceId, processId);
  }

  startCalibrationProcessStep(deviceId, processId, stepId, inputValue) {
    return this.api.startCalibrationProcessStep(
      this.dataWorld.experimentId,
      deviceId,
      processId,
      stepId,
      inputValue,
    );
  }

  updateCalibrationProcessStep(deviceId, processId, stepId, inputValue) {
    return this.api.updateCalibrationProcessStep(
      this.dataWorld.experimentId,
      deviceId,
      processId,
      stepId,
      inputValue,
    );
  }

  stopCalibrationProcessStep(deviceId, processId, stepId, keep) {
    return this.api.stopCalibrationProcessStep(
      this.dataWorld.experimentId,
      deviceId,
      processId,
      stepId,
      keep,
    );
  }

  cancelCalibrationProcess(deviceId, processId) {
    return this.api.stopCalibrationProcess(this.dataWorld.experimentId, deviceId, processId, false);
  }

  keepCalibrationProcess(deviceId, processId) {
    return this.api.stopCalibrationProcess(this.dataWorld.experimentId, deviceId, processId, true);
  }

  resetFactoryCalibration(deviceId) {
    return this.api.resetFactoryCalibration(this.dataWorld.experimentId, deviceId);
  }

  usingFactoryCalibration(deviceId) {
    return this.api.usingFactoryCalibration(this.dataWorld.experimentId, deviceId);
  }

  // TODO: (ff-smart-fan) remove this and move logic to back-end
  updateGdxCartFanSettings(deviceId, { thrustVal, isFanAlwaysEnabled }) {
    const device = this.combinedDeviceList.find(device => device.id === deviceId);
    console.assert(device.orderCode.startsWith('GDX-CART'));

    // update front-end with fan controls state
    device._update({
      gdxCartFanThrustVal: thrustVal,
      isGdxCartFanAlwaysEnabled: isFanAlwaysEnabled,
    });

    // send back-end request to update fan state
    this.updateGdxSmartFanState(device);
  }

  // TODO: (ff-smart-fan) remove this and move logic to back-end
  updateGdxSmartFanState(device) {
    const { gdxCartFanThrustVal, isGdxCartFanAlwaysEnabled } = device;
    const { hasCollectionPrepared } = this;

    // compute the raw thrust value
    const shouldFanBeEnabled = isGdxCartFanAlwaysEnabled || hasCollectionPrepared;
    const rawFanThrust = shouldFanBeEnabled ? gdxCartFanThrustVal : 0;

    // compute device attribute properties
    const fanMode = rawFanThrust >= 0 ? 0 : 1; // 0 corresponds to 'FWD', 1 to 'REV';
    const fanMagnitude = Math.abs(rawFanThrust);

    //
    // TODO: (smart-fan) re-work this
    const attributeMap = {
      'Auxiliary Port Mode': 1, // setting to use smart-fan
      'Fan Mode': fanMode,
      'FAN PWM': fanMagnitude,
    };

    const attributes = device.deviceAttributes.map(attribute => ({
      attribID: attribute.id,
      value: Object.keys(attributeMap).includes(attribute.description)
        ? attributeMap[attribute.description]
        : attribute.value,
    }));

    /*
    console.log(`Smart-fan-related attribute vals-${[0,3,4].reduce((acc, i) => {
      return `${acc} ${attributes[i].value},`
    }, '')}`);
    */

    // write back-end state
    this.writeDeviceAttributes(device.id, attributes);
  }

  async getGdxDeviceSources() {
    return this.api.getGdxDeviceSources(this.dataWorld.experimentId);
  }

  /**
   * Triggers events that require computation for various device list changes
   * @param {Object} beforeAfterLists Contains device lists before and after some change
   * @param {Function} triggerEvent
   */
  // eslint-disable-next-line class-methods-use-this
  _notifyDeviceListChanged({ to: finalList, from: initialList }, triggerEvent) {
    triggerEvent(`device-list-changed`, finalList); // very broad. this notifys of an addition, removal, or change to any device

    const finalConnectedDevices = finalList.filter(d => d.connected);
    const initialConnectedDevices = initialList.filter(d => d.connected);

    const newlyConnectedDevices = differenceWith(
      finalConnectedDevices,
      initialConnectedDevices,
      (final, initial) => final.id === initial.id,
    );

    const newlyDisconnectedDevices = differenceWith(
      initialConnectedDevices,
      finalConnectedDevices,
      (initial, final) => final.id === initial.id,
    );

    newlyConnectedDevices.forEach(device => {
      triggerEvent('device-connected', device);
    });

    newlyDisconnectedDevices.forEach(device => {
      triggerEvent('device-disconnected', device);
    });
  }

  /**
   * Assigns sensorId to a specific channel on a LabQuest / GoLink device.
   * @param {Number} deviceId numerical id of the device to configure
   * @param {Number} channel channel number to configure; for GoLink this is always 1
   * @param {Number} sensorId numerical sensor id (as per sensor map)
   */
  async setSensorIdForLqChannel(deviceId, channel, sensorId) {
    return this.api.setSensorIdForLqChannel(
      this.dataWorld.experimentId,
      deviceId,
      channel,
      sensorId,
    );
  }
}
