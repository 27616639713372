import EventEmitter from 'eventemitter3';

export class DeviceManagerAPI extends EventEmitter {
  constructor(rpc) {
    super();
    this.rpc = rpc;

    const RPC_METHODS = {};
    [
      'devmgr:bluetooth-available',
      'devmgr:bluetooth-device-discovered',
      'devmgr:bluetooth-device-removed',
      'devmgr:bluetooth-device-updated',
      'devmgr:usb-device-added',
      'devmgr:usb-device-removed',
      'devmgr:usb-device-updated',
      'devmgr:calibration-step-result',
      'devmgr:device-attributes-changed',
      'devmgr:retrieval-progress',
    ].forEach(id => {
      RPC_METHODS[id] = (server, notification) => {
        const { params } = notification;
        this.emit(id, params);
      };
    });

    this.rpc.registerMethods(RPC_METHODS, this);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  startBluetoothDiscovery(experimentId) {
    return this.rpc.sendRequest('start-bluetooth-discovery', { experimentId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  stopBluetoothDiscovery(experimentId) {
    return this.rpc.sendRequest('stop-bluetooth-discovery', { experimentId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  startLabquestStreamDiscovery(experimentId) {
    return this.rpc.sendRequest('start-labquest-stream-discovery', { experimentId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   */
  stopLabquestStreamDiscovery(experimentId) {
    return this.rpc.sendRequest('stop-labquest-stream-discovery', { experimentId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   */
  connectBluetoothDevice(experimentId, deviceId) {
    return this.rpc.sendRequest('connect-bluetooth-device', { experimentId, deviceId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   */
  disconnectDevice(experimentId, deviceId) {
    return this.rpc.sendRequest('disconnect-device', { experimentId, deviceId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   * @param {number} channelId
   * @param {boolean} enable
   */
  enableDeviceChannel(experimentId, deviceId, channelId, enable) {
    return this.rpc.sendRequest('enable-device-channel', {
      experimentId,
      deviceId,
      channelId,
      enable,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId - id of the device to add the channel to
   * @param {number} channelId - channelId; there are 10 channels with ids starting from 1.
   * @param {number} selectedWavelength - the wavelength to selelect on the sensor attached to the new channel
   */
  async enableSpecChannel(experimentId, deviceId, channelId, selectedWavelength) {
    return this.rpc.sendRequest('spec-channel-enable', {
      experimentId,
      deviceId,
      channelId,
      selectedWavelength,
    });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   */
  identifyDevice(experimentId, deviceId) {
    return this.rpc.sendRequest('identify-device', { experimentId, deviceId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   */
  getGdxSensorMap(experimentId, deviceId) {
    return this.rpc.sendRequest('get-gdxdevice-map', { experimentId, deviceId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceID
   * @param {*} attributes
   */
  writeDeviceAttributes(experimentId, deviceID, attributes) {
    const params = {
      experimentId,
      deviceID,
      attributes,
    };
    return this.rpc.sendRequest('write-device-attributes', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   * @param {number} processId
   */
  startCalibrationProcess(experimentId, deviceId, processId) {
    const params = {
      experimentId,
      deviceId,
      processId,
    };
    return this.rpc.sendRequest('start-calibration-process', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   * @param {number} processId
   * @param {*} stepId
   * @param {*} inputValue
   */
  startCalibrationProcessStep(experimentId, deviceId, processId, stepId, inputValue) {
    const params = {
      experimentId,
      deviceId,
      processId,
      stepId,
      inputValue,
    };
    return this.rpc.sendRequest('start-calibration-step', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   * @param {number} processId
   * @param {*} stepId
   * @param {*} inputValue
   */
  updateCalibrationProcessStep(experimentId, deviceId, processId, stepId, inputValue) {
    const params = {
      experimentId,
      deviceId,
      processId,
      stepId,
      inputValue,
    };
    return this.rpc.sendRequest('update-calibration-step', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   * @param {number} processId
   * @param {*} stepId
   * @param {*} keep
   */
  stopCalibrationProcessStep(experimentId, deviceId, processId, stepId, keep) {
    const params = {
      experimentId,
      deviceId,
      processId,
      stepId,
      keep,
    };
    return this.rpc.sendRequest('stop-calibration-step', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   * @param {number} processId
   * @param {boolean} keep
   */
  stopCalibrationProcess(experimentId, deviceId, processId, keep) {
    const params = {
      experimentId,
      deviceId,
      processId,
      keep,
    };
    return this.rpc.sendRequest('stop-calibration-process', params);
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   */
  resetFactoryCalibration(experimentId, deviceId) {
    return this.rpc.sendRequest('reset-factory-calibration', { experimentId, deviceId });
  }

  /**
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   */
  usingFactoryCalibration(experimentId, deviceId) {
    return this.rpc.sendRequest('using-factory-calibration', { experimentId, deviceId });
  }

  /**
   * TODO (smart-fan) add more detail here
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} deviceId
   * @param {string} fanThrust
   */
  // eslint-disable-next-line class-methods-use-this
  setGdxCartSmartFanThrust(experimentId, deviceId, fanThrust) {
    // eslint-disable-next-line no-console
    console.log(`write smart fan to back-end - id: ${deviceId}, thrust: ${fanThrust}`);
  }

  /**
   * Get a list of the gdx device sources stored in the experiment
   * {
   *   name: string
   *   deviceId: 0 or valid device id
   * }
   * @param {number} experimentId - UDM id of the experiment
   * @returns {Array}
   */
  async getGdxDeviceSources(experimentId) {
    return this.rpc.sendRequest('get-gdx-device-sources', { experimentId });
  }

  /**
   * Assigns sensorId to a specific channel on a LabQuest / GoLink device.
   * @param {Number} experimentId - UDM id of the experiment
   * @param {Number} deviceId numerical id of the device to configure
   * @param {Number} channel channel number to configure
   * @param {Number} sensorId numerical sensor id (as per sensor map)
   */
  async setSensorIdForLqChannel(experimentId, deviceId, channel, sensorId) {
    return this.rpc.sendRequest('set-sensor-id-for-lq-channel', {
      experimentId,
      deviceId,
      channel,
      sensorId,
    });
  }
}
