import { sprintf } from '@libs/sprintf.js';
import { EventBinder } from '@utils/EventBinder.js';
import { isEqual, throttle } from 'lodash-es';
import { autorun } from 'mobx';
import { vstLayoutStore } from '@stores/vst-layout.store.js';

import { RTCDataShareCommon } from './RTCDataShareCommon';
import { store } from '../../redux/store.js';

/**
 * Class implementing the application's DataShare server behavior.
 */
export class RTCDataShareHost extends RTCDataShareCommon {
  /**
   * Constructor
   * @param {object} params contains arrays of either RTCDataChannel objects or Peer objects.
   * @param {array} params.clientDataChannels [optional] Array of RTCDataChannel objects
   * @param {array} params.clientPeerConnections [optional] Array of Peer objects.
   * @param {obj} dataCollection dataCollection service
   * @param {obj} dataWorld service
   * @param {obj} api service
   */
  constructor(params) {
    super();

    this.clientDataConnections = [];
    this.runningState = 'stopped';
    this.forceStatusPush = false;
    this.viewTimeCount = 1;
    this.liveValues = {};
    this.isUpdating = false;

    // TBD: we can fine tune the exact timimg for this once we've got everything wired up.
    this.onStatusChanged = throttle(() => this.updateDataMapFromUDM(), 75);

    const { dataCollection, dataWorld, api } = params;
    this.$dataCollection = dataCollection;
    this.$dataWorld = dataWorld;
    this.$api = api;

    const channels = params.clientDataChannels;
    const peers = params.clientPeerConnections;

    if (channels) {
      channels.forEach(chan => this.addDataChannel(chan));
    } else if (peers) {
      peers.forEach(peer => this.addPeerDataConnection(peer));
    }
  }

  /**
   * Resets all crucial properties to default settings. These contain
   * the state specific to per-session data sharing operation.
   */
  _resetProperties() {
    this.importDataMap({});
    this._errorBarInfoCache = {};
    this._oldDataMarks = [];
    this.cachedLayout = {};
    this.cachedGraphs = {};
    this.isUpdating = false;
    this.forceStatusPush = false;
    this.requeueStatus = undefined;
  }

  get connectedPeers() {
    return this.clientDataConnections.length;
  }

  /**
   * Call start to begin running in server mode.
   */
  async start() {
    if (this.runningState !== 'stopped') {
      console.warn('Attempt to start server that is already running.');
      return;
    }
    this.runningState = 'starting';

    this.textEncoder = new TextEncoder();

    this._resetProperties();

    // Bind to data world events
    this.eventBinder = new EventBinder();
    this.eventBinder.bindListeners({
      source: this.$dataWorld,
      target: this,
      eventMap: {
        'session-started': 'onSessionDidStart',
        'session-closing': 'onSessionClosing',
        'is-collecting-changed': 'forceStatusChange',
        'dataset-added': 'onStatusChanged',
        'dataset-removed': 'onStatusChanged',
        'column-added': 'onColumnListChanged',
        'column-removed': 'onColumnListChanged',
        'dataset-name-changed': 'forceStatusChange',
        'column-group-unit-change-finished': 'onStatusChanged',
        'column-group-added': '_onColumnGroupAdded',
        'column-group-removed': '_onColumnGroupRemoved',
      },
    });

    this._errorBarInfoCache = {};

    this.eventBinder.bindListeners({
      source: this.$dataCollection,
      target: this,
      eventMap: {
        'collection-mode-changed': 'onCollectionModeChanged',
      },
    });

    // Add listeners to all columns:
    this.bindToColumns();

    // Subscribe to redux and mobx view state changes:
    this.storeUnsubscribe = store.subscribe(this.onViewModelChanged.bind(this));
    this.mobxUnsubscribe = autorun(() => {
      this.onViewModelChanged(vstLayoutStore);
    });

    // Build our initial DataShare JSON state.
    await this.initFromUDM();

    // Send all data (including data marks etc.) to clients:
    this._sendAllData();

    // Detect changes to datamarks, re-broadcasting as necessary.
    this._dataMarkAutorunCancel = autorun(() => {
      const newDataMarks = this.$dataWorld._dataMarks.map(m => m.udmExport);
      if (!this._oldDataMarks || !isEqual(newDataMarks, this._oldDataMarks)) {
        this._sendDataMarksInfo();
        this._oldDataMarks = newDataMarks;
      }
    });

    this.runningState = 'running';
  }

  /**
   * Stops RTC data hosting session. Emits a message to clients alerting them
   * of the imminent stop, then cleans up notifications and observers et al.
   *
   * @returns {Promise} a promise that resolves when RTC data hosting session is finished stopping.
   */
  stop() {
    if (this.runningState !== 'running') {
      console.warn('Attempt to stop server that is not running.');
      return new Promise(resolve => resolve());
    }
    this.runningState = 'stopping';

    // Notify clients of imminent session shut down.
    this.sendMessage({ type: 'session-did-end', data: this.runningState });

    this.eventBinder.unbindAll();
    this.unbindColumns();

    this.storeUnsubscribe();
    delete this.storeUnsubscribe;
    this.mobxUnsubscribe();
    delete this.mobxUnsubscribe;

    delete this.textEncoder;

    this._dataMarkAutorunCancel();
    delete this._dataMarkAutorunCancel;

    this._errorBarInfoCache = {};

    this.runningState = 'stopped';

    return new Promise(resolve =>
      setTimeout(() => {
        // Close connections after a small delay to let messages get to clients.
        this.clientDataConnections.forEach(c => c.close());
        this.clientDataConnections = [];
        resolve();
      }, 1000),
    );
  }

  async fetchStatusJSON() {
    return this.$api.fetchStatusJSON(this.$dataWorld.experimentId);
  }

  async fetchColumnDataJSON(columnIds) {
    return this.$api.fetchColumnDataJSON(this.$dataWorld.experimentId, columnIds);
  }

  // Adds client to client list and broadcasts entire data map to client so they are up to date.
  addDataConnection(dataConnection) {
    console.assert(this.runningState === 'running');
    this.clientDataConnections.push(dataConnection);

    dataConnection.on('data', data => this.onReceiveMessage(dataConnection, JSON.parse(data)));

    // This flag that we inject on the connection tells us whether to text-encode outgoing data. The client can request to change this.
    dataConnection._useByteEncoding = false;

    // Send data map and data marks and anything else the new client wants.
    this._sendAllData(dataConnection);
  }

  removeDataConnection(dataConnection) {
    dataConnection.onmessage = null;
    this.clientDataConnections.splice(this.clientDataConnections.indexOf(dataConnection), 1);
  }

  /**
   * Send a message to all clients (or to a specific client). This is the main bottleneck for sending messages to host(s).
   * @param {Object} messageObject object containing type and payload.
   * @param {import('../PeerConnection.js').PeerConnection?} client [optional] send message to specified client instead of to all clients.
   */
  sendMessage(msgObj, client = null) {
    const stringMessage = JSON.stringify(msgObj);
    const bytes = this.textEncoder.encode(stringMessage);
    if (client) {
      if (client.open) client.send(client._useByteEncoding ? bytes : stringMessage);
      else console.warn(`Client with peer ID ${client.peer} is not open. Unable to write.`);
    } else {
      this.clientDataConnections.forEach(connection => {
        if (connection.open) connection.send(connection._useByteEncoding ? bytes : stringMessage);
        else console.warn(`Client with peer ID ${connection.peer} is not open. Unable to write.`);
      });
    }
  }

  onReceiveMessage(sender, obj) {
    super.onReceiveMessage(sender, obj);
    switch (obj.type) {
      case 'client-info':
        {
          // Add client info the DataConnection's metadata field.
          const metadata = sender.metadata || {};
          sender.metadata = { ...metadata, clientInfo: obj.data };
        }
        break;
      case 'enable-byte-encoding':
        // This happens when RTC butchers certain ranges of UTF-16 surrogate pairs (e.g. emojis et al) and the client simply can't decode our JSON.
        // We'll switch to encoding strings first into bytes when transmitting future packets.
        sender._useByteEncoding = true;

        // Send an (unencoded) acknowledgement -- when client receives this, then we're on the same page.
        sender.send(JSON.stringify({ type: 'byte-encoding-enabled' }));
        break;
      case 'request-all-data':
        // Client has asked to receive ALL the data, typically in the event of some kind of comms failure.
        this._sendAllData(sender);
        break;
      default:
        break;
    }
  }

  // Override
  columnDataDidChange(colId, colObj) {
    this.sendMessage({ type: 'update-col', colId, data: colObj });
  }

  // Override
  statusDidChange(statusObj) {
    this.sendMessage({ type: 'update-status', data: statusObj });
  }

  // Override
  infoDidChange(infoObj) {
    this.sendMessage({ type: 'update-info', data: infoObj });
  }

  // Override
  columnDataWasRemoved(colId) {
    this.sendMessage({ type: 'remove-column', colId });
  }

  async onSessionDidStart() {
    this._resetProperties();
    await this.initFromUDM();
    this._appTransitioning = false;
    this._sendAllData();
  }

  onSessionClosing() {
    // Mark that the application is transitioning to a new experiment. This will
    // prevent certain changes (such as data marks) from propagating to clients.
    this._appTransitioning = true;
  }

  /**
   * Runs a status push with the force flag set. This is used for the less common properties that don't update one of the DS rolling counters/timestamps.
   */
  forceStatusChange() {
    // Flag that we want to push status to clients:
    this.forceStatusPush = true;
    this.onStatusChanged();
  }

  onViewModelChanged(newLayout = this.cachedLayout) {
    const viewState = store.getState();
    if (!isEqual(newLayout, this.cachedLayout) || !isEqual(viewState.graphs, this.cachedGraphs))
      // Add a bit of delay to allow for redux changes to get pushed to udm by the middleware so when
      // updateFromUDM() is called it will reflect these changes.
      setTimeout(() => {
        this.forceStatusPush = true;
        this.viewTimeCount++;
        this.onStatusChanged();
      }, 50);

    this.cachedLayout = { ...newLayout };
    this.cachedGraphs = viewState.graphs;
  }

  onCollectionModeChanged() {
    // After a slight delay, explicitly instruct clients to destroy their views, and then re-issue a status push.
    setTimeout(() => {
      this.sendMessage({ type: 'reset-views', newViewListCount: this.viewTimeCount + 1 });
      this.forceStatusPush = true;
      this.viewTimeCount += 2;
      // Call this directly instead of throttling.
      this.updateDataMapFromUDM();
    }, 300);
  }

  onColumnListChanged() {
    // Columns were added/removed. Add message listeners to all columns and issue a status changed message.
    this.bindToColumns();
    this.onStatusChanged();
  }

  onLiveReadout(colId, newValue) {
    const { value = 0, timestamp = 0 } = this.liveValues[colId] || {};
    if (newValue !== value) {
      this.liveValues[colId] = { value: newValue, timestamp: timestamp + 1 };
      this.forceStatusPush = true;
      this.onStatusChanged();
    }
  }

  _sendDataMarksInfo(client = null) {
    // Don't send data marks until we're done transitioning. This should prevent a
    // case where data marks are deleted from clients before they have a chance
    // to work offline.
    if (this._appTransitioning) return;
    const datamarks = this.$dataWorld._dataMarks.map(mark => mark.udmExport);
    const message = { type: 'data-marks-changed', datamarks };
    this.sendMessage(message, client);
  }

  /**
   * Send all data including the data map AND data marks etc.
   * @param {import('../PeerConnection.js').PeerConnection?} client if null, will broadcast message to all peers. If non-null, will send the message to the specified client only.
   */
  _sendAllData(client = null) {
    const message = { type: 'all-data', data: this.exportDataMap() };
    this.sendMessage(message, client);

    // Send data marks and error bars. If client is specified we'll add a bit of a delay, just so the client is ready.
    setTimeout(
      () => {
        this._sendDataMarksInfo(client);
        this.$dataWorld.columnGroups.forEach(g => this._sendErrorBarInfoForGroup(g, client));
      },
      client ? 750 : 0,
    );
  }

  bindToColumns() {
    // Unbind and rebind to columns, creating a new binder if necessary.
    if (!this.columnBinder) this.columnBinder = new EventBinder();
    else this.columnBinder.unbindAll();

    this.$dataWorld.getAllColumns().forEach(col => {
      this.columnBinder.bindListeners({
        source: col,
        target: this,
        eventMap: {
          'name-changed': 'onStatusChanged',
          'units-changed': 'onStatusChanged',
          'wavelength-changed': 'onStatusChanged',
          'format-string-changed': 'onStatusChanged',
          'values-changed': 'onStatusChanged',
          'set-id-changed': 'onStatusChanged',
          'group-id-changed': 'onStatusChanged',
          'updated-rows-changed': 'onStatusChanged',
          'color-changed': 'onViewModelChanged',
          'symbol-changed': 'onViewModelChanged',
        },
      });
      this.columnBinder.bind(col, 'live-value-changed', value => this.onLiveReadout(col.id, value));
    });
  }

  unbindColumns() {
    if (this.columnBinder) this.columnBinder.unbindAll();
    this.columnBinder = null;
  }

  /**
   * This method initizlizes our client mode data members by fetching initial status etc. from UDM land.
   */
  async initFromUDM() {
    const statusJSON = await this.fetchStatusJSON();
    const status = JSON.parse(statusJSON.status);

    // Grab a list of column Ids and column timestamps
    const colIds = [];
    this.columnTimeCounts = {};
    for (const [colId, colInfo] of Object.entries(status.columns)) {
      colIds.push(colId);
      this.columnTimeCounts[colId] = {
        time: colInfo.valuesTimeStamp,
        count: colInfo.valuesCount,
        live: colInfo.liveValue,
      };
    }

    // Save some time other bits that we can diff against.
    this._previousSessionId = status.sessionID;

    // Grab all column values as JSON
    const columns = await this.fetchColumnDataJSON(colIds);
    // Store in map according to the RESTful URL:
    for (const [id, colObj] of Object.entries(columns)) {
      this.setColumnData(id, JSON.parse(colObj));
    }

    this.setInfo(RTCDataShareCommon.platformInfo);
    this.setStatus(status);

    const viewState = store.getState();
    this.cachedLayout = { ...vstLayoutStore };
    this.cachedGraphs = viewState.graphs;
  }

  /**
   * Call this whenever we detect a column or other change. This will inform our clients of changes.
   */
  async updateDataMapFromUDM() {
    // If we are in an intermediate running state, i.e. starting up, then proceeding here would
    // cause our bookkeeping to get out of whack.
    if (this.runningState !== 'running') return;

    // Guard to prevent re-entrancy. Saves off the forceStatusPush state and re-sets this
    // when the running invocation finishes.
    if (this.isUpdating) {
      this.requeueStatus = { forceStatusPush: this.forceStatusPush };
      return;
    }

    this.isUpdating = true;

    // Save state, and reset member.
    const forceStatus = this.forceStatusPush;
    this.forceStatusPush = false;

    if (!Object.entries(this.dataMap).length) {
      await this.initFromUDM();
      this.isUpdating = false;
      return;
    }

    const statusJSON = await this.fetchStatusJSON();
    const newStatus = JSON.parse(statusJSON.status);
    const oldStatus = this.getEntryForKey('/status');

    // First identify when columns have been modified or newly added:
    const changedColumnIds = [];
    const reportedColumnIds = new Set();
    for (const [colId, col] of Object.entries(newStatus.columns)) {
      reportedColumnIds.add(colId);
      // UDM JSON liveValue only seems to update during data collection. We'll hack it a bit.
      let liveValue = 0;
      const column = this.$dataWorld.getColumnById(colId);
      if (column) liveValue = column.liveValue;
      const previous = this.columnTimeCounts[colId];
      if (previous) {
        if (previous.time !== col.valuesTimeStamp || previous.count !== col.valueCount) {
          changedColumnIds.push(col.id);
        }
      } else {
        changedColumnIds.push(col.id);
      }
      this.columnTimeCounts[col.id] = {
        time: col.valuesTimeStamp,
        count: col.valueCount,
        live: liveValue,
      };
    }

    let shouldPushStatus =
      forceStatus || this._previousSessionId !== newStatus.sessionID || changedColumnIds.length;
    this._previousSessionId = newStatus.sessionID;

    // Detect columns that have since been removed
    for (const [colId] of Object.entries(oldStatus.columns ?? {})) {
      if (!reportedColumnIds.has(colId)) {
        this.removeColumnData(colId);
        shouldPushStatus = true;
      }
    }
    // Fetch changed data:
    if (changedColumnIds.length) {
      // TODO: this should be able to be removed once we move back to using left column ids rather than
      // group ids. Currently in onViewModelChanged the view.graphs look the same from one run to the next
      // becuase the group ids don't change, while the actual column ids will.
      this.viewTimeCount++;

      const changedColumnData = await this.fetchColumnDataJSON(changedColumnIds);
      for (const [colId, col] of Object.entries(changedColumnData)) {
        // Update our dataMap (which will report to clients).
        this.setColumnData(colId, JSON.parse(col));
      }
    }

    if (shouldPushStatus) this.setStatus(newStatus);
    this.isUpdating = false;

    // Check if we've had other update requests and re-request them.
    if (this.requeueStatus) {
      this.forceStatusPush = this.forceStatusPush || this.requeueStatus.forceStatusPush;
      this.requeueStatus = undefined;
      this.onStatusChanged();
    }
  }

  /**
   * Amends status with live values, viewListTimeStamp, and canCollect = false before calling super.
   * @override
   * @param {object} status
   */
  setStatus(status) {
    Object.values(status.columns).forEach(col => {
      const live = this.liveValues[col.id];
      if (live) {
        let liveValue = sprintf(col.formatStr, live.value);
        if (!liveValue) liveValue = live.value;
        col.liveValue = liveValue;
        col.liveValueTimeStamp = live.timestamp;
      }
    });

    super.setStatus({
      ...status,
      collection: { isCollecting: this.$dataWorld.isCollecting, canCollect: false },
      viewListTimeStamp: this.viewTimeCount,
    });
  }

  /**
   * Sends error bar info to a client
   * @param {import('@api/common/ColumnGroup.js').ColumnGroup} group group for which to send error bar changes.
   * @param  {import('../PeerConnection.js').PeerConnection?} client [optional] PeerConnection object. Data will be sent to this client instead of to all
   */
  _sendErrorBarInfoForGroup(group, client = null) {
    const info = {
      columnId: group.errorBarColumnId,
      type: group.errorBarType,
      value: group.errorBarValue,
    };

    // Get cached error bar info:
    const entry = this._errorBarInfoCache[group.id];
    if (!entry) {
      // If we don't have a cache entry, we'll create one, and then add listeners to the group.
      this._errorBarInfoCache[group.id] = info;
      this.eventBinder.bindListeners({
        source: group,
        target: this,
        eventMap: {
          'error-bar-column-id-changed': '_onErrorBarChanged',
          'error-bar-type-changed': '_onErrorBarChanged',
          'error-bar-value-changed': '_onErrorBarChanged',
        },
      });
    } else if (isEqual(info, entry) && client === null) {
      // Nothing changed.
      return;
    }

    // Grab the first column id from the group -- client will use to figure out which groupt to target.
    // If group has no columns, we'll just ignore for now. This happens when loading new experiments.
    if (group.columns.length >= 1) {
      const [{ id: colId = '' }] = group.columns;
      const message = {
        type: 'error-bar-info-changed',
        colId,
        info,
      };

      this.sendMessage(message, client);
    }
  }

  /**
   * Called when an error bar property is modified on a group
   * @param {Object} obj
   * @param {import('@api/common/ColumnGroup.js').ColumnGroup} obj.group group that has changed.
   */
  _onErrorBarChanged({ group }) {
    this._sendErrorBarInfoForGroup(group);
  }

  /**
   * Called when DataWorld indicated a column group has been added.
   * @param {import('@api/common/ColumnGroup.js').ColumnGroup} newGroup
   */
  _onColumnGroupAdded(newGroup) {
    this._sendErrorBarInfoForGroup(newGroup);
  }

  /**
   * Called when DataWorld indicated a column group has been removed.
   * @param {import('@api/common/ColumnGroup.js').ColumnGroup} group
   */
  _onColumnGroupRemoved(group) {
    delete this._errorBarInfoCache[group.id];
  }
}
