export class RTCDataShareAPI {
  constructor(rpc) {
    this.rpc = rpc;
  }

  fetchStatusJSON(experimentId) {
    return this.rpc.sendRequest('datashare-get-status', { experimentId });
  }

  fetchColumnDataJSON(experimentId, udmIds) {
    const columnIds = udmIds.map(id => Math.floor(id));
    return this.rpc.sendRequest('datashare-get-columns', { experimentId, columnIds });
  }
}
