/**
 * Components here are categorized in groups and sorted alphabetically
 */

// App Components
import { serviceWorkerInitializer } from '@common/utils/serviceWorker/ServiceWorkerInitializer.js';
import '@common/libs/array.prototype.at.js';
import '@components/vst-ui-tooltip/vst-ui-tooltip-wrapper.js';
import './ga-app.js';

serviceWorkerInitializer.init().then(async ({ newServiceWorkerReady }) => {
  await newServiceWorkerReady;
  fetch(EXPERIMENT_MANIFEST_URL);
  fetch(FW_MANIFEST_URL);
});

if (!('ResizeObserver' in window)) {
  (async () => {
    const module = await import('@juggle/resize-observer');
    window.ResizeObserver = module.ResizeObserver;
  })();
}

if (!PRODUCTION && !('toJs' in window)) {
  (async () => {
    const mobx = await import('mobx');
    window.toJS = mobx.toJS;
  })();
}
