import { addCallback, emptyCallbacks } from './helpers.js';
import * as platform from './PLATFORM_ID';

const sources = [];

export const onListChange = cb => {
  addCallback(cb);
};

export const unbindListChange = () => {
  emptyCallbacks();
};

const startListeners = () => {
  if (platform.startListeners) {
    return platform.startListeners();
  }

  return () => {
    console.log('not implemented');
  };
};

const stopListeners = () => {
  unbindListChange();
  if (platform.stopListeners) {
    return platform.stopListeners();
  }

  return () => {
    console.log('not implemented');
  };
};

const getSourceList = () => {
  if (platform.getSourceList) {
    return platform.getSourceList();
  }

  return sources;
};

export const createDsSourceDiscovery = () => ({
  startListeners,
  stopListeners,
  getSourceList,
  onListChange,
});
