let callbacks = [];

export const addCallback = callback => {
  callbacks.push(callback);
};

export const emptyCallbacks = () => {
  callbacks = [];
};

export const notifyListChange = sourceList => {
  callbacks.forEach(cb => cb(sourceList));
};
