export class GraphicalAnalysisAPI {
  constructor(rpc) {
    this.rpc = rpc;
  }

  /**
   * Adds a video with specified pathname to UDM store.
   * @param {number} experimentId - UDM id of the experiment
   * @param {string} archPath path relative to the containing archive directory.
   * @returns {number} udm ID of video
   */
  setVideo(experimentId, archPath) {
    return this.rpc.sendRequest('add-video', { experimentId, archPath }); // TODO: rename add-video to set-video
  }

  /**
   * Remove video with specfied id.
   * @param {number} experimentId - UDM id of the experiment
   * @param {*} videoId udm Id of video. This is obtained as a result of `updateVideo()` or by inspecting `videos` array of pageAttributes.
   */
  removeVideo(experimentId, videoId) {
    return this.rpc.sendRequest('remove-video', { experimentId, videoId: Math.floor(videoId) });
  }

  /**
   * Set visibility of video in UDM store. TBD:
   * @param {number} experimentId - UDM id of the experiment
   * @param {boolean} visible true if video is visible or false if not.
   */
  setVideoVisibility(experimentId, visible, videoId) {
    return this.rpc.sendRequest('set-video-visibility', {
      experimentId,
      visible,
      videoId: Math.floor(videoId),
    });
  }

  /**
   * @param {string} token
   */
  validateJWT(token) {
    return this.rpc.sendRequest('validate-jwt', { token });
  }
}
