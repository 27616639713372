import platform from 'platform';
import { isEqual } from 'lodash-es';

/**
 * This is the current Internet Data Share version. This version describes a particular iteration of IDS protocol, signaling,
 * data structures etc., and is distinct from the Data Share API version ('\info.apiVers') which describes the content of restful JSON objects used for
 * both local and internet DS.
 *
 * We use standard semantic verions: MAJ.MIN.REV.
 *  REV -- is just small changes that do not change API: bug fixes etc.
 *  MIN -- adds new APIs and capabilities but preserves backwards compatibility with older versions.
 *  MAJ -- adds new API and capabilities which are NOT backwards compatible.
 *
 * Internet Data Share version history:
 *
 * 1.4.0: removed `session-will-start` and `session-did-end`. These were never
 * actually used by anyone, warranting only a minor version bump.
 *
 * 1.3.0: adds support for Error Bars.
 *
 * 1.2.0: adds support for sharing Data Marks over data sharing.
 *
 * New message types:
 *
 * 'error-bar-info-changed' (client => host) sent when error bars info changes. Sends { type: 'error-bar-info-changed', colId, info}. Info is type ErrorBarInfo.
 *
 * 'data-marks-updated' (client => host) sent whenever host's data mark store updates. Will send { type: 'data-marks-updated', datamarks: array} where array is
 * an array of externalized datamark representations (@see DataMark.udmExport).
 *
 * 1.1.0: adds support for host "content byte encoding" (data passes through TextEncoder/TextDecoder), to circumvent a WebRTC issue in which emojis didn't
 * survive the round trip from String->DOMString->USVString and in certain cases, cause connection to fail.
 *
 *  New message types:
 *
 *  'enable-byte-encoding' (client => host): client requests that host use byte encoding when sending packets. Host will respond with 'byte-encoding-enabled'
 *  at which point client can assume that subsequent packets from host will be encoded. Note: encoding will only be from host to client, and once turned on,
 *  cannot be disabled for the remainder of the session.
 *
 *  'byte-encoding-enabled' (host => client): host acknowledgement that it will send packets using byte encoding when sending data to that client for the
 *  remainder of the session.
 *
 *  'request-all-data' (client => host): client requests host to re-send entire data map. Host will respond with message type 'all-data'.
 *
 * New properties:
 *
 *  'idsVersion' in the '/info' object, contains the IDS_VERSION string as defined below (TODO: in an ideal world, adding a new property to the restful
 *  JSON content really OUGHT to bump 'apiVers', but the original DS machinery is very brittle about changing that value. Needs to be fixed but not today.)
 *
 * 1.0.0: original shipping version. This version does NOT actually report the IDS_VERSION; a host that omits this in the '/info' record
 * may safely be assumed to be running version 1.0.0.
 */
const IDS_VERSION = '1.4.0';

/**
 * @typedef {Object} ErrorBarInfo
 * @property {ErrorBarType} type
 * @property {number} value
 * @property {number} columnId
 */

/**
 * Utility to compare two data map export objects.
 * @param {*} dm1 first data map to compare.
 * @param {*} dm2 second data map to compare.
 * @see RTCDataShareCommon.exportDataMap()
 */
export function compareDataMaps(dm1, dm2) {
  const result = isEqual(dm1, dm2);
  if (!result) {
    for (const [key, value] of Object.entries(dm1)) {
      if (!isEqual(value, dm2[key])) console.warn(`Diff fails on key ${key}`);
    }
  }
  return result;
}

/**
 * This is the base class of both RTC DataShare clients and servers. It provides a data structure for expressing the application's
 * state in a DataShare-centric way: a data map consisting of top level REST objects, keyed on "URL key". There are methods for accessing
 * and manipulating entries in this data map as well as methods a subclass can override to respond to various changes to the map.
 */
export class RTCDataShareCommon {
  constructor() {
    // Datamap is an object containing string keys and string values.
    this.dataMap = {};
  }

  /**
   * Bottleneck for getting values from the dataMap.
   * @param {string} key A string identifying the object corresponding to a restful URL, i.e. '/status', '/info', or '/columns/xyz' where xyz is a column identifier.
   * @returns {object} The object corresponding to key.
   */
  getEntryForKey(key) {
    return this.dataMap[key];
  }

  /**
   * Bottleneck for setting values in the data map. This will invalidate the cached data map.
   * @param {String} key A string identifier @see `getEntryForKey()` above.
   * @param {object} entry object corresponding to the key.
   */
  setEntryForKey(key, entry) {
    this.dataMap[key] = entry;
  }

  /**
   * Bottleneck for removing an entry in the data map.
   * @param {string} key of item to remove.
   */
  removeEntryForKey(key) {
    if (this.dataMap[key]) {
      delete this.dataMap[key];
    }
  }

  /**
   * Sets column data in the data map.
   * @param {string} colId Numeric UDM id of data column.
   * @param {object} dataObj An object representing the columns data, i.e. {values: [0, 1, 2, 5]}.
   */
  setColumnData(colId, dataObj) {
    const newId = RTCDataShareCommon.normalizeColumnId(colId);
    this.setEntryForKey(newId, dataObj);
    this.columnDataDidChange(newId, dataObj);
  }

  /**
   * Remove a column's data from the data map.
   * @param {string} colId UDM id of column to remove.
   */
  removeColumnData(colId) {
    const columnId = RTCDataShareCommon.normalizeColumnId(colId);
    this.removeEntryForKey(columnId);
    this.columnDataWasRemoved(colId);
  }

  static normalizeColumnId(colId) {
    if (typeof colId === 'string') {
      if (colId.startsWith('/columns/')) return colId;
    }
    return `/columns/${colId}`;
  }

  /**
   * Returns a DataSharing 1.x compliant 'info' record.
   */
  static get platformInfo() {
    return {
      agent: platform.name,
      agentVers: platform.version,
      apiVers: '1.0.3',
      platform: platform.os.toString(),
      idsVersion: IDS_VERSION,
    };
  }

  /**
   * @returns {string|null} if available, a string containing the current
   * sessionId, else null.
   */
  get sessionId() {
    const status = this.getEntryForKey('/status');
    return status?.sessionID || null;
  }

  /**
   * Sets the status entry in the data map
   * @param {object} statusObj object containing status.
   */
  setStatus(statusObj) {
    this.setEntryForKey('/status', statusObj);
    this.statusDidChange(statusObj);
  }

  /**
   * Sets the info entry in the data map
   * @param {object} infoObj object containing info status.
   */
  setInfo(infoObj) {
    this.setEntryForKey('/info', infoObj);
    this.infoDidChange(infoObj);
  }

  /**
   * Returns the entire data map in transportable form which can then be reconstituted via `importDataMap`.
   * @returns {object}
   */
  exportDataMap() {
    return this.dataMap;
  }

  /**
   * Imports the entire data map from JSON contained in a string.
   * @param {object} dataMap -- stringified JSON struct.
   */
  importDataMap(newMap) {
    this.dataMap = newMap;
  }

  /* eslint-disable class-methods-use-this, no-unused-vars */

  /**
   * Called in response to `setColumnData()/importDataColumnChangeString()` above. Subclasses (i.e. server) may override this and send changes to clients.
   * @param {number} colId UDM id of column that changed
   * @param {object} colObj column data.
   */
  columnDataDidChange(colId, colObj) {
    // Subclasses may override.
  }

  /**
   * Called in response to `removeColumnData()`. Subclasses may override this.
   * @param {number} colId UDM id of column that was removed.
   */
  columnDataWasRemoved(colId) {
    // Subclasses may override.
  }

  /**
   * Called when status object was changed. Subclasses may override.
   * @param {object} statusObject new status.
   */
  statusDidChange(statusObject) {
    // Subclasses may override.
  }

  /**
   * Called when info is changed.
   * @param {object} infoObject new info.
   */
  infoDidChange(infoObject) {
    // Subclasses may override.
  }

  /**
   * Send a message to this class's peer(s).
   * @param {string} data message to send.
   */
  sendMessage(data) {
    // Subclasses must override.
  }

  /**
   * Handles incoming messages from one of this class's peer(s).
   * @param {object} sender data channel or peer connection which originated this message.
   * @param {object} obj message obj, contains different members depending on the the type field.
   * @param {string} obj.type describes what this message contains.
   * @param {string} obj.data [optional] contains the body of the message.
   * @param {number} obj.colId [optional] column Id
   */
  onReceiveMessage(sender, obj) {
    // Subclasses may override.
    // TBD: Handle messages applicable to ALL peers -- we might implement some kind of information or signalling
    // here.
    switch (obj.type) {
      default:
        break;
    }
  }
}
