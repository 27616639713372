import { css } from 'lit';
import { bottombarButton } from '@styles/mixins/buttons.css.js';

export const gaBottombarStyles = css`
  :host {
    background: var(--vst-color-bg-secondary);
    user-select: none;
    display: flex;
    border-top: 1px solid var(--vst-color-bg-primary);
    padding: 0;
  }

  /* layout */
  .modes-sensors-wrapper {
    flex: 1;
    overflow-x: auto;
    display: flex;
  }

  .modes,
  .sensors {
    flex: 1 1 auto;
    display: flex;
  }

  .modes {
    padding-inline-start: calc(var(--vst-space-xs) / 2);
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .sensors {
    justify-content: flex-end;
    margin-inline-start: var(--vst-space-xs);
  }

  .datashare-connection-status {
    width: 5px;
    height: 5px;
    border-radius: var(--vst-radius);
  }

  .datashare-connection-status--online {
    background-color: var(--vst-color-success);
  }

  .datashare-connection-status--offline {
    background-color: var(--vst-color-fg-tertiary);
  }

  /* button/meter styles */
  .bottombar-btn {
    margin: var(--vst-space-xs) calc(var(--vst-space-xs) / 2);
    flex: 0 0 auto;
  }

  ${bottombarButton(css`.bottombar-btn`)}

  /* mode specific */
  .mode-prefix::after {
    content: ':';
  }

  .mode-name {
    margin-inline-end: 1em;
  }

  .mode-name:last-child {
    margin: 0;
  }

  /* sensor specific */
  .device-manager-btn {
    flex-shrink: 0;
    margin-inline-end: var(--vst-space-xs);
  }

  .device-manager-btn:hover .device-manager-icon {
    opacity: 1;
  }

  .device-manager-icon {
    opacity: 0.8;
    width: 1.125rem;
    height: 1.125rem;
  }

  :host([device-needs-firmware-update]) .device-manager-btn {
    position: relative;
  }

  :host([device-needs-firmware-update]) .device-manager-btn::after {
    display: block;
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    background-color: var(--vst-color-danger);
    position: absolute;
    top: var(--vst-space-2xs);
    right: var(--vst-space-2xs);
  }
`;
