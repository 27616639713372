import { observable, action, makeObservable, runInAction } from 'mobx';
import EventEmitter from 'eventemitter3';

import { isFeatureFlagEnabled } from '@services/featureflags/featureFlags.js';
import { convertTimeUnits } from '@utils/formatter.js';

import { ReplayTimer, ReplayTimerDelegate } from './replay/ReplayTimer.js';

export class Replay extends EventEmitter {
  constructor({ api, dataWorld, dataCollection }) {
    super();

    this._defaults = {
      currentDataSetId: 0,
      isReplayActive: false,
      replayRate: 1.0, // non-negative playback rate
    };
    Object.assign(this, this._defaults);

    // TODO: remove this in favor of using _replayParams when separate sync times for individual data sets are supported
    this.videoSync = { enabled: false, videoSyncTime: 0, dataSyncTime: 0 };

    makeObservable(this, {
      videoSync: observable,
      currentDataSetId: observable,
      isReplayActive: observable,
      replayRate: observable,
      updateVideoSync: action,
      resetService: action,
      enterReplayMode: action,
      exitReplayMode: action,
      updateReplayRate: action,
      updateDataSet: action,
      startDataPlayback: action,
    });

    this.api = api;
    this.dataWorld = dataWorld;
    this.dataCollection = dataCollection;

    this.isSyncPaneOpen = false;

    this._replayParams = {};
    this._replayTimingCache = {};
  }

  /**
   * Returns default timing information based on the current data collection settings.
   * @param {array} dataSetIds ids as numbers used to produce output
   * @param {object} dataCollectionParams the current data collection settings
   * @returns {object} keyed on dataSet ids as numbers with `duration` and `timeInterval` values suited to the collection settings.
   */
  static getDefaultTimingInformation(dataSetIds, dataCollectionParams) {
    const { mode, params } = dataCollectionParams;

    let duration = 10;
    let timeInterval = 1;

    if (mode === 'time-based') {
      if (params.delta) timeInterval = params.delta;
      if (params.duration) duration = params.duration;
      if (params.units) {
        timeInterval = convertTimeUnits(timeInterval, params.units, 's');
        duration = convertTimeUnits(duration, params.units, 's');
      }
    }
    const result = {};
    dataSetIds.forEach(id => {
      result[id] = { duration, timeInterval };
    });
    return result;
  }

  /**
   * Call this after a new file gets opened. This will update local caches.
   */
  async resetService(isImportedSession) {
    if (this.timer) {
      this.timer.stop();
      this.timer = null;
      this.emit('replay-playing-changed', { playing: false });
    }

    this.isReplayActive = false;
    await this.api.exitReplayMode(this.dataWorld.experimentId, true); // TODO: remove this call if possible

    this._replayParams = {};
    await Promise.all(
      this.dataWorld.getDataSets().map(async dataSet => {
        const dataSetId = parseInt(dataSet.id);
        this._replayParams[dataSetId] = await this.api.getReplayParams(
          this.dataWorld.experimentId,
          dataSetId,
        );
      }),
    );

    // TODO: add replay param UDM flag to store whether sync is enabled
    const { dataSyncTime, videoSyncTime, enabled } = Object.values(this._replayParams)[0] || {};

    this.updateVideoSync(
      {
        dataSyncTime,
        videoSyncTime,
        enabled,
      },
      undefined,
      isImportedSession,
    );

    if (isImportedSession) {
      // fetch replay global state from UDM and store to local vars
      const { dataSetId, active, rate } = await this.api.getReplayOptions(
        this.dataWorld.experimentId,
      );

      // Ensure rate is positive
      runInAction(() => {
        this.replayRate = rate > 0.0 ? rate : 1.0;
      });

      if (active) {
        await this.enterReplayMode(dataSetId);
      }
    } else {
      // reset front-end and back-end global replay state
      runInAction(() => {
        Object.assign(this, this._defaults);
      });

      await this.api.setReplayOptions(
        this.dataWorld.experimentId,
        this.currentDataSetId,
        this.isReplayActive,
        this.replayRate,
      ); // update UDM
    }

    this.emit('replay-service-reset', this);
  }

  // TODO: this is a workaround.  We need to propertly handle trigger pre-store and datasets collected at different smaple rates
  getResetTimestamp() {
    let timeStamp = 0;
    const { params } = this.dataCollection.getCollectionParams('time-based');
    if (params) {
      timeStamp -= params.delta / 2; // start just before the first point
    }
    return timeStamp;
  }

  /**
   * update state storing whether the sync pane is open
   * @param {boolean} isOpen Flag describing whether pane is open
   */
  updateSyncPaneOpen(isOpen) {
    this.isSyncPaneOpen = isOpen;
  }

  get linkVideoToExamine() {
    return (
      !this.isReplaying &&
      this.videoSync.enabled &&
      !(this.isSyncPaneOpen && isFeatureFlagEnabled('ff-ga-interactive-sync-inputs'))
    );
  }

  get linkDataInputToExamine() {
    return (
      !this.isReplaying &&
      this.videoSync.enabled &&
      this.isSyncPaneOpen &&
      isFeatureFlagEnabled('ff-ga-interactive-sync-inputs')
    );
  }

  /**
   * Update local state and request the native code to enter replay mode after initializing the cache of data set timing info (_replayTimingCache).
   * @param {string or number} dataSetId udmId of data set to be played back. Specify 0 to playback most recent data set.
   * @param {number} columnGroupId [Optional] id of column group corresponding to column to be used instead of the default time column.
   */
  async enterReplayMode(dataSetId, columnGroupId = 0) {
    const _dataSetId = parseInt(dataSetId); // data set id as a number

    if (!this.isReplayActive) {
      try {
        // cache the timing information for all regular data sets
        const dataSetIds = this.dataWorld.getDataSets().map(d => parseInt(d.id));
        try {
          this._replayTimingCache = await this.api.fetchTimingInformation(
            this.dataWorld.experimentId,
            dataSetIds,
          );
        } catch (err) {
          console.error(err);
          this._replayTimingCache = Replay.getDefaultTimingInformation(
            dataSetIds,
            this.dataCollection.getCollectionParams(),
          );
        }

        // request to enter replay mode and update udm
        runInAction(() => {
          this.currentDataSetId = _dataSetId;
          this.isReplayActive = true;
          this.emit('replay-status-change', { isReplayActive: true });
        });

        await this.api.setReplayOptions(
          this.dataWorld.experimentId,
          _dataSetId,
          true,
          this.replayRate,
        );
        await this.api.enterReplayMode(this.dataWorld.experimentId, _dataSetId, columnGroupId);
        this.resumeTimestamp = undefined;
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * Exit replay mode. This routine will be a no-op if there is not already a replay in session.
   */
  async exitReplayMode() {
    this.emit('replay-status-change', { isReplayActive: false });
    if (!this.isReplayActive) return;

    if (this.isReplaying) {
      this.pauseDataPlayback();
    }

    runInAction(() => {
      this.isReplayActive = false;
      this.currentDataSetId = 0;
    });

    await this.api.exitReplayMode(this.dataWorld.experimentId);
    await this.api.setReplayOptions(this.dataWorld.experimentId, 0, false, this.replayRate); // update UDM w/ data set id and new active status
  }

  /**
   * Associate sync times for a dataset-video pair, both in a local cache and in udm
   * @param {number} dataSetId Id of the data set in the pair
   * @param {number} videoId Id of the video in the pair
   * @param {number} dataSyncTime Timestamp of data that ought to be synchronous with the videoSyncTime param
   * @param {number} videoSyncTime Timestamp of video that ought to be synchronous with the dataSyncTime param
   * @param {boolean} enabled Whether sync is actively enabled for the data set
   * @param {boolean} importing Whether an import is in progress
   * @returns {promise} that resolves when api call to update native code is complete
   */
  setReplayParams(dataSetId, videoId, dataSyncTime, videoSyncTime, enabled, importing = false) {
    this._replayParams[dataSetId] = { dataSetId, videoId, dataSyncTime, videoSyncTime, enabled }; // update local cache
    if (importing) return Promise.resolve();

    return this.api.setReplayParams(
      this.dataWorld.experimentId,
      dataSetId,
      videoId,
      dataSyncTime,
      videoSyncTime,
      enabled,
    ); // update udm
  }

  get replayParams() {
    return this._replayParams;
  }

  /**
   * Returns true if data replay is currently underway, false if not.
   * @see `startDataPlayback()`
   */
  get isReplaying() {
    return !!this.timer;
  }

  /**
   * Sets the current time stamp. This is only valid after you've entered replay mode
   * @property {number} timeStamp new time stamp use.
   * @see `enterReplayMode()`
   */
  set currentTimestamp(timestamp) {
    if (!this.isReplayActive) {
      console.error('Attempting to set currentTimestamp w/o first entering playback mode.');
      return;
    }

    if (this.isReplaying) {
      // If we're currently replaying we can jog the time like this:
      this.timer.seekTime(timestamp);
    } else {
      // If we're paused, we can set a resume time stamp which will be picked up when replay begins.
      this.resumeTimestamp = timestamp;
    }

    const { enabled, videoSyncTime, dataSyncTime } = this.videoSync;
    const videoDelay = dataSyncTime - videoSyncTime;
    const videoTime = Math.max(0, timestamp - videoDelay);
    if (enabled) {
      this.emit('scrub-video-requested', { timestamp: videoTime });
    }

    try {
      this.api.updateDataPlaybackTime(this.dataWorld.experimentId, timestamp);
    } catch (error) {
      console.error(error);
    }
  }

  get currentTimestamp() {
    if (!this.isReplayActive) {
      console.error('Attempting to fetch currentTimestamp w/o first entering playback mode.');
      return NaN;
    }
    if (this.isReplaying) {
      return this.timer.currentTimestamp;
    }
    return this.resumeTimestamp;
  }

  /**
   * Replace the current replay data set with a new one. TBD: this will stop data replay and re-start the replay session at time 0.
   * @param {string or number} dataSetId udmId of new data set to use for replay.
   */
  async updateDataSet(dataSetId) {
    const _dataSetId = parseInt(dataSetId); // data set id as a number
    if (_dataSetId === this.currentDataSetId) return;

    if (this.isReplaying) {
      this.pauseDataPlayback();
    }

    await this.api.exitReplayMode(this.dataWorld.experimentId, false);
    runInAction(() => {
      this.currentDataSetId = _dataSetId;
    });
    await this.api.enterReplayMode(this.dataWorld.experimentId, _dataSetId);
    await this.api.setReplayOptions(this.dataWorld.experimentId, _dataSetId, true, this.replayRate); // update udm
    this.resumeTimestamp = undefined;
  }

  /**
   * Change the playback speed state while replay is paused
   * @param {number} rate new playback speed. Note: must be > 0.
   */
  updateReplayRate(rate) {
    if (this.isReplaying) return;

    this.replayRate = rate;
    this.api.setReplayOptions(
      this.dataWorld.experimentId,
      this.currentDataSetId,
      this.isReplayActive,
      rate,
    ); // update UDM
  }

  /**
   * Convenience method: stops data playback and rewinds to t = 0.
   */
  rewind() {
    this.pauseDataPlayback();
    this.resumeTimestamp = undefined;
    this.currentTimestamp = this.replayStart;
  }

  /**
   * remove this when separate sync times for individual data sets are supported in favor of calling setReplayParams directly
   *
   * @param {object} syncOptions
   * @param {boolean} sync Flag describing whether sync is active
   * @param {number} videoSyncTime
   * @param {number} dataSyncTime
   */
  updateVideoSync(syncOptions, videoId, importing = false) {
    this.videoSync = {
      ...this.videoSync,
      ...syncOptions,
    };

    const { dataSyncTime, videoSyncTime, enabled } = this.videoSync;

    this.dataWorld.getDataSets().map(async dataSet => {
      const dataSetId = parseInt(dataSet.id);
      if (dataSetId !== 0 && videoId !== 0) {
        this.setReplayParams(dataSetId, videoId, dataSyncTime, videoSyncTime, enabled, importing);
      }
    });
  }

  /**
   * Returns the time when replay should initially start (or rewind to).
   */
  get replayStart() {
    const { duration = 10 } = this._replayTimingCache[this.currentDataSetId];

    const startTime =
      this.resumeTimestamp && duration >= this.resumeTimestamp
        ? this.resumeTimestamp
        : this.getResetTimestamp();
    return startTime;
  }

  /**
   * Start data playback at "this.replayRate" using duration cached for "this.currentDataSetId",
   * kicking off a synced video at the proper time according to "this.videoSync"
   */
  startDataPlayback() {
    const { duration = 10, timeInterval = 0.02 } = this._replayTimingCache[this.currentDataSetId];
    const startTime = this.replayStart;
    const { replayRate = 1 } = this;
    const { enabled, videoSyncTime, dataSyncTime } = this.videoSync;
    const videoDelay = enabled ? dataSyncTime - videoSyncTime : 0; // set delay to 0 to start data playback right away if sync not enabled

    // Set up some event handling methods that get triggered by the replay timer:
    const delegate = new ReplayTimerDelegate();
    delegate.reportTimestamp = timeStamp =>
      this.api.updateDataPlaybackTime(this.dataWorld.experimentId, timeStamp);

    const emitStartVideo = (time, rate) => this.emit('start-video-requested', { time, rate });
    delegate.startVideo = enabled ? emitStartVideo : () => {};

    delegate.replayEnded = () => {
      this.pauseDataPlayback();
    };

    this.timer = new ReplayTimer(delegate, {
      startTime,
      replayDuration: duration,
      replayRate,
      dataInterval: timeInterval,
      videoDelay,
    });
    this.timer.start();
    this.emit('replay-playing-changed', { time: startTime, playing: true });
  }

  /**
   * Pause data playback -- remembers the current time, and on call to startDataPlayback() will resume from that time in the playback.
   */
  pauseDataPlayback() {
    if (this.timer) {
      const timerCurrentTimestamp = this.timer.currentTimestamp;

      const { enabled, videoSyncTime, dataSyncTime } = this.videoSync;
      const videoDelay = dataSyncTime - videoSyncTime;
      const videoTime = Math.max(0, timerCurrentTimestamp - videoDelay);
      if (enabled) {
        this.emit('pause-video-requested', { timestamp: videoTime });
      }

      this.resumeTimestamp = timerCurrentTimestamp;
      this.timer.stop();
      this.timer = null;
      this.emit('replay-playing-changed', { time: this.resumeTimestamp, playing: false });
    }
  }

  _stepBackDataPlayback() {
    const { timeInterval } = this._replayTimingCache[this.currentDataSetId] || {};

    if (timeInterval && Number.isFinite(this.currentTimestamp)) {
      this.currentTimestamp -= timeInterval;
    }
  }

  _stepForwardDataPlayback() {
    const { timeInterval } = this._replayTimingCache[this.currentDataSetId] || {};

    if (timeInterval && Number.isFinite(this.currentTimestamp)) {
      this.currentTimestamp += timeInterval;
    }
  }
}
