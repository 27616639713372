import { fileOpen /* , directoryOpen, fileSave, supported */ } from 'browser-fs-access';
import {
  saveFile as abittiSaveFile,
  writeFile as abittiWriteFile,
  saveToArrayBuffer as abittiSaveToArrayBuffer,
  openFromArrayBuffer as abittiOpenFromArrayBuffer,
} from './abitti.js';

/* eslint-disable import/no-mutable-exports */
export let showOpenDialog = null;
export let saveFile = null;
export let writeFile = null;
export let saveToArrayBuffer = null;
export let openFromArrayBuffer = null;

if (PLATFORM_VARIANT === 'abitti') {
  saveFile = abittiSaveFile;
  writeFile = abittiWriteFile;
  saveToArrayBuffer = abittiSaveToArrayBuffer;
  openFromArrayBuffer = abittiOpenFromArrayBuffer;
} else {
  showOpenDialog = async function showOpenDialog(accepts) {
    const options = accepts.map(({ extensions, mimeTypes, name }) => ({
      description: name,
      extensions: extensions.map(extension => `.${extension}`),
      mimeTypes,
    }));
    const file = await fileOpen(options);

    return { file, filepath: file.path || file.name };
  };
}
