import { LitElement, html } from 'lit';

import { Requester } from '@mixins/vst-core-requester-mixin.js';
import { formatter } from '@utils/formatter.js';
import { getText } from '@utils/i18n.js';
import { EventBinder } from '@utils/EventBinder.js';
import { ObservableProperties } from '@mixins/vst-observable-properties-mixin.js';
import { sprintf } from '@libs/sprintf.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import { sensor as sensorIcon } from '@components/vst-ui-icon/index.js';
import { conditionalTemplate } from '@components/directives/conditionalTemplate.js';
import { serviceWorkerInitializer } from '@common/utils/serviceWorker/ServiceWorkerInitializer.js';
import { photogateParamMap } from '../ga-data-collection-settings/photogateParamMap.js';
import { gaBottombarStyles } from './ga-bottombar.css.js';
import { frameFeatures } from '../services/FrameFeatures.js';

import { gaPeerStore } from '../stores/ga-peer.store.js';
import { gaNetworkStore } from '../stores/ga-network.store.js';

import '@components/vst-ui-icon/vst-ui-icon.js';
import '@components/vst-ui-tooltip/vst-ui-tooltip.js';

const inverseFunction = decimal => {
  if (decimal !== 0) {
    return 1 / decimal;
  }
  return decimal;
};

class GaBottombar extends Requester(ObservableProperties(LitElement)) {
  static get observableProperties() {
    return {
      errorType: gaPeerStore,
      online: gaNetworkStore,
      hostId: { store: gaPeerStore, key: 'id' },
      isClient: gaPeerStore,
    };
  }

  static get properties() {
    return {
      _collectionModeDisabled: { state: true },
      mode: {
        type: String,
        reflect: true,
      },
      sessionType: {
        type: String,
        reflect: true,
      },
      hasBluetooth: {
        type: Boolean,
        reflect: true,
      },
      dataShareSource: {
        type: String,
        reflect: true,
      },
      collectionRate: {
        type: String,
      },
      collectionTimeUnits: {
        type: String,
      },
      photogateSubmode: {
        type: String,
      },
      deviceNeedsFirmwareUpdate: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.mode = '';
    this.sessionType = 'DataCollection';
    this.hasBluetooth = true;
    this.dataShareSource = '';
    this._collectionModeDisabled = false;
    this.collectionRate = '';
    this.collectionTimeUnits = '';
    this.photogateSubmode = '';
    this.deviceNeedsFirmwareUpdate = false;
    this._eventBinder = new EventBinder();
    frameFeatures.addEventListener('frame-features-updated', () => this.requestUpdate());
  }

  updated(changedProperties) {
    changedProperties.forEach(async (oldValue, propName) => {
      if (propName === 'mode') {
        this.modeChanged(this.mode);
      } else if (propName === 'sessionType') {
        this.typeChanged(this.sessionType);
      }
    });
  }

  firstUpdated() {
    [
      this.$dataWorld,
      this.$dataCollection,
      this.$deviceManager,
      this.$popoverManager,
      this.$urlHandler,
    ] = this.requestServices([
      'dataWorld',
      'dataCollection',
      'deviceManager',
      'popoverManager',
      'urlHandler',
    ]);

    this._eventBinder.bindListeners({
      source: this.$dataWorld,
      target: this,
      eventMap: {
        'session-ended': 'onDataWorldSessionEnded',
        'session-source-name-changed': 'onDataWorldSessionSourceNameChanged',
        'session-started': 'onDataWorldSessionStarted',
        'collection-preparing': 'onDataWorldCollectionPreparing',
        'collection-stopped': 'onDataWorldCollectionStopped',
      },
    });

    this._eventBinder.bindListeners({
      source: this.$dataCollection,
      target: this,
      eventMap: {
        'collection-params-changed': 'onDataCollectionCollectionParamsChanged',
      },
    });

    this._eventBinder.bindListeners({
      source: this.$deviceManager,
      target: this,
      eventMap: {
        'device-list-changed': 'onDeviceListChanged',
      },
    });

    const dcParams = this.$dataCollection.getCollectionParams();
    this.collectionParamsUpdated(dcParams);

    this.meterContainerEl = this.querySelector('vst-core-meter-container');

    // respond to ally.js from externally reaching in and enabling
    // the collection mode button by un-doing the change
    // FIXME: Replace this hack fix and others like it with a cleaner solution
    this.disabledChangeObserver = new MutationObserver(mutationsList => {
      const changedToEnabled = mutationsList.find(
        mutation => mutation.attributeName === 'disabled' && !mutation.target.disabled,
      );

      if (changedToEnabled && this._collectionModeDisabled === true) {
        // We are not enabled, re-disabling child button
        changedToEnabled.target.disabled = true;
      }
    }).observe(this.shadowRoot.querySelector('button#collection_mode_btn'), { attributes: true });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._eventBinder.unbindAll();
    if (this.disabledChangeObserver) this.disabledChangeObserver.disconnect();
  }

  openDataCollectionSettings() {
    this.dispatchEvent(new CustomEvent('open-data-collection-settings'));
  }

  collectionParamsUpdated(params) {
    let delta;
    let rate;

    if (params.mode && params.mode !== this.mode) {
      this.mode = params.mode;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (params.mode === 'time-based' && params.params && params.params.hasOwnProperty('delta')) {
      delta = formatter.convertTimeUnits(params.params.delta, 's', params.params.units);
      rate = inverseFunction(delta);
      const curRate = formatter.sigFig(rate, 6);
      const curPrecision = formatter.getPrecision(curRate);
      this.collectionRate = sprintf(`%.${curPrecision}f`, curRate);
      this.collectionTimeUnits = `${getText('samples')}/${params.params.units}`;
    }

    if (params.mode === 'photogate-timing') {
      switch (photogateParamMap.backendToFrontend(params.params).measurementType) {
        case 'speed-through-gate':
          this.photogateSubmode = getText('Speed through Gate');
          break;
        case 'linear-motion':
          this.photogateSubmode = getText('Linear Motion');
          break;
        case 'angular-motion':
          this.photogateSubmode = getText('Angular Motion');
          break;
        case 'timer':
          this.photogateSubmode = getText('Timer');
          break;
        case 'gate-state':
          this.photogateSubmode = getText('Gate State');
          break;
        case 'projectile':
          this.photogateSubmode = getText('Projectile Launcher');
          break;
        default:
          this.photogateSubmode = '';
      }
    }
  }

  typeChanged(newType) {
    this.isDataShare = newType === 'DataShare';
    this.isManualEntry = newType === 'ManualEntry';
    this.isDataCollection = newType === 'DataCollection';

    if (!this.isDataShare && !this.isManualEntry && !this.isDataCollection) {
      this.isManualEntry = true;
    }

    this._collectionModeDisabled = !this.isDataCollection;

    this.requestUpdate();
  }

  modeChanged(newMode) {
    this.isTimeBased = newMode === 'time-based';
    this.isEventsWithEntry = newMode === 'events-with-entry';
    this.isSelectedEvents = newMode === 'selected-events';
    this.isDropCounting = newMode === 'drop-counting';
    this.isPhotogateTiming = newMode === 'photogate-timing';

    this.requestUpdate();
  }

  setSensorMessage(message) {
    if (typeof message === 'string') {
      this.meterContainerEl.message = getText('No sensor connected');
      this.meterContainerEl.showMessage = true;
    } else {
      this.meterContainerEl.showMessage = false;
    }
  }

  onDataWorldSessionEnded() {
    this.setSourceName();
  }

  onDataWorldSessionSourceNameChanged(name) {
    this.setSourceName(name);
  }

  onDataWorldSessionStarted(sessionConfig) {
    if (this.$dataWorld.sessionSourceName) {
      this.setSourceName(this.$dataWorld.sessionSourceName);
    }
    this.sessionType = sessionConfig.sessionType;
    this._collectionModeDisabled = !this.isDataCollection;
  }

  onDataWorldCollectionPreparing() {
    this._collectionModeDisabled = true; // force disable the buttons
  }

  onDataWorldCollectionStopped() {
    this._collectionModeDisabled = !this.isDataCollection;
  }

  onDataCollectionCollectionParamsChanged(params) {
    this.collectionParamsUpdated(params);
  }

  onDeviceListChanged(/* combineDeviceList */) {
    // Let the component know if a connected device needs new firmware
    let hasUpdate = false;
    const connectedDevices = this.$deviceManager.getConnectedDevices();
    connectedDevices.forEach(device => {
      if (device.firmware.hasUpdate) {
        hasUpdate = true;
      }
    });
    this.deviceNeedsFirmwareUpdate = hasUpdate;
  }

  setSourceName(sourceName) {
    this.dataShareSource = sourceName;
  }

  reconnectDataShareClient() {
    this.dispatchEvent(new CustomEvent('reconnect-data-share-client'));
  }

  static get styles() {
    return [globalStyles, gaBottombarStyles];
  }

  render() {
    return html`
      <h2 visually-hidden>${getText('Bottom Bar')}</h2>
      <div class="modes-sensors-wrapper">
        <div class="modes">
          <vst-ui-tooltip
            for="#collection_mode_btn"
            position="top"
            content="${getText('Data Collection Settings')}"
          ></vst-ui-tooltip>
          <button
            class="bottombar-btn"
            id="collection_mode_btn"
            @click="${this.openDataCollectionSettings}"
            ?disabled="${this._collectionModeDisabled ||
            (frameFeatures.disabledFeatures.has('mode-switching') &&
              !serviceWorkerInitializer.authoringMode)}"
            aria-label="${getText('Data Collection Settings')}"
          >
            ${this.isDataShare && this.isClient
              ? html`
                  <div class="session-type flex">
                    <span class="mode-prefix" margin="inline-end-2xs">${getText('Mode')}</span>
                    <span margin="inline-end-xs">${getText('Data Share')}</span>

                    <div
                      class="datashare-connection-status ${this.errorType === ''
                        ? 'datashare-connection-status--online'
                        : 'datashare-connection-status--offline'}"
                      margin="inline-end-2xs"
                    ></div>
                    <span class="mode-prefix" margin="inline-end-2xs">${getText('ID')}</span>
                    <span>${this.hostId}</span>
                  </div>
                `
              : ''}
            ${this.isDataShare && !this.isClient
              ? html`
                  <div class="session-type">
                    <span class="mode-prefix">${getText('Mode')}</span>
                    <span class="mode-prefix">${getText('Data Share')}</span>
                    <span class="mode-name" id="data_share_source">${this.dataShareSource}</span>
                  </div>
                `
              : ''}
            ${this.isManualEntry
              ? html`
                  <div class="session-type">
                    <span class="mode-prefix">${getText('Mode')}</span>
                    <span class="mode-name">${getText('Manual Entry')}</span>
                  </div>
                `
              : ''}
            ${this.isDataCollection
              ? html`
                  <div class="session-type type-data-collection">
                    ${this.isTimeBased
                      ? html`
                          <div class="mode">
                            <span class="mode-prefix">${getText('Mode')}</span>
                            <span class="mode-name">${getText('Time Based')}</span>

                            <span class="mode-prefix">${getText('Rate')}</span>
                            <span>${this.collectionRate}</span>
                            <span class="mode-name">${this.collectionTimeUnits}</span>

                            <!--<span class="mode-prefix">Duration</span>
                                  <span class="mode-name">30s</span>-->
                          </div>
                        `
                      : ''}
                    ${this.isEventsWithEntry
                      ? html`
                          <div class="mode mode-events-with-entry">
                            <span class="mode-prefix">${getText('Mode')}</span>
                            <span class="mode-name">${getText('Events with Entry')}</span>
                          </div>
                        `
                      : ''}
                    ${this.isSelectedEvents
                      ? html`
                          <div class="mode mode-selected-events">
                            <span class="mode-prefix">${getText('Mode')}</span>
                            <span class="mode-name">${getText('Selected Events')}</span>
                          </div>
                        `
                      : ''}
                    ${this.isDropCounting
                      ? html`
                          <div class="mode mode-drop-counting">
                            <span class="mode-prefix">${getText('Mode')}</span>
                            <span class="mode-name">${getText('Drop Counting')}</span>
                          </div>
                        `
                      : ''}
                    ${this.isPhotogateTiming
                      ? html`
                          <div class="mode mode-photogate">
                            <span class="mode-prefix">${getText('Mode')}</span>
                            <span class="mode-name">${getText('Photogate Timing')}</span>
                            ${this.photogateSubmode
                              ? html` <span>${this.photogateSubmode}</span> `
                              : ''}
                          </div>
                        `
                      : ''}
                  </div>
                `
              : ''}
          </button>
          ${this.isDataShare && this.isClient && this.errorType === 'offline' && this.online
            ? html`
                <button class="bottombar-btn" @click="${this.reconnectDataShareClient}">
                  ${getText('Reconnect')}
                </button>
              `
            : ''}
        </div>

        <div class="sensors" id="sensors">
          <slot></slot>
        </div>
      </div>
      ${conditionalTemplate(
        !frameFeatures.disabledFeatures.has('sensor-connect') &&
          !this.$urlHandler?.isReadOnly &&
          this.isDataCollection,
        html`
          <vst-ui-tooltip
            for="#device_manager"
            position="top"
            content="${getText('Sensor Setup')}"
          ></vst-ui-tooltip>
          <button
            aria-label="${getText('Sensor Setup')}"
            class="device-manager-btn bottombar-btn"
            id="device_manager"
            ?disabled=${this.$urlHandler?.isGroupClient && !this.$urlHandler?.groupComplete}
            @click="${() => {
              this.dispatchEvent(
                new CustomEvent('open-sensor-dialog', {
                  bubbles: true,
                  composed: true,
                }),
              );
            }}"
          >
            <vst-ui-icon .icon="${sensorIcon}" class="device-manager-icon"></vst-ui-icon>
          </button>
        `,
      )}
    `;
  }
}

customElements.define('ga-bottombar', GaBottombar);
