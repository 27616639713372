import EventTarget from '@ungap/event-target';

import { RTCDataShareHost } from './rtc-data-share/RTCDataShareHost.js';
import { RTCDataShareClient } from './rtc-data-share/RTCDataShareClient.js';

import { gaPeerStore } from '../stores/ga-peer.store.js';

export class PeerConnection extends EventTarget {
  /**
   * Creates a PeerConnection object and adds the service references
   *
   * @param {Object} services the dataWorld/dataCollection/api services to pass into the RTCDataShareCommon based classes
   * @param {ReplayAPI} services.api
   * @param {DataWorld} services.dataWorld
   * @param {DataCollection} services.dataCollection
   */
  constructor(services) {
    super();
    this.services = services;
  }

  /**
   * Initiate the Peer object and start listening for connections.
   * This could potentially move into the `constructor` when we can properly lazy load
   *
   * @param {Boolean} turnEnabled Do we want to include the TURN servers?
   */
  async initiate(turnEnabled = true) {
    if (this.peer) {
      await this.dispose();
    }
    const stunServer = {
      url: 'stun:global.stun.twilio.com:3478',
      urls: 'stun:global.stun.twilio.com:3478',
    };
    let turnServers = [];
    if (turnEnabled)
      turnServers = await fetch(
        'https://q1ts9bzhdk.execute-api.us-west-2.amazonaws.com/default/twilio-turn-credentials',
      )
        .then(response => response.json())
        .then(json => json.iceServers.filter(server => server.url.includes('turn')));
    const [peerHost, peerPort] = PEER_SERVER_URL.split(':');
    const Module = await import('peerjs');
    const Peer = Module.Peer ?? Module.default.Peer;
    this.peer = new Peer({
      host: peerHost,
      secure: !peerPort,
      port: peerPort || 443,
      config: { iceServers: [stunServer, ...turnServers] },
    });

    this.peer.on('connection', this.clientConnected.bind(this));
    // this.peer.on('close', () => console.log('*** PEER GOT CLOSED'));
    this.peer.on('disconnected', () => {
      this.peer.destroy();
      delete this.peer;
    });

    return new Promise(resolve => {
      this.peer.on('open', peerId => {
        this.peerId = peerId;
        resolve(peerId);
        gaPeerStore.updateErrorType('');
      });
      this.peer.on('error', error => {
        console.error(`Peer received error: '${error.type}'. ${error.message}`);
        gaPeerStore.updateErrorType(error.message); // TODO translate error.message into errorType
      });
    });
  }

  /**
   * Initiates a connection to a `RTCDataShareHost`
   *
   * @param {String} destPeerId the `id` of the Peer you would like to connect to
   */
  connect(destPeerId) {
    return new Promise(resolve => {
      this.hostDataConnection = this.peer.connect(destPeerId, { reliable: true });
      this.hostDataConnection.on('open', () => {
        this.openedServerConnection(() => resolve(this.clientDataShare));
      });
    });
  }

  sessionDidEnd() {
    this.dispatchEvent(new CustomEvent('rtc-datashare-did-end'));
  }

  /**
   * confirms with the client if they want to follow the host to a new session they have started, or keep their data and go "offline"
   */
  confirmFollowHostNewExperiment() {
    this.dispatchEvent(new CustomEvent('rtc-datashare-confirm-follow-host'));
  }

  /**
   * a handler for when a client peer connects
   *
   * @param {DataConnection} peerDataConnection the `DataConnection` object provided by PeerJs that wraps WebRTC's DataChannel
   */
  async clientConnected(peerDataConnection) {
    // TODO: When possible - const { RTCDataShareHost } = await import("./rtc-data-share/RTCDataShareHost.js");
    if (!this.dataShareHost) {
      this.dataShareHost = new RTCDataShareHost({
        ...this.services,
        peerConnection: this,
      });
      await this.dataShareHost.start();
    }

    console.assert(this.dataShareHost);

    peerDataConnection.on('open', () => {
      this.dataShareHost.addDataConnection(peerDataConnection);
      gaPeerStore.updateConnectionCount(this.dataShareHost.connectedPeers);

      peerDataConnection.on('close', () => {
        this.dataShareHost?.removeDataConnection(peerDataConnection);
        gaPeerStore.updateConnectionCount(this.dataShareHost?.connectedPeers ?? 0);
      });
      peerDataConnection.on('error', error =>
        this.handleClientDataConnectionErrors(peerDataConnection, error),
      );
    });
  }

  handleClientDataConnectionErrors(connection, error) {
    this.errored = true;
    console.error(`Host lost connection to client ${connection.peer}.`);
    console.error(error);
    gaPeerStore.updateErrorType(error.message);
  }

  /**
   * for to dispose
   */
  async dispose() {
    if (this.dataShareHost) {
      await this.dataShareHost.stop();
      delete this.dataShareHost;
    }

    if (this.hostDataConnection) {
      this.hostDataConnection.close();
      delete this.hostDataConnection;
    }
    if (this.peer) {
      this.peer.disconnect();
    }

    if (this.clientDataShare) {
      delete this.clientDataShare;
    }
    gaPeerStore.updateConnectionCount(0);
    delete this.peerId;
    delete this.peer;
  }

  /**
   * Handling the opening of a connection to a server peer event
   * @param {function} completion called when data share client has completed handshaking with host.
   */
  openedServerConnection(completion) {
    // TODO: When possible - const { RTCDataShareClient } = await import("./rtc-data-share/RTCDataShareClient.js");
    this.clientDataShare = new RTCDataShareClient({
      peerConnection: this,
      connectionCompletion: completion,
      dataWorld: this.services.dataWorld,
      dataCollection: this.services.dataCollection,
    });
  }
}
