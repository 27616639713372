/* eslint-disable max-classes-per-file */
import { ErrorBarType } from '@api/common/ColumnGroup';
import { cloneDeep } from 'lodash-es';
import { RTCDataShareCommon } from './RTCDataShareCommon';

/**
 * Class that implements the application's DataShare client behavior. Is constructed with either a PeerConnection or an RTCDataChannel obbject
 * which represents the connection to RTC peer which is running as a server.
 */
export class RTCDataShareClient extends RTCDataShareCommon {
  constructor(params) {
    super();

    this.name = 'Client';
    this._pollFuncAttempts = 3;
    this._allDataReceived = false;
    this._useByteDecoding = false;
    this._byteEncodingRequestTimeout = null;
    this._textDecoder = new TextDecoder();
    this._dataMarkIdMap = {};

    const { dataWorld, dataCollection } = params;
    this.$dataWorld = dataWorld;
    this.$dataCollection = dataCollection;

    const { peerConnection, name, connectionCompletion } = params;
    this.peerConnection = peerConnection;
    if (name) this.name = name;

    if (peerConnection.hostDataConnection) {
      this.hostDataConnection = peerConnection.hostDataConnection;
      this.hostDataConnection.on('data', data => {
        try {
          const messageData = this._useByteDecoding ? this._textDecoder.decode(data) : data;
          this.onReceiveMessage(peerConnection.hostDataConnection, JSON.parse(messageData));
        } catch (err) {
          // We've received a block of data which can't be JSON-parsed. We'll ask the client to start byte encoding all of its message.
          if (!this._useByteDecoding && !this._byteEncodingRequestTimeout) {
            // First ask the host to send us byte-encoded packages from now on (see 'case byte-encoding-enabled:' in `onReceiveMessage()` below):
            this.hostDataConnection.send(JSON.stringify({ type: 'enable-byte-encoding' }));

            // Set up a timeout in case the host doesn't understand what we just told it to do.
            this._byteEncodingRequestTimeout = setTimeout(() => {
              peerConnection.handleClientDataConnectionErrors(this.hostDataConnection, {
                type: 'encoding-error',
                message: 'Host does not support bytewise encoding.',
              });
            }, 5000);

            console.warn('Could not decode host data. Requesting byte encoding.');
          } else {
            console.error('Got the following error:');
            console.error(err);
            peerConnection.handleClientDataConnectionErrors(this.hostDataConnection, {
              type: 'data-error',
              message: 'Could not parse data from host. Try re-connecting.',
            });
          }
        }
      });
      this.hostDataConnection.on('error', error => {
        peerConnection.handleClientDataConnectionErrors(this.hostDataConnection, error);
      });
    } else {
      console.error(
        'RTCDataShareClient must be constructed with either a peerConnection or a data channel.',
      );
    }

    if (connectionCompletion) this.connectionCompletion = connectionCompletion;
  }

  /**
   * Creates a new http shim which can be substituted into a DataShareClient as its http implementation.
   */
  createHttpShim() {
    class HTTPShim {
      constructor(client) {
        this.client = client;
      }

      async getJSON(urlString) {
        const jsonObject = this.client.getEntryForKey(urlString);
        if (!jsonObject) {
          throw new Error(`HTTPShim: could not find ${urlString} in data map.`);
        }

        return cloneDeep(jsonObject);
      }
    }

    return new HTTPShim(this);
  }

  /**
   * This will prepare this client instance for switching to a new session.
   * Call this in reponse `host-new-session` message, or any time the session
   * id changes outside of the normal message flow (e.g app moved from bg to fg)
   */
  prepareForNewSession() {
    this.hostStartingNewExperiment = true;
    this.peerConnection.confirmFollowHostNewExperiment();
  }

  onReceiveMessage(sender, obj) {
    super.onReceiveMessage(sender, obj);
    // stop message flow if host triggered a new experiment otherwise the data on the client clears before they confirm if they want to keep it
    if (this.hostStartingNewExperiment) return;
    // Make sure we receive the 'all-data' (and 'byte-encoding-enabled') message before handling any other messages to prevent httpShim errors (GA-5317, JK 20210406.)
    if (
      !this._allDataReceived &&
      !(obj.type === 'all-data' || obj.type === 'byte-encoding-enabled')
    )
      return;

    switch (obj.type) {
      case 'all-data':
        // Check the info. If our host's idsVersion is >= 1.1 then we'll request byte encoding.
        if (!this._useByteDecoding) {
          const idsVersion = obj.data['/info']?.idsVersion;
          if (idsVersion) {
            const [major = 1, minor = 0] = idsVersion.split('.').map(p => parseInt(p));
            if (major === 1 && minor >= 1) {
              sender.send(JSON.stringify({ type: 'enable-byte-encoding' }));
              return;
            }
          }
        }
        this.importDataMap(obj.data);
        this._allDataReceived = true;
        if (this.connectionCompletion) {
          this.connectionCompletion();
          this.connectionCompletion = null;
        }
        this.triggerStatusFetch();
        this.sendMessage({ type: 'client-info', data: RTCDataShareCommon.platformInfo });
        break;
      case 'update-col':
        // console.log(`${this.name} updating column ${obj.colId}. Count = ${obj.data['values'].length}`);
        this.setColumnData(obj.colId, obj.data);
        break;
      case 'update-status':
        this.setStatus(obj.data);
        break;
      case 'update-info':
        this.setInfo(obj.data);
        break;
      case 'remove-column':
        this.removeColumnData(obj.colId);
        break;
      case 'live-readout':
        this.onLiveReadout(obj);
        break;
      case 'host-new-session':
        this.prepareForNewSession();
        break;
      case 'session-did-end':
        if (obj.data !== 'running') {
          this.peerConnection.sessionDidEnd();
          this.peerConnection.dispose();
        }
        break;
      case 'reset-views':
        {
          // Clear the views list.
          const oldStatus = this.getEntryForKey('/status');
          this.setStatus({ ...oldStatus, views: {}, viewListTimeStamp: obj.newViewListCount });
          this.triggerStatusFetch();
        }
        break;
      case 'byte-encoding-enabled':
        // Cancel time out
        clearTimeout(this._byteEncodingRequestTimeout);
        this._byteEncodingRequestTimeout = null;

        // Set decode flag and ask host to re-send "all data" to us.
        this._useByteDecoding = true;
        this.sendMessage({ type: 'request-all-data' });
        break;
      case 'data-marks-changed':
        this._updateDataMarks(obj.datamarks);
        break;
      case 'error-bar-info-changed':
        this._updateErrorBars(obj.colId, obj.info);
        break;
      default:
        console.warn(`Unknown object type: ${obj.type}`);
        break;
    }
  }

  /**
   * Updates data marks as they change on the host.
   * @param {Object} marks
   */
  async _updateDataMarks(marks) {
    // Build a map to cross reference data set foreign (datashare) id with actual id:
    const dataSetIdMap = {};
    this.$dataWorld.getDataSets().forEach(ds => {
      dataSetIdMap[ds.foreignId] = ds.id;
    });

    // Preflight marks: adjust datasets, and build a set of graph id's.
    const idSet = new Set();
    marks.forEach(async m => {
      // Remap the data set id.
      m.dataSetId = dataSetIdMap[m.dataSetId];
      // Fetch the graph ids:
      m.appearanceInfo.forEach(a => idSet.add(a.graphId));
    });

    if (idSet.size > 3)
      console.warn(
        'Datamarks over DataSharing is advertising > 3 graphs. This may cause unintended behavior.',
      );

    // Build map between host and local graph ids.
    const hostIds = [...idSet];
    hostIds.sort((s1, s2) => s1 >= s2);
    const localIds = this.$dataWorld.registeredGraphIds;
    const dsIdMap = {};
    for (let i = 0; i < hostIds.length; i++) {
      dsIdMap[hostIds[i]] = localIds[i]?.udmId ?? 'n/a';
    }

    marks.forEach(async m => {
      // Iterate appearances and re-map graph ids:
      m.appearanceInfo.forEach(app => {
        app.graphId = dsIdMap[app.graphId];
      });

      // Retrieve a mapped data mark id if available.
      const mappedId = this._dataMarkIdMap[m.id] ?? null;

      // If we've mapped the id, then we've encountered it before. We'll simply import the changes which will update UDM and the UI.
      if (mappedId) {
        const existingMark = this.$dataWorld.getDataMarkWithId(mappedId);
        console.assert(existingMark);
        existingMark.udmImport(m);
      } else {
        // We haven't recorded this data mark yet, so we'll create it.
        const newDatamark = await this.$dataWorld.createDataMarkForDataSharing(m);
        // Record in the map.
        this._dataMarkIdMap[m.id] = newDatamark.id;
      }
    });

    // Purge datamarks which have been deleted by the host.
    const deleted = [];
    this.$dataWorld._dataMarks.forEach(dm => {
      if (!marks.find(m => this._dataMarkIdMap[m.id] === dm.id)) deleted.push(dm);
    });
    deleted.forEach(dm => this.$dataWorld.removeDataMark(dm));
  }

  /**
   * Called when error bars information changes. This will find and apply changes
   * to the group associated with a particular column.
   * @param {String} hostColumnId foreign Id of column whose error bars have changed
   * @param {import('./RTCDataShareCommon.js').ErrorBarInfo} errBarInfo information describing changes to error bars.
   */
  _updateErrorBars(hostColumnId, errBarInfo) {
    // Function returns a column whose foreignId matches the passed in id.
    const findColWithForeignId = id =>
      this.$dataWorld.getAllColumns().find(c => `${c.foreignId}` === `${id}`);

    // Resolve the host column id.
    const { columnId, type, value } = errBarInfo;
    const hostColumn = findColWithForeignId(hostColumnId);
    if (!hostColumn) {
      console.error(
        `Data sharing: unable to find column with foreignId ${hostColumnId}. This indicates that our column list is out of sync with the host. Did we receive 'all-data' on connect?`,
      );
      return;
    }

    // Find the host column's group. We'll set error bar values on the group.
    const { group } = hostColumn;
    if (!group) {
      console.error('Data sharing: could not find group to assign error bar attributes.');
      return;
    }
    const groupParams = {};
    // If the columnId is a number, we need to track down the corresponding column and pass in its id.
    if (type === ErrorBarType.COLUMN) {
      const col = findColWithForeignId(columnId);
      if (col) {
        groupParams.errorBarColumnId = col.id;
      } else {
        console.error(`Data sharing: could not find error bar column ${columnId}, type = COLUMN.`);
      }
    }

    // Set remaining error bar params.
    groupParams.errorBarType = type;
    groupParams.errorBarValue = value;

    // And commit to the store. This will round-trip back to the group object itself.
    this.$dataWorld.updateColumnGroup(group.id, groupParams);
  }

  /**
   * Called when we receive live readout notifications.
   * @param {object} msg live readout params
   * @param {string} msg.name name of sensor column
   * @param {*} msg.colId the column id.
   * @param {number} msg.value the live readout value.
   * @param {string} msg.units units of the live readout.
   */
  /* eslint-disable */
  onLiveReadout(msg) {
    // console.log(`Column ${msg.name} (${msg.colId}) reporting live value change: ${msg.value} ${msg.units}`);
  }
  /* eslint-enable */

  /**
   * Called to emit a message to the client DS implementation.
   */
  triggerStatusFetch() {
    // TODO: Look for a cleaner way to sequence the status fetch after the DataShare session has started
    if (typeof this.pollFunc === 'function') {
      this.pollFunc();
    } else if (this._pollFuncAttempts > 0) {
      --this._pollFuncAttempts;
      setTimeout(() => {
        this.triggerStatusFetch();
      }, 100);
    } else {
      console.error('this.pollFunc was never set');
    }
  }

  sendMessage(messageObject) {
    const blob = JSON.stringify(messageObject);
    if (this.hostDataConnection.open) this.hostDataConnection.send(blob);
    else console.warn(`HOST ${this.hostDataConnection.peer} is not open.`);
  }

  // Override
  statusDidChange(status) {
    super.statusDidChange(status);
    this.triggerStatusFetch();
  }
}
