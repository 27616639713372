import * as platform from './PLATFORM_ID';

const sendMessage = (msg, options) => {
  if (platform.sendMessage) {
    platform.sendMessage(msg, options);
  } else console.log(`no background page to send msg: ${msg}`);
};

export const createBgPageComm = () => ({
  sendMessage,
});
