import { getDefaultType, getName, getMimeType } from '@utils/fileio-helpers.js';
import {
  notifyFileSaved,
  onSaveCallbacks,
  getFileIOServices,
  setFileIOServices,
} from './helpers.js';

import * as platform from './PLATFORM_ID';
import { writeTemporaryFile, chooseFileName } from './cordova.js';

let _appName = ''; // assigned to the manifest app name in the creator
let _hasNativeFileSystem = 'showOpenFilePicker' in window;
let _fileData = false;

// for FileSystem API formatting of acceptable types
const acceptsToTypesShim = accepts => {
  return accepts.map(accept => ({
    description: accept.description,
    accept: { '*/*': accept.extensions.map(extension => `.${extension}`) },
  }));
};

async function showOpenDialog(accepts) {
  if (platform.showOpenDialog) {
    const fileData = await platform.showOpenDialog(accepts);
    _fileData = {
      fileHandle: fileData.file.handle,
      filepath: fileData.file.name,
      filename: fileData.file.name,
    };
    return fileData;
  }

  throw new Error('showOpenDialog is not implemented for this platform.');
}

const createFileExtension = ([
  {
    extensions: [fileExtension],
  },
]) => {
  return fileExtension ? `.${fileExtension}` : '';
};

async function showSaveDialog(args) {
  if (platform.showSaveDialog) {
    return platform.showSaveDialog(args);
  }

  if (!_hasNativeFileSystem) {
    return Promise.resolve({
      filename: args.suggestedName,
      filepath: args.suggestedName,
    });
  }

  const { accepts } = args;

  const getNewFileHandle = async () => {
    const opts = {
      types: acceptsToTypesShim(accepts),
      suggestedName: `${args.suggestedName}${createFileExtension(accepts)}`,
    };

    const handle = await window.showSaveFilePicker(opts);
    return handle;
  };

  const fileHandle = await getNewFileHandle();

  _fileData = {
    fileHandle,
    filename: fileHandle.name,
    filepath: fileHandle.name,
  };

  return _fileData;
}

async function writeFile(options = {}) {
  if (platform.writeFile) {
    return platform.writeFile(options);
  }

  if (!options.blob) {
    return Promise.reject();
  }

  try {
    if (!_hasNativeFileSystem) {
      // generate a download link for the blob and simulate a click
      const name = options.filepath ? options.filepath : 'Document';
      const docURL = window.URL.createObjectURL(options.blob);
      const link = document.createElement('a');
      link.download = name;
      link.target = '_blank';
      link.title = name;
      link.href = docURL;

      const type = getDefaultType();
      const nameParts = name.split('.');
      const ext = nameParts.pop();
      if (ext !== type) link.download = `${name}.${type}`;

      link.click();
      return name;
    }

    const { fileHandle } = options;
    // Support for Chrome 82 and earlier.
    if (fileHandle.createWriter) {
      // Create a writer (request permission if necessary).
      const writer = await fileHandle.createWriter();
      // Write the full length of the contents
      await writer.write(0, options.blob);
      // Close the file and write the contents to disk
      await writer.close();
      return true;
    }
    // For Chrome 83 and later.
    // Create a FileSystemWritableFileStream to write to.
    const writable = await fileHandle.createWritable();
    // Write the contents of the file to the stream.
    await writable.write(options.blob);
    // Close the file and write the contents to disk.
    await writable.close();
    return fileHandle.name;
  } catch (err) {
    return Promise.reject(err);
  }
}

function restoreEntry(filepath) {
  // eslint-disable-line class-methods-use-this
  if (platform.restoreEntry) {
    return platform.restoreEntry(filepath);
  }

  return Promise.resolve();
}

async function saveFile(options = {}) {
  if (platform.saveFile) {
    const _options = { appName: _appName, ...options };
    return platform.saveFile(_options);
  }

  try {
    const { dataWorld } = getFileIOServices();
    const defaultType = getDefaultType();
    const type = options.type || defaultType;
    const { decimal } = options;
    let currentFilepath = dataWorld.userFileMetaData.filepath;
    const isNativeFile = type === defaultType;
    const suggestedName = options.suggestedName || getName(dataWorld.sessionName, isNativeFile);

    if (!currentFilepath) {
      // If we have no filepath, then use generrated name
      currentFilepath = `${suggestedName}.${type}`;
    }

    const exportData = async result => {
      const { fileHandle, filename, filepath } = result;
      const { filepath: _filepath } = await dataWorld.exportData({
        type,
        fileHandle,
        filename,
        filepath,
        decimal,
      });

      if (isNativeFile) {
        notifyFileSaved(_filepath);
      }

      return fileHandle;
    };

    if (!options.saveAs && _fileData) {
      return exportData(_fileData);
    }

    const fileData = await showSaveDialog({
      suggestedName,
      accepts: [
        {
          description: _appName,
          mimeTypes: [getMimeType(type)],
          extensions: [type],
        },
      ],
    });

    return exportData(fileData);
  } catch (err) {
    if (err.message === 'The user aborted a request.') {
      return false;
    }

    return err;
  }
}

export function readFile(filepath) {
  console.assert(filepath);

  return new Promise((resolve, reject) => {
    const fileHandle = window.resolveLocalFileSystemURL(filepath);

    fileHandle.file(file => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve({
          file: reader.result,
          filepath,
        });
      };

      reader.readAsText(file);
    }, reject);
  });
}

async function saveToArrayBuffer() {
  if (platform.saveToArrayBuffer) return platform.saveToArrayBuffer();
  throw new Error('ArrayBuffer based save is not implemented.');
}

async function openFromArrayBuffer(buf) {
  if (platform.openFromArrayBuffer) return platform.openFromArrayBuffer(buf);
  throw new Error('ArrayBuffer based open is not implemented.');
}

export function createFileIO(appName, dataWorld, popoverManager) {
  _appName = appName;
  _hasNativeFileSystem = 'showOpenFilePicker' in window;
  setFileIOServices({ dataWorld, popoverManager });

  const fileIO = {
    saveFile,
    showOpenDialog,
    showSaveDialog,
    writeFile,
    restoreEntry,
    onSave(cb) {
      onSaveCallbacks.add(cb);
    },
    writeTemporaryFile,
    chooseFileName,
    saveToArrayBuffer,
    openFromArrayBuffer,
  };

  dataWorld.setFileIO(fileIO); // FIXME: circular dependency between DataWorld and fileIO

  return fileIO;
}
