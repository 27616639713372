/* eslint-disable import/named */
/* global vstRequestDevice */
import { css, html } from 'lit';
import { when } from 'lit/directives/when.js';
import { MobxReactionUpdate } from '@adobe/lit-mobx/lib/mixin.js';
import { VseApp } from '@components/vse-app/vse-app.js';

import { connect } from 'pwa-helpers';
import platform from 'platform';

import { Provider } from '@mixins/vst-core-provider-mixin.js';
import { DialogMediator } from '@mixins/vst-core-dialog-mediator-mixin.js';
import { ObservableProperties } from '@mixins/vst-observable-properties-mixin.js';

import { initServices, addProgressCallback } from '@services/init-services.js';
import { createGraphModeTransition } from '@utils/graphModeTransition.js';
import { computeGraphsAutoLayout, computeManualEntryLayout } from '@utils/appAutoLayout.js';
import { getText } from '@utils/i18n.js';
import { EventBinder } from '@utils/EventBinder.js';
import { keyboardEvents } from '@utils/keyboardEvents.js';
import { getOpenableTypes } from '@utils/fileio-helpers.js';

import { closeCommonDialogEvent } from '@utils/closeCommonDialogEvent.js';
import { isPrivilegedIframe } from '@utils/isPrivilegedIframe.js';

import { globalStyles } from '@styles/vst-style-global.css.js';
import { customPropertyStyles } from '@styles/vst-style-custom-properties.css.js';

import { sprintf } from '@libs/sprintf.js';

import { applyNativeCompatibility } from '@services/adapters/native-compatibility/index.js';
import { conditionalTemplate } from '@components/directives/conditionalTemplate.js';

import { vstLayoutStore } from '@stores/vst-layout.store.js';
import { vstAuthStore } from '@stores/vst-auth.store.js';

import { vstPresentationStore } from '@stores/vst-presentation.store.js';

import { syncServices } from '@utils/serviceSync/serviceSync.js';

import { autorun } from 'mobx';
import { serviceWorkerInitializer } from '@common/utils/serviceWorker/ServiceWorkerInitializer.js';
import { isFeatureFlagEnabled } from '@services/featureflags/featureFlags.js';
import {
  collectLicenseDataForAnalytics,
  capturePlatformAnalyticsData,
  captureDeviceConnectedAnalyticsData,
  captureSensorAddedAnalyticsData,
  captureSessionAnalyticsData,
  initAnalytics,
} from '@common/utils/analyticsHelpers.js';
import { initGAServices } from './services/init-ga-services.js';
import { store } from './redux/store.js';
import {
  addPopover,
  removePopover,
  resetSession,
  updateIsSessionEmpty,
  updateAccessibilityScale,
  updateGraphsAutoLayout,
  autoUpdateBaseRange,
} from './redux/actions.js';

import { gaPeerStore } from './stores/ga-peer.store.js';
import { gaNetworkStore } from './stores/ga-network.store.js';

import './ga-bottombar/ga-bottombar.js';
import './ga-logo/ga-logo.js';
import './ga-sample-data/ga-sample-data.js';

import '@components/vst-ui-logo-vernier/vst-ui-logo-vernier.js';
import '@components/vst-ui-dialog/vst-ui-dialog.js';
import '@components/vst-ui-dialog/vst-ui-dialog-manager.js';
import '@components/vst-core-meter-container/vst-core-meter-container.js';
import '@components/vst-ui-splash-screen/vst-ui-splash-screen.js';

const GDX_MELT_AUTO_ID = 422;
const BTA_MELT_AUTO_ID = 92;
const DAYS_TO_SHOW_WARNING = 30;
const DAYS_TO_SHOW_ERROR = 15;
/** @type {Array<{label:string, type:string}} */
const webUSBOptions = [
  { label: getText('Go Direct USB'), type: 'goDirectFamilyUSB' },
  { label: getText('Go! Link'), type: 'goLinkUSB' },
  { label: getText('Go!Temp'), type: 'goTempUSB' },
  { label: getText('Go! Motion'), type: 'goMotionUSB' },
  { label: getText('LabQuest'), type: 'labQuestFamilyUSB' },
];

/** @type {Boolean|[String, Boolean]} false for no udm watching, otherwise its the arugments for the setupUdmWatcher(id, isExperiment) function in an Array */
let watchUDM = false;
/** @type {Boolean} a flag to ensure the udm watcher is only setup once */
let udmWatcherSetupCalled = false;

const onDeviceReady = () => {
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    window.StatusBar?.backgroundColorByHexString('#27292a');
  }
};

const nextTick = timeMs => new Promise(resolve => setTimeout(resolve, timeMs));

export class GaApp extends connect(store)(
  Provider(DialogMediator(MobxReactionUpdate(ObservableProperties(VseApp)))),
) {
  static get properties() {
    return {
      _authoringMode: { state: true },
      _frameFeatures: { state: true },
      _isGroupCollectionComplete: { state: true },
      _isUnsupportedBrowserMessageVisible: { state: true },
      _offlineLoggingStatus: { state: true },
      _remoteRetrievalProgress: { state: true },
      _showDataLoggingStatusDialog: { state: true },
      _showWait: { state: true },
      _waitMessage: { state: true },
      _waitTriggerMeter: { state: true },

      autoSaving: { type: Boolean },
      auxGraphCount: { type: Number },
      dataShareUpdates: { type: Object },
      experimentName: { type: String },
      graphModeTransition: { type: Object },
      isCollecting: { type: Boolean },
      isImportedSession: { type: Boolean },
      isSessionEmpty: { type: Boolean },
      Layout: { type: Object },
      progress: { type: Number },
      sampleExperiments: { type: Object },
      saveError: { type: String },
      savePending: { type: Boolean },
      showDataCollectionSettings: { type: Boolean },
      showDataShareHost: { type: Boolean },
      showDataShareStatus: { type: Boolean },
      showSampleExperiments: { type: Boolean },
    };
  }

  static get observableProperties() {
    return {
      authorized: vstAuthStore,
      colorMode: vstPresentationStore,
      connectionCount: gaPeerStore,
      dataShareStatus: { store: gaPeerStore, key: 'status' },
      daysRemaining: vstAuthStore,
      errorType: gaPeerStore,
      isDataShareClient: { store: gaPeerStore, key: 'isClient' },
      isDataShareHost: { store: gaPeerStore, key: 'isHost' },
      license: vstAuthStore,
      online: gaNetworkStore,
      peerId: { store: gaPeerStore, key: 'id' },
    };
  }

  static get styles() {
    return [
      globalStyles,
      customPropertyStyles,
      css`
        :host {
          --vst-color-brand-400: hsl(182, 100%, 16%);
          --vst-color-brand-300: hsl(182, 100%, 23%);
          --vst-color-brand-200: hsl(182, 70%, 35%);
          --vst-color-brand-100: hsl(182, 60%, 90%);

          background-color: var(--vst-color-bg-secondary);
          height: 100%;

          /* apply safe areas only if the environment specifies them */
          padding: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0)
            env(safe-area-inset-bottom, 0) env(safe-area-inset-left, 0);
        }

        #data_collection_settings_dialog {
          --overflow-y: hidden;
          --dialog-max-block-size: 100%;
          --dialog-z-index: calc(var(--vst-z-popover) - 1);
          --dialog-padding: 0;
        }
        vst-ui-logo-vernier[cover] {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 9001;
          background: var(--vst-color-bg-dark);
        }
        .content-wrapper {
          display: flex;
          flex-direction: column;
          height: calc(100% - var(--chrome-menubar-height, 0px));
        }

        .toolbar {
          border-bottom: 1px solid var(--vst-color-bg-primary);
        }

        .soft_alert_trial {
          z-index: calc(var(--vst-z-popover) - 1);
        }
      `,
    ];
  }

  constructor() {
    super();
    // to get to certain debug options, start with ctrl-b, if we expand this functionality, lets consider `xyzzy` or `Konami` npm packages.
    document.addEventListener('keydown', event => {
      if (event.key === 'b' && event.ctrlKey) {
        this._debugActive = true;
        setTimeout(() => {
          this._debugActive = false;
        }, 500);
      }
      // ctrl-b, ctrl-f activates the feature flags page for debugging/testing
      if (this._debugActive && event.key === 'f' && event.ctrlKey) {
        event.preventDefault();
        (async () => {
          await import('@components/vst-core-feature-flags/vst-core-feature-flags.js');
          this.$services.popoverManager.presentDialog('vst-core-feature-flags', {
            title: getText('Experimental Features'),
          });
        })();
      }
    });

    this._appInitialized = false;
    this._continueDSSession = false;
    this._isUnsupportedBrowserMessageVisible = false;
    this._offlineLoggingStatus = {};
    this._remoteRetrievalProgress = 0;
    this._servicesReady = false;
    this._showDataLoggingStatusDialog = false;
    this._showWait = false;
    this._splashScreenEl = null;
    this._waitMessage = '';
    this._waitTriggerMeter = null;
    this.$services = null;

    /**
     * Ensures that auto save listeners are only ever setup in place once,
     * cancelling any possible subsequent calls to initialize the listeners
     * @type {Boolean}
     */
    this.autoSaving = false;
    this.auxGraphCount = 0;
    this.experimentName = getText('Untitled');
    this.isCollecting = false;
    this.isImportedSession = false;
    this.isSessionEmpty = true;
    this.progress = 0;
    this.showDataCollectionSettings = false;
    this.showDataShareHost = false;
    this.showDataShareStatus = false;
    this.showSampleExperiments = false;

    applyNativeCompatibility(window);
    window.matchMedia('(orientation: portrait)').addListener(() => {
      if (vstLayoutStore.openedPanes === 4) vstLayoutStore.updateLayout({ video: false });
    });
  }

  // #region Lit Lifecycle Callbacks
  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();

    if (platform.name === 'Chrome' && PLATFORM_ID !== 'web') {
      import('@components/vst-ui-chromebar/vst-ui-chromebar.js');
      const vstUiChromebarEl = document.createElement('vst-ui-chromebar');
      const appEl = document.querySelector('#app');
      document.body.insertBefore(vstUiChromebarEl, appEl);
      document.body.style.setProperty('--chrome-menubar-height', '32px');
    }
  }

  async firstUpdated() {
    document.addEventListener('deviceready', onDeviceReady, false);
    this.classList = this.colorMode;
    this._splashScreenEl = this.shadowRoot.querySelector('#splash_screen');

    if (platform.name === 'Chrome') {
      // if we're running the chrome packaged app on Mac or Windows, stop the user from moving forward.
      if (
        PLATFORM_ID !== 'web' &&
        (platform.os.family.includes('OS X') || platform.os.family.includes('Windows'))
      ) {
        this._showChromeOnMacWinDialog();
      } else {
        this._splashScreenEl.showProgress = true;
      }

      addProgressCallback(progress => {
        this.progress = progress;
      });
    }

    // Show a message when running the PWA in a browser that's missing support
    // for all connectivity types
    if (
      PLATFORM_ID === 'web' &&
      !vstRequestDevice.hasBluetooth &&
      !vstRequestDevice.hasHID &&
      !vstRequestDevice.hasUSB
    ) {
      this._splashScreenEl.hide();
      this._isUnsupportedBrowserMessageVisible = true;
      return;
    }

    await Promise.allSettled([
      import('./ga-main-content.js'),
      import('./ga-toolbar.js'),
      import('@components/vst-ui-soft-alert/vst-ui-soft-alert.js'),
    ]);

    await this._setupUrlHandler(this.$services.urlHandler);
    await this.initApp();

    autorun(() => {
      if (this.authorized) {
        const {
          sensorWorld: { sensors },
        } = this.$services;
        sensors.forEach(sensor => {
          this.enableDataMarksForMeltStation(sensor);
        });
      }
    });

    // Listen for pause and resume notifications on ios devices -- we can
    // respond to these appropriately by disconnecting and reconnecting data
    // sharing etc.
    if (window.cordova?.platformId === 'ios') {
      document.addEventListener('pause', () => this._handleAppPause());
      document.addEventListener('resume', () => this._handleAppResume());
    }

    this.setAppInitialized();
  }

  updated(changedProperties) {
    if (changedProperties.has('isDataShareHost') || changedProperties.has('isDataShareClient')) {
      import('./ga-datashare/ga-datashare-disconnected.js');
      import('./ga-datashare/ga-datashare-status.js');
    }
    if (changedProperties.has('showDataShareHost')) {
      if (this.showDataShareHost) import('./ga-datashare/ga-datashare-host.js');
    }

    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'colorMode') {
        this.classList = this.colorMode;
        window.StatusBar?.backgroundColorByHexString(
          this.colorMode === 'dark' ? '#27292a' : '#e3e4e5',
        );
        if (this.colorMode === 'dark') window.StatusBar?.styleLightContent();
        else {
          // This isn't working yet, and as a result if a user opens the app with native dark mode set, then changes to light mode in app, the status bar text will be light. A pr is open to make this method change the text color back to dark (https://github.com/apache/cordova-plugin-statusbar/pull/181.)
          window.StatusBar?.styleDarkContent();
        }
      }
    });
  }

  async scheduleUpdate() {
    if (!this._servicesReady) {
      const coreServices = await initServices();
      const { dataWorld, dataCollection, rpc } = coreServices;
      const gaServices = initGAServices({ dataWorld, dataCollection, rpc });

      this.$services = { ...coreServices, ...gaServices };
      this.provideServices(this.$services);
      this._servicesReady = true;
    }
    super.scheduleUpdate();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    const { dataWorld } = this.$services;
    dataWorld.off('session-ended', this.resetStoreSession);
    dataWorld.off('session-remote-id-changed', this.onDataWorldSessionRemoteIdChanged);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line wc/require-listener-teardown
    window.removeEventListener('online', () => gaNetworkStore.updateOnline());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line wc/require-listener-teardown
    window.removeEventListener('offline', this._onOffline.bind(this));
  }
  // #endregion

  // #region getters/setters
  get _dataLoggingStatusHeaderText() {
    return this.$services.deviceManager.getConnectedDevices().at(0)?.offlineLoggingStatus
      ?.offlineStatus === 'waiting'
      ? getText('Remote Logging in Process')
      : getText('Logged Data on Device');
  }

  /** @type {import('./ga-welcome/ga-welcome.js').GaWelcome} */
  get _gaWelcomeEl() {
    return this.shadowRoot.querySelector('ga-welcome');
  }

  /**
   * Returns specific information about this app.
   * @returns {VseAppInfo}
   * @override
   */
  // eslint-disable-next-line class-methods-use-this
  get appInfo() {
    return {
      appName: 'Vernier Graphical Analysis',
      fileExtension: '.gambl',
      sampleExperimentAddress: 'experiments.graphicalanalysis.com',
    };
  }

  get appInitialized() {
    return new Promise(resolve => {
      if (this._appInitialized) resolve();
      else this.addEventListener('app-initialized', () => resolve(), { once: true });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  get persistentHostIframe() {
    return window.frameElement?.hasAttribute('persistent-host');
  }
  // #endregion

  // #region private methods
  _checkIfDataLoggingSupportChanged() {
    const { dataCollection, deviceManager } = this.$services;
    const hasMoreThanOneDeviceConnected = deviceManager.getConnectedDevices().length > 1;
    const hasConnectedDataLoggingDevices = deviceManager.connectedDataLoggingDevices.length > 0;
    const { mode: collectionMode, params } = dataCollection.getCollectionParams();

    if (
      collectionMode === 'time-based' &&
      params.remoteLogging &&
      deviceManager.hasConnectedDevices &&
      (hasMoreThanOneDeviceConnected || !hasConnectedDataLoggingDevices)
    ) {
      // Switch off remote logging if none of the connected devices support it
      params.remoteLogging = false;
      dataCollection.setCollectionParams(collectionMode, params);
    }
  }

  /**
   * Configure a meter that resides in a meter block. This will set up the meter attributes and add live update (etc.) notification bindings.
   * This method can be called repeatedly as devices get plugged in and sensors are enumerated.
   * @param {api/common/Meter.js.Meter} meter to configure.
   */
  _configureBlockMeter(meter) {
    const { dataWorld, sensorWorld } = this.$services;
    const {
      sensorInfo: { sensorId },
    } = meter;

    if (meter.columnId !== 0) {
      let sensor = sensorWorld.getSensorById(sensorId);

      // Since sensors might not be available or may have changed ids during persistence thrash, we can try to match them up using provided hints.
      if (!sensor) {
        sensor = sensorWorld.sensors.find(s => {
          // Ignore sensors that have already been matched by meters.
          if (dataWorld.meters.find(m => m.sensorInfo.id === sensorId)) return false;
          // Match sensors on id, then name, then autoId.
          return (
            s.id === sensorId || s.name === meter.sensorName || s.autoId === meter.sensorAutoId
          );
        });
      }
      // It's okay if sensor is still null at this point.
      this._meterCentral.updateMeterProperties(meter);
    }
  }

  _decrementAuxGraphCount() {
    this.auxGraphCount--;
  }

  _dialogTemplates() {
    return html`
      <ga-welcome
        @dialog-close=${() => {
          this.$services.analytics.trackEvent('dialog_close', { dialog_name: 'Welcome' });
        }}
        @connect-data-sharing=${async () => {
          await import('./ga-datashare/ga-datashare-connect.js');
          this.$services.popoverManager.presentDialog('ga-datashare-connect', {
            title: getText('Data Sharing'),
            events: () => ({
              'start-datashare-client': e => this.onStartDataShareClient(e),
            }),
          });
        }}
        @open-file=${this._handleOpenFile}
        @open-sensor-dialog=${() => this.showSensorDialog()}
        @show-sample-experiments=${async () => {
          this._gaWelcomeEl.close();
          const { readCacheJsonFile } = await import('@utils/file-rw.js');
          this.sampleExperiments = await fetch(EXPERIMENT_MANIFEST_URL)
            .then(response => response.json())
            .catch(() => readCacheJsonFile('experiment-manifest.json'));
          this.showSampleExperiments = true;
          this.requestUpdate();
        }}
      ></ga-welcome>
    `;
  }

  _generateAutoLayout() {
    const { dataWorld, dataCollection, sensorWorld } = this.$services;

    let appAutoLayout = {};
    let graphsLayout = [];

    if (dataWorld.sessionType === 'ManualEntry') {
      appAutoLayout = {
        graph_1: true,
        table: true,
      };
      graphsLayout = computeManualEntryLayout(dataWorld);
    } else if (dataWorld.sessionType === 'DataShare') {
      appAutoLayout = { graph_1: true };
    } else {
      const autoLayout = computeGraphsAutoLayout(
        dataWorld,
        sensorWorld,
        dataCollection.getCollectionParams(),
      );
      // eslint-disable-next-line prefer-destructuring
      appAutoLayout = autoLayout.appAutoLayout;
      graphsLayout = autoLayout.graphAutoLayouts;
    }

    return { appAutoLayout, graphsLayout };
  }

  /** Called when app goes into the background. */
  _handleAppPause() {
    if (gaPeerStore.isClient) {
      // Cache the host id and the current session id.
      const {
        dataWorld: { session },
        gaPeerConnection,
      } = this.$services;
      this._resumeDataShareServerId = gaPeerStore.id;
      this._cachedDataShareSessionId = session.sessionId;
      console.warn(
        `App is pausing. Will disconnect data sharing: ${this._resumeDataShareServerId}`,
      );
      gaPeerConnection.dispose();
    }
  }

  /** Called when app returns to the foreground. Restores data share connection. */
  async _handleAppResume() {
    const {
      _resumeDataShareServerId: serverId = null,
      _cachedDataShareSessionId: sessionId = null,
    } = this;

    if (serverId) {
      console.warn(`App is resuming. Will reconnect data sharing: ${serverId}`);
      const { dataWorld, gaPeerConnection, toast } = this.$services;
      try {
        toast.makeToast(getText(`Reconnecting to host: ${serverId}`));
        this._dataShareClientStarting = true;
        await gaPeerConnection.initiate();
        const rtcClient = await gaPeerConnection.connect(serverId);
        // Check if the host is running a different experiment
        if (rtcClient.sessionId !== sessionId) {
          // If the session id has changed, then kick off the new session
          // sequence.
          rtcClient.prepareForNewSession();
        } else {
          dataWorld.session.replaceRtcClient(rtcClient);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isConnecting = false;
        this._dataShareClientStarting = false;
        this._resumeDataShareServerId = null;
        this._cachedDataShareSessionId = null;
      }
    }
  }

  _handleDataLoggingDisconnect() {
    const { deviceManager } = this.$services;
    const device = this._activeDataLoggingDevice;

    deviceManager.disconnectDevice(device);
    if (this._offlineStatusEventBinding) this._offlineStatusEventBinding.off();
    this._showDataLoggingStatusDialog = false;
  }

  async _handleDataLoggingRetrieval(event) {
    const target = event.composedPath().at(0);
    const { delete: deleteDataAfterRetrieval, disconnect } = event.detail;
    const { dataCollection, deviceManager, dataWorld } = this.$services;
    const device = this._activeDataLoggingDevice;

    if (this._offlineStatusEventBinding) this._offlineStatusEventBinding.off();
    this._remoteRetrievalProgress = 0;

    const progressHandler = percent => {
      this._remoteRetrievalProgress = percent;
    };
    deviceManager.on('retrieval-progress', progressHandler);

    try {
      await dataCollection.stopMeasurements();

      // Enforce import into an empty dataset
      const skipCreateNewRun = dataWorld.currentDataSet && dataWorld.isCurrentDataSetEmpty(true);
      const tagCurrentDataset = skipCreateNewRun && dataWorld.getDataSets().length > 1;

      if (!skipCreateNewRun) {
        // If creating a new dataset for the remote data, tag it with the data origin info, and if the
        // current dataset also contains remote data, tag it too with its own origin info
        const currentName = dataWorld.currentDataSet?.name;

        if (currentName) {
          if (!currentName.includes('[GDX-')) {
            const colIds = dataWorld.currentDataSet.columnIds;
            const deviceIds = {};
            let origin;

            for (const colId of colIds) {
              const col = dataWorld.getColumnById(colId);
              if (col) {
                const stamp = col.origin.timeStamp;
                if (stamp) {
                  const { id } = col.origin;
                  if (!Object.hasOwn(deviceIds, id)) {
                    deviceIds[id] = 0;

                    const count = Object.keys(deviceIds).length;

                    // This only works if all the data comes from the same device
                    if (count > 1) {
                      origin = '';
                      break;
                    }

                    origin = col.originAsString;
                  }
                }
              }
            }

            if (origin) {
              const name = `${currentName} [${origin}]`;
              await dataWorld.updateDataSet(dataWorld.currentDataSet.id, { name });
            }
          }
        }

        const date = new Date(device.offlineLoggingStatus.originTimeStamp);
        const nameTag = `[${device.orderCode} ${device.serialNumber}, ${date.toLocaleString()}]`;
        await dataWorld.createNewDataSet({ nameTag });
      } else if (tagCurrentDataset) {
        const currentName = dataWorld.currentDataSet?.name;
        const date = new Date(device.offlineLoggingStatus.originTimeStamp);
        // prettier-ignore
        const name = `${currentName} [${device.orderCode} ${device.serialNumber}, ${date.toLocaleString()}]`;
        await dataWorld.updateDataSet(dataWorld.currentDataSet.id, { name });
      }
      await dataCollection.retrieveLoggedData({
        deviceId: device.id,
        deleteDataAfterRetrieval,
      });

      if (disconnect) deviceManager.disconnectDevice(device);
      this._showDataLoggingStatusDialog = false;
    } catch (error) {
      // If either of these fails, leave the dialog open
      console.warn(error);
    } finally {
      target.reset();
      deviceManager.removeListener('retrieval-progress', progressHandler);
    }
  }

  _handleDataLoggingStatusClose() {
    const { deviceManager } = this.$services;
    const device = this._activeDataLoggingDevice;

    if (device?.offlineLoggingStatus?.offlineStatus === 'waiting') {
      deviceManager.disconnectDevice(device);
    }

    if (this._offlineStatusEventBinding) this._offlineStatusEventBinding.off();
    this._showDataLoggingStatusDialog = false;
  }

  /** Handle device sensor enum completed */
  async _handleDeviceSensorEnumCompleted() {
    const { deviceManager } = this.$services;
    const devices = deviceManager.connectedDataLoggingDevices;

    for (const device of devices) {
      if (
        device?.supportsOfflineCollection &&
        ['completed', 'waiting'].includes(device.offlineLoggingStatus.offlineStatus)
      ) {
        // eslint-disable-next-line no-await-in-loop
        await import('./ga-data-logging-status/ga-data-logging-status.js');
        this.dispatchEvent(
          new CustomEvent('close-dialog', {
            bubbles: true,
            composed: true,
            detail: 'device_manager',
          }),
        );

        const processDeviceOfflineStatus = async status => {
          this._remoteRetrievalProgress = (status.currentNumSamples / status.totalNumSamples) * 100;
          this._offlineLoggingStatus = status;
          if (status.offlineStatus === 'completed') {
            if (this._offlineStatusEventBinding) this._offlineStatusEventBinding.off();
          }
        };

        this._offlineLoggingStatus = device.offlineLoggingStatus;
        if (this._offlineStatusEventBinding) this._offlineStatusEventBinding.off();
        this._offlineStatusEventBinding = this.eventBinder.on(
          device,
          'offline-logging-status-changed',
          processDeviceOfflineStatus,
        );

        processDeviceOfflineStatus(this._offlineLoggingStatus);
        this._activeDataLoggingDevice = device;
        this._showDataLoggingStatusDialog = true;
      }
    }
  }

  /**
   * Called from the Data Logging Status dialog -- will confirm with user, and
   * delete logged data on the current device.
   */
  async _handleDiscardLoggedData() {
    this.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'message_box',
          params: {
            title: getText('Clear Logged Data'),
            choiceRequired: true,
            content: getText('This will clear logged data from the device. This cannot be undone.'),
            actions: [
              {
                id: 'cancel',
                message: getText('Cancel'),
                variant: 'text',
                onClick: async () => {
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                },
              },
              {
                id: 'confirm',
                message: getText('Clear Logged Data'),
                variant: 'danger',
                onClick: async () => {
                  const { dataCollection } = this.$services;
                  const device = this._activeDataLoggingDevice;
                  try {
                    await dataCollection.stopMeasurements();
                    await dataCollection.deleteLoggedData(device.id);
                  } catch (error) {
                    console.warn(error);
                  }

                  if (this._offlineStatusEventBinding) this._offlineStatusEventBinding.off();
                  this._showDataLoggingStatusDialog = false;
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                },
              },
            ],
          },
        },
      }),
    );
  }

  async _handleOpenFile() {
    const { appManifest, dataWorld, fileIO, popoverManager } = this.$services;

    try {
      const { cancelled } = await this.trySave().catch(rejection => rejection);
      if (cancelled) {
        return;
      }
      const fileData = await fileIO.showOpenDialog([
        {
          extensions: getOpenableTypes(),
          name: appManifest.getAppName(),
        },
        {
          extensions: ['csv'],
          name: getText('Comma Separated Values'),
        },
      ]);

      if (!fileData) return; // cancelled

      const fileOpened = await dataWorld.importData({ fileData });
      if (fileOpened) {
        popoverManager.closeAll();
        this._gaWelcomeEl.close();
      } else {
        this.$services.toast.makeToast(getText('Open failed'));
      }
    } catch (err) {
      console.error(err);
    }
  }

  async _handleStopDataLogging() {
    const { dataCollection } = this.$services;
    await dataCollection.stopMeasurements();
    if (this._offlineStatusEventBinding) this._offlineStatusEventBinding.off();
    this._showDataLoggingStatusDialog = false;
  }

  // this is a non-ideal method of detecting any FFT or Histogram graph that I feel is flimsy yet workable.
  // preferably we would have a method of detection which I think can be achieved through the state manager
  // but I feel this is a workable solution until the graph state gets more into mobx -- Matt
  //
  // Note: this is used to inform the toolbar that an fft or histogram auxiliary graph is present. The toolbar
  // in turn will hide the playback button. There is a feature request to allow playback of ffts and/or (TBD) histograms
  // so when we get there, we can remove these (JK)
  _incrementAuxGraphCount() {
    this.auxGraphCount++;
  }

  async _onOffline() {
    const { gaPeerConnection } = this.$services;

    gaNetworkStore.updateOnline();

    await gaPeerConnection.dispose();
    if (this.isDataShareHost) {
      this.showDataShareHost = false;
      this.showDataShareStatus = false;
    }
  }

  _resetDataShareHost() {
    this.showDataShareHost = false;
  }

  _resetDataShareStatus() {
    this.showDataShareStatus = false;
  }

  _resetSampleExperiments() {
    this.showSampleExperiments = false;
    if (!this.shadowRoot.querySelector('ga-sample-data').hasAttribute('downloaded'))
      this._showWelcomeDialog();
  }

  _setupAutoLayout() {
    const eventBinder = new EventBinder();

    const { dataWorld, dataCollection, sensorWorld } = this.$services;

    const updateGraphsAndLayout = (graphsLayout, appAutoLayout) => {
      store.dispatch(updateGraphsAutoLayout(graphsLayout));
      vstLayoutStore.updateLayout(appAutoLayout);
    };

    eventBinder.on(dataWorld, 'before-session-started', async config => {
      this.isImportedSession = config.imported;

      // For fresh documents setup the layout config before we start tracking document age
      if (!this.isImportedSession) {
        const { appAutoLayout, graphsLayout } = this._generateAutoLayout();
        updateGraphsAndLayout(graphsLayout, appAutoLayout);

        const layoutData = {
          graph_1: vstLayoutStore.graph_1,
          graph_2: vstLayoutStore.graph_2,
          graph_3: vstLayoutStore.graph_3,
          table: vstLayoutStore.table,
          meter: vstLayoutStore.meter,
          video: vstLayoutStore.video,
          notes: vstLayoutStore.notes,
          configurator: vstLayoutStore.configurator,
        };

        await dataWorld.setLayoutProperties(layoutData);
      }
    });

    eventBinder.on(dataWorld, 'session-started', async config => {
      this._stopLayoutAutorunFirstRun = true; // we don't want to do anything on on the first autorun
      this.isImportedSession = config.imported;
      delete this._lastSync;
      this._updateExperimentTitle();
      if (this.$services.udm.isBlock) {
        const { blockInfo } = this.$services.udm;
        if (blockInfo) vstLayoutStore.updateLayout(blockInfo.layout);
        else {
          const { blockType, blockId } = this.$services.urlHandler;
          await this._startBlockSession(blockType, blockId);
        }
      } else if (this.isImportedSession) {
        // Fetch saved layout information from the file and apply it to the
        // layout store. It is either already in the file, or, for legacy documents,
        // the backend will iterate all its views for their visibility state.
        const layoutFlags = await dataWorld.getLayoutProperties();
        if (layoutFlags) vstLayoutStore.updateLayout(layoutFlags);
      } else {
        // For fresh documents we have to reconfigure the layout here, as when the UDM part was setup, the basecolumn
        // wasn't yet known, so the graph doesn't have the axes setup (NB: this doesn't do any UDM operations, it just
        // ensures that the state of the graphs is in sync with the rest of DataWorld).
        const { appAutoLayout, graphsLayout } = this._generateAutoLayout();
        updateGraphsAndLayout(graphsLayout, appAutoLayout);
      }

      // Set up an autorun that responds to changes to the layout store and
      // saves the values to UDM.
      this._stopLayoutAutorun = autorun(() => {
        // The data to pass to setLayoutProperties -- we must access this even on the first (setup) run
        // otherwise subsequent runs will not trigger
        const layoutData = {
          graph_1: vstLayoutStore.graph_1,
          graph_2: vstLayoutStore.graph_2,
          graph_3: vstLayoutStore.graph_3,
          table: vstLayoutStore.table,
          meter: vstLayoutStore.meter,
          video: vstLayoutStore.video,
          notes: vstLayoutStore.notes,
          configurator: vstLayoutStore.configurator,
        };

        // If this is the first run, we don't want to change the layout props, as nothing has
        // changed, and we don't want the experiment state dirtied (age bumped).
        if (this._stopLayoutAutorunFirstRun) {
          this._stopLayoutAutorunFirstRun = false;
          return;
        }

        dataWorld.setLayoutProperties(layoutData);
      });

      await this.$services.gaReplayService.resetService(this.isImportedSession);
      if (
        watchUDM &&
        !udmWatcherSetupCalled &&
        !this.$services.urlHandler.isReadOnly &&
        !this.$services.urlHandler.isDemoMode
      ) {
        udmWatcherSetupCalled = true;
        const { setupUdmWatcher } = await import('@common/utils/setupUdmWatcher.js');
        setupUdmWatcher.call(this, ...watchUDM);
        watchUDM = false;
      }
      if (
        isPrivilegedIframe() &&
        !this._sensorDialogShownOnLoad &&
        !this._isGroupCollectionComplete
      ) {
        this.showSensorDialog(true);
        this._sensorDialogShownOnLoad = true;
      }
    });

    eventBinder.on(dataCollection, 'collection-mode-changed', ({ to, from }) => {
      const ignoreTransition = to.includes('events') && from.includes('events'); // ignore changes between selected-events and events-with-entry modes

      if (!this.isImportedSession && !ignoreTransition) {
        const autoLayout = computeGraphsAutoLayout(
          dataWorld,
          sensorWorld,
          dataCollection.getCollectionParams(),
        );

        updateGraphsAndLayout(autoLayout.graphAutoLayouts, autoLayout.appAutoLayout);
      }
    });

    // Apply autolayout logic when switching changing photogate-timing collection params
    eventBinder.on(
      dataCollection,
      'collection-params-changed',
      ({ mode: newMode }, { mode: oldMode }) => {
        const isPhotogateTimingChange =
          newMode === 'photogate-timing' && oldMode === 'photogate-timing';

        if (!this.isImportedSession && isPhotogateTimingChange) {
          // TODO: we may want to reconsider if we want to ignore on restored session
          const autoLayout = computeGraphsAutoLayout(
            dataWorld,
            sensorWorld,
            dataCollection.getCollectionParams(),
          );

          updateGraphsAndLayout(autoLayout.graphAutoLayouts, autoLayout.appAutoLayout);
        }
      },
    );

    // TODO: This isn't actually part of autolayout. We need to move this to another connected component to handle
    // updating the base ranges when a user updates the duration. Again, this is a user action, not an auto action
    eventBinder.on(dataCollection, 'collection-params-changed', () => {
      if (!this.isImportedSession) {
        // TODO: we may want to reconsider if we want to ignore on restored session
        const autoLayout = computeGraphsAutoLayout(
          dataWorld,
          sensorWorld,
          dataCollection.getCollectionParams(),
        );
        const graphsBaseRangeMax = autoLayout.graphAutoLayouts.map(
          layout => layout.options.baseRange.max,
        );
        store.dispatch(autoUpdateBaseRange(graphsBaseRangeMax));
      }
    });

    ['sensor-ready', 'sensor-removed'].forEach(eventName => {
      eventBinder.on(sensorWorld, eventName, async () => {
        if (
          this.isSessionEmpty &&
          dataWorld.sessionType === 'DataCollection' &&
          !dataCollection.isRemoteLoggingEnabled
        ) {
          if (dataCollection.timeUnits !== 's') {
            dataCollection.timeUnits = 's'; // reset time units
          }
          // Add a bit more time for new calc-columns to percolate from the
          // back end (MEG-2824). Terrible hack, and we really need to sort out
          // the VPG calc-column stuff.
          //
          // TODO (jkelly): this previous fix ^^^ messes with manual session,
          // e.g. if you connect a device, then switch to Manual Session, the
          // setTimeout here will fire AFTER the manual auto layout has run, and
          // will hide the table and set up wonky graph ranges (this only became
          // apparent after I fixed some thrown errors in meter refactor).
          // I created MEG-4872 to address this and other related issues.
          setTimeout(() => {
            // this is the quick and dirty 1pt fix
            // TODO: fix the sensor-removed event to fire actually after the sensor is removed in code.
            // this will likely be fixed(or should be considered) when we move SensorWorld to a store backed service
            const params = dataCollection.getCollectionParams();
            if (!this.isImportedSession && params.mode !== 'manual') {
              const autoLayout = computeGraphsAutoLayout(dataWorld, sensorWorld, params);
              updateGraphsAndLayout(autoLayout.graphAutoLayouts, autoLayout.appAutoLayout);
            }
          }, 250);
        }
      });
    });

    // All Data Sharing updates are driven by this event
    eventBinder.on(dataWorld, 'data-share-view-changed', ({ graphLayouts, appLayout }) => {
      if (this.dataShareUpdates.graphs && this.dataShareUpdates.layout) {
        updateGraphsAndLayout(graphLayouts, appLayout);
      } else if (this.dataShareUpdates.graphs) {
        store.dispatch(updateGraphsAutoLayout(graphLayouts));
      } else if (this.dataShareUpdates.layout) {
        vstLayoutStore.updateLayout(appLayout);
      }
    });
  }

  _setupCustomCurveFits() {
    const eventBinder = new EventBinder();

    const { dataWorld, dataAnalysis } = this.$services;

    eventBinder.on(dataWorld, 'session-ended', async () => {
      await dataAnalysis.curveFitStore.removeAllFits();
    });
  }

  _setupServiceWorkerSaveListeners() {
    // check if have already set these up, or if we are not on the web and bail quick
    if (this.autoSaving || PLATFORM_ID !== 'web') return;
    this.autoSaving = true;
    this.savePending = true;
    this.$services.urlHandler.setupMessageListener();
    this.$services.urlHandler.on('FILE_CACHE_UPDATED', async () => {
      await this.appInitialized;
      this.__notifiedNewFile = true;
      this.$services.toast.hideToast();
      this.$services.toast.makeToast(getText('Local copy out of sync. Refreshing...'));
      await nextTick(1500); // dataworld.import has a throttling mechanism that won't allow it to be called too quickly
      this.$services.urlHandler.checkOpeningUrl(this.$services.gaAppService.validateJWT, {
        requireKey: false,
      });
    });
    this.$services.urlHandler.on('FILE_CACHE_USED', async () => {
      await this.appInitialized;
      if (this.__notifiedNewFile) return;
      if (!this.persistentHostIframe)
        this.$services.toast.makeToast(
          getText('Using locally cached version of this experiment.'),
          {
            duration: 3000,
          },
        );
    });
    this.$services.urlHandler.on('FILE_SAVE_PENDING', async () => {
      this.savePending = true;
    });
    this.$services.urlHandler.on('FILE_SAVE_SUCCESS', async () => {
      this.savePending = false;
    });
    this.$services.urlHandler.on('FILE_SAVE_ERROR', async ({ error }) => {
      this.savePending = false;
      this.saveError = error;
    });
  }

  _setupTriggering(prestoreReached = false) {
    let waitMessage = '';
    let triggerMeter = null;

    if (this.triggeringParams.prestore && !prestoreReached) {
      const singularOrPlural =
        this.triggeringParams.prestore > 1 ? getText('points') : getText('point');
      waitMessage = sprintf(
        getText('Collecting %d %s'),
        this.triggeringParams.prestore,
        singularOrPlural,
      );
    } else {
      const { dataWorld, sensorWorld } = this.$services;

      const getTriggeringSensor = () => {
        let ret;
        sensorWorld.sensors.forEach(sensor => {
          if (sensor.id === this.triggeringParams.sensor) {
            ret = sensor;
          }
        });
        return ret;
      };

      const triggeringSensor = getTriggeringSensor();

      if (triggeringSensor) {
        const triggeringSlope =
          this.triggeringParams.slope === 1 ? getText('increase') : getText('decrease');
        const columnGroup = dataWorld
          .getColumnGroups()
          .find(group => group.sensorId === triggeringSensor.id);
        const name = columnGroup.name ? columnGroup.name : 'unknown';

        triggerMeter = dataWorld.meters.find(meter => meter.sensorInfo.id === triggeringSensor.id);

        waitMessage = sprintf(
          getText('Waiting for %s to %s across %s %s'),
          name,
          triggeringSlope,
          this.triggeringParams.threshold,
          triggeringSensor.units,
        );
      }
    }
    this.showWait(true, waitMessage, triggerMeter);
  }

  async _setupUrlHandler(urlHandler) {
    urlHandler.on('url-changed', key => {
      vstAuthStore.authorize(key);
    });
    // event listener will not be set up before event is emitted if launched from auth page
    if (urlHandler.authKey) {
      vstAuthStore.authorize(this.$services.urlHandler.authKey);
    }

    if (urlHandler.checkOpeningUrl) {
      urlHandler.on(
        'startup-url-error',
        async ({ status = '404', fileType = 'file', location = 'storage', error, skipToast }) => {
          await this.appInitialized;
          if (!skipToast) {
            this.$services.toast.makeToast(
              `${status}: ${error ?? `Failed to fetch ${fileType} from location: ${location}`}`,
              { duration: 5000 },
            );
          }
          if (fileType === 'experiment' && !serviceWorkerInitializer._templateFile) {
            if (urlHandler.isContentCreatorSession) watchUDM = [urlHandler.experimentId, true];
            await this.$services.dataWorld.startNewSession(
              urlHandler.manual ? 'ManualEntry' : 'DataCollection',
            );

            serviceWorkerInitializer.notifyHostInitialized(this.$services);
          } else this._showWelcomeDialog();
        },
      );

      urlHandler.on('startup-url-experiment', async ({ fileData, exludeAnalysis }) => {
        this._urlFile = true;
        const subset = exludeAnalysis ? { graphs: {} } : undefined;
        this._isGroupCollectionComplete = urlHandler.groupComplete;
        if (urlHandler.isStudentSession) watchUDM = [urlHandler.sessionId];
        else if (urlHandler.isContentCreatorSession) watchUDM = [urlHandler.experimentId, true];
        await this.appInitialized;
        await this._startSession(fileData, { subset });
      });

      if (urlHandler.isBlock) this._setupServiceWorkerSaveListeners();
      await urlHandler.checkOpeningUrl(this.$services.gaAppService.validateJWT, {
        requireKey: false,
      });

      if (window.__isSessionClient) {
        await syncServices(this.$services);
      }
    }
  }

  async _showChromeOnMacWinDialog() {
    const { _splashScreenEl } = this;
    let simpleOSName;
    if (platform.os.family.includes('OS X')) {
      simpleOSName = 'macOS';
    } else if (platform.os.family.includes('Windows')) {
      simpleOSName = 'Windows';
    }

    _splashScreenEl.errorMsg = `You are running Graphical Analysis as a Chrome Packaged App. Please download the native app for ${simpleOSName}.`;
    await this.updateComplete;
    const wrapperEl = _splashScreenEl.shadowRoot.querySelector('.wrapper');
    const downloadBtnEl = document.createElement('button');
    downloadBtnEl.innerText = 'Download';
    downloadBtnEl.classList.add('download-btn');
    downloadBtnEl.addEventListener('click', () => {
      window.open('https://www.vernier.com/products/software/graphical-analysis/', '_blank');
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('close-app', { bubbles: true, composed: true }));
      });
    });
    wrapperEl.appendChild(downloadBtnEl);

    // add a way to move forward if you're a dev or QA
    keyboardEvents.on('dev-override', () => {
      keyboardEvents.off('dev-override');
      downloadBtnEl.remove();
      _splashScreenEl.errorMsg = '';
      this._splashScreenEl.showProgress = true;
    });
  }

  _showDialog(message) {
    if (!this.messageBoxInView) {
      this.messageBoxInView = true;

      this.dispatchEvent(
        new CustomEvent('open-dialog', {
          bubbles: true,
          composed: true,
          detail: {
            dialog: 'message_box',
            params: {
              title: getText('Device Error'),
              content: message,
              actions: [
                {
                  id: 'okay',
                  message: getText('OK'),
                  onClick: async () => {
                    this.dispatchEvent(closeCommonDialogEvent('message_box'));
                  },
                },
              ],
            },
            onClose: () => {
              this.messageBoxInView = false;
            },
          },
        }),
      );
    }
  }

  async _showWelcomeDialog() {
    // no welcome dialog in the iframe
    if (isPrivilegedIframe() && PLATFORM_VARIANT !== 'abitti') return;
    const { analytics } = this.$services;
    // TODO: use ui-dialog component
    await import('./ga-welcome/ga-welcome.js');
    analytics.trackEvent('dialog_open', { dialog_name: 'Welcome' });
    this._gaWelcomeEl.open(this);
  }

  async _startBlockSession(blockType, blockId) {
    if (this._blockSessionStarted) return;
    this._blockSessionStarted = true;
    const { dataWorld, udm } = this.$services;
    await dataWorld.blockSynced;
    await dataWorld.startNewSession(
      this.$services.urlHandler.manual ? 'ManualEntry' : undefined,
      dataWorld.sessionConfig,
      undefined,
    );
    if (!serviceWorkerInitializer.authoringMode) {
      // do not freeze columns in authoring mode
      await Promise.all(
        dataWorld.getAllColumns().map(column => dataWorld.freezeColumn(column.id, true)),
      );
    } else {
      this._authoringMode = true;
    }
    const { frameFeatures } = await import('./services/FrameFeatures.js');
    this._frameFeatures = frameFeatures;
    this._frameFeatures.initializeBlockTypeFeatures(blockType);
    ['feature-disabled', 'feature-enabled'].forEach(eventType =>
      this._frameFeatures.addEventListener(eventType, () => this.requestUpdate()),
    );
    const { buildBlockLayout } = await import('@utils/buildBlockLayout.js');
    const blockDefaults = {
      graphIds: [],
      blockId,
      showsTable: blockType === 'table',
      meterIds: blockType === 'meter' ? [dataWorld.meters[0]?.id] : undefined,
      meterSize: blockType === 'meter' ? 'small' : undefined,
      type: blockType,
      layout: buildBlockLayout(blockType),
    };
    const block = await udm.setBlockId(dataWorld.experimentId, blockId, blockDefaults);
    if (block.rehydrated) {
      store.dispatch(updateIsSessionEmpty(false));
    }
    vstLayoutStore.updateLayout(block.layout);
    this._setupServiceWorkerSaveListeners();
  }

  async _startSession(fileData = {}, options = {}) {
    const { dataWorld, popoverManager } = this.$services;
    if (fileData && fileData.file) {
      try {
        popoverManager.closeAll();
        const { cancelled } = await this.trySave().catch(rejection => rejection);
        if (cancelled) {
          return;
        }
        if (!window.__isSessionClient) {
          await dataWorld.importDataThrottled({ fileData, subset: options.subset });
          serviceWorkerInitializer.notifyHostInitialized(this.$services);
        }
      } catch (err) {
        console.warn(`Import failed: ${err}`);
      }
    }
  }

  _updateExperimentTitle() {
    const { dataWorld } = this.$services;
    this.experimentName = dataWorld.sessionName;
  }
  // #endregion

  // #region public methods
  async addDataMark() {
    const { dataWorld } = this.$services;
    const graphIds = dataWorld.getGraphUdmIds();
    const leftColumnId = dataWorld.currentDataSet.columnIds.find(
      columnId => !dataWorld.getColumnById(columnId).prefersBase,
    );

    if (leftColumnId) {
      const rowIndex = dataWorld.getColumnById(leftColumnId).values.length - 1;
      dataWorld.addDataMark(rowIndex, dataWorld.currentDataSet.id, graphIds);
    }
  }

  addPeerConnectionListeners() {
    const { gaPeerConnection, toast } = this.$services;
    if (gaPeerConnection.hasListeners) return;
    gaPeerConnection.addEventListener('rtc-datashare-did-end', () => {
      toast.makeToast(getText('Session ended by Host'), { duration: 5000 });
      gaPeerStore.resetPeerStatus();
    });
    gaPeerConnection.addEventListener(
      'rtc-datashare-confirm-follow-host',
      this.onFollowHostNewExperimentConfimration.bind(this),
    );
    gaPeerConnection.hasListeners = true;
  }

  closeDataCollectionSettings() {
    this.showDataCollectionSettings = false;
  }

  enableDataMarksForMeltStation(sensor) {
    const { dataCollection } = this.$services;
    if (
      vstAuthStore.authorized &&
      (sensor.autoId === BTA_MELT_AUTO_ID || sensor.autoId === GDX_MELT_AUTO_ID)
    ) {
      dataCollection.setDataMarksEnabled(true);
    }
  }

  /**
   * Initializes application business logic. Called at `firstUpdated()`
   * @override
   */
  async initApp() {
    super.initApp();

    if (
      isPrivilegedIframe() &&
      !window.__isSessionClient && // this just allows us to skip the following await on the clients, since its not necessary
      (await serviceWorkerInitializer.updateComplete)
    ) {
      // the host won't be initialized by now, so the clients won't load, allowing the host to change service workers before starting their load
      serviceWorkerInitializer.skipWaitingAndClaim();
    }

    const {
      dataWorld,
      dataCollection,
      deviceManager,
      sensorMap,
      fileIO,
      popoverManager,
      accessibility,
      analytics,
      startupFileHandler,
      sensorWorld,
    } = this.$services;

    if (isFeatureFlagEnabled('ff-posthog-analytics')) {
      initAnalytics(POSTHOG_PROJECT_KEY);

      autorun(() => {
        if (this.license) {
          collectLicenseDataForAnalytics(this.license.name, this.license.key);
        }
      });

      // (@ejdeposit) Android and ios can get os version from window.device
      capturePlatformAnalyticsData({
        osFamily: platform.os.family,
        osVersion:
          platform.name === 'Electron'
            ? process.getSystemVersion()
            : window.device?.version ?? platform.os.version,
        platformId: PLATFORM_ID,
        appVersion: this.$services.appManifest.getAppVersion(),
      });

      deviceManager.on('device-connected', captureDeviceConnectedAnalyticsData);
      sensorWorld.on('sensor-added', captureSensorAddedAnalyticsData);
      dataWorld.on('session-started', captureSessionAnalyticsData);
    }

    this.registerDialogTemplate([
      'vst-core-column-options',
      'vst-core-device-manager',
      'vst-ui-message-box',
      'vst-ui-custom-expression-info',
      'vst-ui-presentation',
      'vst-core-export',
      'vst-ui-prompt',
      'vst-core-device-info',
      'vst-core-fit-options',
      'vst-core-meter-select',
    ]);

    this._setupAutoLayout();
    this._setupCustomCurveFits();

    // iOS hack to make sure DataCollection is up and ready to talk after a force quit
    await nextTick(10);

    this.eventBinder.bindListeners({
      source: dataCollection,
      target: this,
      eventMap: {
        'device-error': 'onDataCollectionDeviceError',
        'ble-communication-error': 'onDataCollectionBleCommunicationError',
        'start-measurements-failed': 'onDataCollectionStartMeasurementsFailed',
        'collection-params-changed': 'onDataCollectionCollectionParamsChanged',
        'collection-mode-changed': 'onDataCollectionCollectionModeChanged',
        'device-sensor-enum-completed': '_handleDeviceSensorEnumCompleted',
      },
    });

    await dataCollection.init({
      sensorMap: sensorMap.getSensorMap(),
      hasFirmwareUpdater: true,
    });

    window.addEventListener('online', () => gaNetworkStore.updateOnline());
    window.addEventListener('offline', this._onOffline.bind(this));

    const graphIds = ['graph_1', 'graph_2', 'graph_3'];

    store.dispatch(resetSession(graphIds));
    // skip the session start if we're opening a file since it is wasted effort (and janky)
    if (
      !this._urlFile &&
      !this.$services.urlHandler.sessionId &&
      !this.$services.urlHandler.blockType &&
      !serviceWorkerInitializer._templateFile
    ) {
      await dataWorld.startNewSession(
        this.$services.urlHandler.manual ? 'ManualEntry' : 'DataCollection',
      );
    } else if (this.$services.urlHandler.blockType) {
      // if we already have a blocktype, we can trigger the block session here
      // otherwise we wait until the session-started event
      await this._startBlockSession(
        this.$services.urlHandler.blockType,
        this.$services.urlHandler.blockId,
      );
    }

    this._updateExperimentTitle();

    fileIO.onSave(() => {
      this._updateExperimentTitle();
    });

    const wrapper = this.shadowRoot.querySelector('#popover_wrapper');
    popoverManager.attachWrapper(wrapper);
    this.addEventListener('popover-creation', e => store.dispatch(addPopover(e.detail.id)));
    this.addEventListener('popover-removal', e => store.dispatch(removePopover(e.detail.id)));

    const toastWrapper = this.shadowRoot.querySelector('#toast_wrapper');
    await this.$services.toast.attachWrapper(toastWrapper);

    // Google Analytics listeners
    this.addEventListener('open-dialog', e =>
      analytics.trackEvent('dialog_open', { dialog_name: e.detail.dialog }),
    );
    this.addEventListener('dialog-close', e =>
      analytics.trackEvent('dialog_close', { dialog_name: e.detail.dialog }),
    );
    this.addEventListener('popover-creation', e =>
      analytics.trackEvent('popover_created', { dialog_name: e.detail.title }),
    );
    this.addEventListener('popover-removal', e =>
      analytics.trackEvent('popover_removed', { dialog_name: e.detail.title }),
    );
    this.addEventListener('open-file', () =>
      analytics.trackEvent('open_file', { dialog_name: this.getAttribute('id') }),
    );
    this.addEventListener('save-file', () =>
      analytics.trackEvent('file_saved', { dialog_name: this.getAttribute('id') }),
    );
    this.addEventListener('save-as', () =>
      analytics.trackEvent('file_saved', { dialog_name: this.getAttribute('id') }),
    );

    this.eventBinder.bindListeners({
      source: dataWorld,
      target: this,
      eventMap: {
        'collection-preparing': 'onDataWorldCollectionPreparing',
        'collection-started': 'onDataWorldCollectionStarted',
        'collection-stopped': 'onDataWorldCollectionStopped',
        'collection-prestore-reached': 'onDataWorldCollectionPrestoreReached',
        'collection-threshold-reached': 'onDataWorldCollectionThresholdReached',
        'is-collecting-changed': 'onDataWorldIsCollectingChanged',
        'session-remote-id-changed': 'onDataWorldSessionRemoteIdChanged',
        'session-ended': 'onSessionEnded',
        'before-session-ended': 'onBeforeSessionEnded',
      },
    });

    this.eventBinder.bindListeners({
      source: deviceManager,
      target: this,
      eventMap: {
        'device-list-changed': '_checkIfDataLoggingSupportChanged',
      },
    });

    this.eventBinder.bindListeners({
      source: sensorWorld,
      target: this,
      eventMap: {
        'sensor-added': 'enableDataMarksForMeltStation',
      },
    });

    dataWorld.on('column-values-changed', () => {
      store.dispatch(updateIsSessionEmpty(false));
    });

    dataWorld.on('session-connection-error', error => {
      console.error(error);
      this.$services.toast.makeToast(getText('Connection Lost'), { duration: 5000 });
    });
    dataWorld.on('prompt-collecting-confirm-new-session', async ({ appName, resolution }) => {
      const { promptConfirmNewSession } = await import('@utils/promptConfirmNewSession.js');
      resolution(promptConfirmNewSession.call(this, appName));
    });

    const mainContentEl = this.shadowRoot.querySelector('#main_content');
    this.graphModeTransition = createGraphModeTransition(this, mainContentEl, this.$services.ally);

    accessibility.on('accessibility-scale-changed', newScale => {
      store.dispatch(updateAccessibilityScale(newScale));
    });

    // always check if we're being launched via a file handler
    const { startFile, startFileData } = startupFileHandler;

    if (startFileData || serviceWorkerInitializer._templateFile) {
      await this._startSession(
        startFileData ?? { file: serviceWorkerInitializer._templateFile, filepath: '/' },
      );
    }

    if (PLATFORM_ID !== 'web') {
      startupFileHandler.on('new-start-file', this._startSession.bind(this));
    }

    this._curMode = dataCollection.getCollectionParams().mode; // set the mode internally for knowing what we're transitioning from

    const abitti = PLATFORM_VARIANT === 'abitti';

    if (abitti) {
      window.addEventListener('message', async event => {
        if (event.data.length === 0) return;

        const message = JSON.parse(event.data);
        const { filename } = message;

        const { openFile } = await import('@services/adapters/file-io/abitti.js');

        if (!filename || !openFile(filename)) {
          if (!deviceManager.hasConnectedDevices) {
            this._showWelcomeDialog();
          }
        }

        this._splashScreenEl.hide();
      });

      window.parent.postMessage('init');
    } else {
      if (!deviceManager.hasConnectedDevices && !(startFile || this._urlFile)) {
        this._showWelcomeDialog();
      }
      this._splashScreenEl.hide();
    }
  }

  onBeforeSessionEnded() {
    if (!this.isDataShareHost) return;
    const { gaPeerConnection } = this.$services;
    if (gaPeerConnection.dataShareHost)
      gaPeerConnection.dataShareHost.sendMessage({ type: 'host-new-session' });
  }

  async onCreateExperiment() {
    const { dataWorld, popoverManager } = this.$services;
    popoverManager.closeAll();

    try {
      const saveResult = await this.trySave().catch(rejection => rejection);
      if (saveResult?.cancelled) {
        popoverManager.closeAll();
        return;
      }
      await dataWorld.startNewSession('DataCollection');
      this._showWelcomeDialog();
    } catch (err) {
      console.error(err);
    }
  }

  async onDataCollectionBleCommunicationError(/* err */) {
    const { toast } = this.$services;
    // TODO: Can we make this message more useful to the user? Which device dropped, etc.
    toast.makeToast(getText('The Bluetooth connection has been lost'), { duration: 5000 });
  }

  onDataCollectionCollectionModeChanged(modeTransition) {
    const { dataCollection } = this.$services;

    const mode = modeTransition.to;
    const params = dataCollection.getCollectionParams();

    if (mode === 'time-based') {
      if (params) {
        if (params.delta < 0.001 && (params.duration === 0 || params.duration > 0.05)) {
          this.toggleUIUpdates({
            graphs: false,
            table: false,
            autoSave: false,
          });
          this.isUIDisabled = true;
        } else {
          this.toggleUIUpdates(); // reset to defaults
          this.isUIDisabled = false;
        }

        if (params.triggering) {
          this.triggeringParams = params.triggering;
          this.triggeringPrestore = params.triggering.prestore;
          this.triggeringSensor = params.triggering.sensor;

          this.waitingForTrigger = params.triggering.enabled && !params.remoteLogging;
        }
      }
    } else if (mode.match('events') || mode === 'drop-counting' || mode === 'photogate-timing') {
      this.toggleUIUpdates(); // reset to defaults
    }

    this._curMode = mode; // keep curMode in sync with DC mode
  }

  onDataCollectionCollectionParamsChanged(collectionParams) {
    const { params } = collectionParams;
    if (params && this._curMode === 'time-based') {
      if (params.delta < 0.001 && (params.duration === 0 || params.duration > 0.05)) {
        this.toggleUIUpdates({
          graphs: false,
          table: false,
          autoSave: false,
        });
        this.isUIDisabled = true;
      } else {
        this.toggleUIUpdates(); // reset to defaults
        this.isUIDisabled = false;
      }

      if (params.triggering) {
        this.triggeringParams = params.triggering;
        this.triggeringPrestore = params.triggering.prestore;
        this.triggeringSensor = params.triggering.sensor;
        this.waitingForTrigger = params.triggering.enabled && !params.remoteLogging;
      }
    }
  }

  onDataCollectionDeviceError(err) {
    const { dataWorld, dataCollection } = this.$services;

    this.showWait(false);

    if (typeof err.errorCode === 'number') {
      if (err.errorCode === dataCollection.DeviceError.COMMUNICATION_FAILED) {
        this._showDialog(
          getText('A communication error has occurred. Start a new experiment to continue.'),
        );
      } else if (dataCollection.mode === 'time-based' && !this.waitingForTrigger) {
        dataWorld.stopCollection();
        if (err.errorCode === dataCollection.DeviceError.DROPPED_MEASUREMENT) {
          this._showDialog(
            getText(
              'Dropped Measurement(s). Please start a new experiment and use a slower data collection rate.',
            ),
          );
        } else {
          this._showDialog(
            getText('A communications error caused data collection to stop. Please try again.'),
          );
        }
      }
    }
  }

  onDataCollectionStartMeasurementsFailed() {
    this.showWait(false);
  }

  onDataWorldCollectionPreparing() {
    const { dataWorld, dataCollection } = this.$services;

    this.isCollecting = true;

    if (this.isUIDisabled) {
      this.showWait(true);
    }

    if (dataWorld.isTriggeringEnabled && !dataCollection.isRemoteLoggingEnabled) {
      this._setupTriggering();
    }
  }

  onDataWorldCollectionPrestoreReached() {
    this.showWait(false);
    this._setupTriggering(true);
  }

  onDataWorldCollectionStarted() {
    if (this.$services.dataCollection.isRemoteLoggingEnabled) {
      this.$services.dataCollection.detachRemoteDevices();
    }
  }

  onDataWorldCollectionStopped() {
    this.isCollecting = false;
    this.dispatchEvent(new CustomEvent('collection-stopped'));

    this.showWait(false);

    if (this.isUIDisabled) {
      this.toggleUIUpdates();
    }
  }

  onDataWorldCollectionThresholdReached() {
    this.showWait(false);
    this.waitingForTrigger = false;

    if (this.isUIDisabled) {
      this.showWait(true);
    }
  }

  onDataWorldIsCollectingChanged(isCollecting) {
    if (isCollecting) {
      // eslint-disable-next-line wc/no-self-class
      this.classList.add('is-collecting');
    } else {
      // eslint-disable-next-line wc/no-self-class
      this.classList.remove('is-collecting');
    }
  }

  onDataWorldSessionRemoteIdChanged() {
    if (this.isRemoteIdDialogOpen || gaPeerStore.id) {
      // guard against this call being made twice, causing multiple dialogs.
      return;
    }

    this.isRemoteIdDialogOpen = true;
    const { dataWorld } = this.$services;

    const sessionType = 'DataShare';
    let sessionConfig = {};

    if (dataWorld.getSession() && dataWorld.sessionHostAddress) {
      sessionConfig = {
        sourceURI: dataWorld.sessionHostAddress,
        sourceName: dataWorld.sessionSourceName,
      };
    }

    this.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'message_box',
          params: {
            title: getText('Start New Experiment?'),
            choiceRequired: true,
            content: getText(
              'Your Data Sharing Host is starting a new experiment. You may continue with your experiment or join into the new experiment.',
            ),
            actions: [
              {
                id: 'offline',
                message: getText('Disconnect'),
                variant: 'text',
                onClick: async () => {
                  gaPeerStore.resetPeerStatus();
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                },
              },
              {
                id: 'new_session',
                message: getText('Join New Experiment'),
                onClick: async () => {
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                  const { cancelled } = await this.trySave().catch(rejection => rejection);
                  if (cancelled) {
                    await this.onDataWorldSessionRemoteIdChanged();
                  }
                  await dataWorld.startNewSession(sessionType, sessionConfig);
                },
              },
            ],
          },
          onClose: () => {
            this.isRemoteIdDialogOpen = false;
          },
        },
      }),
    );
  }

  onFollowHostNewExperimentConfimration() {
    const { gaPeerConnection } = this.$services;

    this.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'message_box',
          params: {
            title: getText('Start New Experiment?'),
            choiceRequired: true,
            content: getText(
              `Your Data Sharing Host is starting a new experiment. You may continue with your experiment or join into the new experiment.`,
            ),
            actions: [
              {
                id: 'disconnect',
                message: getText('Disconnect'),
                variant: 'text',
                onClick: async () => {
                  gaPeerStore.resetPeerStatus();
                  await gaPeerConnection.dispose();
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                },
              },
              {
                id: 'join',
                message: getText('Join New Experiment'),
                onClick: async () => {
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                  // Set this latch-flag to preserve the underlying data sharing connection.
                  // This flag will be cleared after it passes the test point.
                  this._continueDSSession = true;
                  await this.onStartDataShareClient({ detail: { serverid: gaPeerStore.id } });
                },
              },
            ],
          },
          onClose: () => {
            this.isRemoteIdDialogOpen = false;
          },
        },
      }),
    );
  }

  async onOpenSampleFile({ detail: { file, url, experiment } }) {
    const { dataWorld, popoverManager } = this.$services;
    let _file = file;
    // in cordova - files create wrong, so lets detect that and fix it
    // TODO: move this into an adapter service
    if (_file.name instanceof Array) {
      // we can find ourselves in a case where the file hasn't been fully written yet (generally Android), in which case the file comes through with `end` === 0 (file size 0)
      let escapeHatch = 0;
      const { localURL } = _file;
      let twoRunSameSize = false;
      let priorFileEnd = -1;
      const { getCacheFile } = await import('@utils/file-rw.js');
      do {
        // eslint-disable-next-line no-await-in-loop
        _file = await getCacheFile(localURL);
        twoRunSameSize = (_file.end || 0) === priorFileEnd;
        priorFileEnd = _file.end;
        escapeHatch++;
      } while ((_file.end === 0 || !twoRunSameSize) && escapeHatch < 10); // it generally doesn't take more than 2 times on a Pixel 3XL
    }
    const fileOpened = await dataWorld.importData({
      fileData: { file: _file, filepath: url.replace(/\?.+/, '') },
    });
    // switch to cached here so the loading state sticks around until we are ready to show the experiment
    experiment.status = 'cached';
    if (fileOpened) popoverManager.closeAll();
  }

  onSessionEnded() {
    vstLayoutStore.resetLayout();
    this.auxGraphCount = 0;
    const graphIds = ['graph_1', 'graph_2', 'graph_3'];
    store.dispatch(resetSession(graphIds));
    if (gaPeerStore.isClient && !this._continueDSSession) this.onStopDataShare();
    this._continueDSSession = false;
    if (this._stopLayoutAutorun) this._stopLayoutAutorun();
    this._stopLayoutAutorun = undefined;
  }

  onShowDataShareHost() {
    this.showDataShareHost = true;
    this.requestUpdate();
  }

  async onStartDataShareClient({ detail: { serverid } }) {
    const { dataWorld, gaPeerConnection, popoverManager } = this.$services;
    try {
      this._dataShareClientStarting = true;
      await gaPeerConnection.initiate();
      const rtcClient = await gaPeerConnection.connect(serverid);
      await dataWorld.startNewSession('DataShare', { rtcClient });
      popoverManager.closeAll();
      gaPeerStore.updatePeerStatus({ type: 'client', id: serverid });
      this.addPeerConnectionListeners();
      this.isConnecting = false;
    } catch (error) {
      // TODO: propagate error to peerStore
      console.error(error);
    } finally {
      this._dataShareClientStarting = false;
    }
  }

  async onStartDataShareHost({ target }) {
    try {
      if (this.peerId) return;
      const { gaPeerConnection } = this.$services;
      const peerId = await gaPeerConnection.initiate();
      gaPeerStore.updatePeerStatus({ id: peerId, type: 'host' });
      target.hostStarting = false;
      this.requestUpdate();
    } catch (error) {
      gaPeerStore.resetPeerStatus();
      console.error(error);
    }
  }

  async onStopDataShare() {
    if (this._dataShareClientStarting) {
      console.warn(
        'Calling onStopDataShare in the middle of starting datashare. Plese wait for the client to connect, then call stop.',
      );
      return;
    }
    const { gaPeerConnection } = this.$services;
    this.showDataShareStatus = false;
    gaPeerConnection.dispose();
    gaPeerStore.resetPeerStatus();
    this.requestUpdate();
  }

  onToggleDataShareStatus() {
    this.showDataShareStatus = !this.showDataShareStatus;
  }

  openDataCollectionSettings() {
    import('./ga-data-collection-settings/ga-data-collection-settings.js');
    this.showDataCollectionSettings = true;
  }

  setAppInitialized() {
    this._appInitialized = true;
    this.dispatchEvent(new CustomEvent('app-initialized'));
    this.$services?.dataCollection?.emit?.('app-initialized');
  }

  showAddMeterDialog() {
    this.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'select_meters_display',
          params: {
            title: getText('Add/Remove Meters'),
          },
        },
      }),
    );
  }

  async showSensorDialog(onLoad = false) {
    const requiredDevices = await this.$services.deviceManager.getGdxDeviceSources();
    const studentMode =
      Boolean(this.$services.urlHandler.sessionId) || this.$services.urlHandler.isDemoMode;
    const { bypassRequiredDevices, isGroupClient, isReadOnly } = this.$services.urlHandler;

    // if not a student, never show sensor confirmation on load,
    // students not shown sensor confirmation on load if no required devices,
    // if button got pressed, show sensors
    if (
      onLoad &&
      (bypassRequiredDevices ||
        isGroupClient ||
        !studentMode ||
        requiredDevices.length === 0 ||
        isReadOnly)
    ) {
      return;
    }

    this.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'device_manager',
          params: {
            title: getText('Sensors'),
            onLoad,
            studentMode,
            allowsLabQuestStreamDiscovery: true,
            webUSBOptions,
            windowsButNot10Message: getText(
              'This version of Windows supports only USB connection to Go Direct Sensors.',
            ),
          },
        },
      }),
    );
  }

  /**
   * Enable full screen wait component which comprises a spinner, an optional
   * message, and a meter for displaying live sensor value (e.g. while waiting
   * on a trigger).
   * @param {boolean} show true to show, false to hide.
   * @param {string} [message] additional text.
   * @param {import('@common/mobx-stores/Meter.js').Meter} [triggerMeter]
   * optional meter
   */
  async showWait(show, message = '', triggerMeter = null) {
    const toolbarEl = this.shadowRoot
      .querySelector('ga-toolbar')
      .shadowRoot.querySelector('vst-ui-toolbar'); // TODO: Verify that these classes are working as expected? Maybe we need to dive deeper.

    if (show) {
      await import('@components/vst-ui-wait/vst-ui-wait.js');
      // eslint-disable-next-line wc/no-self-class
      this.classList.add('show-wait');
      toolbarEl.classList.add('show-wait');
      this._showWait = true;
      this._waitMessage = message;
      this._waitTriggerMeter = triggerMeter;
    } else {
      // eslint-disable-next-line wc/no-self-class
      this.classList.remove('show-wait');
      toolbarEl.classList.remove('show-wait');
      this._showWait = false;
      this._waitMessage = '';
      this._waitTriggerMeter = null;
    }
  }

  stateChanged(state) {
    this.isSessionEmpty = state.isSessionEmpty;
    this.dataShareUpdates = state.enableDataShareUpdates;
  }

  toggleUIUpdates(uiConfig) {
    const { dataWorld } = this.$services;
    const config = { ...{ graphs: true, table: true, autoSave: true }, ...uiConfig };

    dataWorld.enableAutoSave = config.autoSave;
  }
  // #endregion

  render() {
    return html`
      ${this.persistentHostIframe ? html`<vst-ui-logo-vernier cover></vst-ui-logo-vernier>` : ''}

      <vst-ui-dialog no-close no-escape ?open=${this._isUnsupportedBrowserMessageVisible}>
        <h1 slot="header">${getText('Unsupported Browser')}</h1>
        <div slot="content">
          ${when(
            navigator.maxTouchPoints > 2,
            () => html`
              <p margin="block-end-m">${getText('Please download the app from the App Store.')}</p>
              <div class="inline">
                <div>
                  <a
                    class="btn"
                    size="medium"
                    href="https://apps.apple.com/us/app/vernier-graphical-analysis/id1385963326"
                    target="_blank"
                  >
                    ${getText('Open App Store')}
                  </a>
                </div>
              </div>
            `,
            () => html`<p>${getText('A recent Chrome browser is required.')}</p>`,
          )}
        </div>
      </vst-ui-dialog>

      <vst-ui-splash-screen id="splash_screen" .percentLoaded="${this.progress}">
        ${conditionalTemplate(
          !isPrivilegedIframe(),
          html`<ga-logo slot="logo"></ga-logo>`,
          html`<vst-ui-logo-vernier slot="logo" alt="Vernier Logo"></vst-ui-logo-vernier>`,
        )}
      </vst-ui-splash-screen>

      ${this.$services
        ? html`
            <div class="content-wrapper">
              <ga-toolbar
                aria-label="${getText('Toolbar')}"
                role="region"
                .authoringMode=${this._authoringMode}
                .experimentName="${this.experimentName}"
                .license="${this.license}"
                .dataShareHostingActive="${this.isDataShareHost}"
                .showDataShareStatus="${this.showDataShareStatus}"
                .dataShareHostingStatus="${this.dataShareStatus}"
                .auxGraphEnabled="${this.auxGraphCount >= 1}"
                .savePending="${this.savePending}"
                .saveError="${this.saveError}"
                .autoSaving="${this.autoSaving}"
                @create-new-experiment="${this.onCreateExperiment}"
                @submit-auth="${({ detail: { key } }) => vstAuthStore.authorize(key)}"
                @show-datashare-host="${this.onShowDataShareHost}"
                @start-datashare-host="${this.onStartDataShareHost}"
                @toggle-datashare-status="${this.onToggleDataShareStatus}"
                @open-file="${this._handleOpenFile}"
                @save-file="${e => this.onSaveFile(e.detail)}"
                @mark-data=${this.addDataMark}
                @experiment-reset="${() =>
                  this.$services.dataWorld.resetSession(
                    this.$services.urlHandler.manual ? 'ManualEntry' : 'DataCollection',
                  )}"
                @group-collection-complete="${() =>
                  serviceWorkerInitializer.groupCollectionCompleted()}"
                ?hidden=${this._frameFeatures?.disabledFeatures?.has('toolbar')}
              >
              </ga-toolbar>
              <ga-main-content
                class="main-content"
                id="main_content"
                role="main"
                .isCollecting="${this.isCollecting}"
                .graphModeTransition="${this.graphModeTransition}"
                @increment-aux-count="${this._incrementAuxGraphCount}"
                @decrement-aux-count="${this._decrementAuxGraphCount}"
                @add-remove-meters-clicked=${this.showAddMeterDialog}
              >
              </ga-main-content>
              <ga-bottombar
                aria-label="${getText('Bottombar')}"
                role="region"
                ?hidden="${this.$services.gaReplayService?.isReplayActive ||
                this._frameFeatures?.disabledFeatures?.has('bottom-bar')}"
                @open-data-collection-settings="${this.openDataCollectionSettings}"
                @reconnect-data-share-client="${async () =>
                  this.onStartDataShareClient({ detail: { serverid: gaPeerStore.id } })}"
                @open-sensor-dialog=${() => this.showSensorDialog()}
              >
                <vst-core-meter-container
                  .isCollecting=${this.isCollecting}
                  metersize="mini"
                ></vst-core-meter-container>
              </ga-bottombar>
              ${conditionalTemplate(
                this.license,
                html`
                  <vst-ui-soft-alert
                    aria-label="${getText('Pro Expiry Alert')}"
                    id="soft_alert_bottom"
                    class="soft_alert_trial"
                    ?hidden=${this.daysRemaining > DAYS_TO_SHOW_WARNING}
                    type=${this.daysRemaining < DAYS_TO_SHOW_ERROR ? 'error' : 'warning'}
                    canClose
                  >
                    ${this.daysRemaining <= 1
                      ? getText(`Vernier Graphical Analysis® Pro access expires today`)
                      : getText(
                          `Vernier Graphical Analysis® Pro access expires in ${this.daysRemaining} days`,
                        )}
                  </vst-ui-soft-alert>
                `,
              )}
              <div class="popover-wrapper" id="popover_wrapper"></div>
              <div id="toast_wrapper"></div>
              ${when(
                this._showWait,
                () =>
                  html`<vst-ui-wait
                    fillScreen="true"
                    .auxiliaryMeter=${this._waitTriggerMeter}
                    .message=${this._waitMessage}
                  ></vst-ui-wait>`,
              )}
              <slot name="tooltip_wrapper"></slot>
              <vst-ui-dialog
                style="--dialog-padding: 0"
                ?open="${this.showDataCollectionSettings}"
                id="data_collection_settings_dialog"
                @dialog-close="${this.closeDataCollectionSettings}"
              >
                ${conditionalTemplate(
                  this.showDataCollectionSettings,
                  html`
                    <h2 slot="header">${getText('Data Collection Settings')}</h2>
                    <ga-data-collection-settings slot="content"></ga-data-collection-settings>
                  `,
                )}
              </vst-ui-dialog>

              <vst-ui-dialog
                ?open="${this.showDataShareHost}"
                style="--dialog-z-index: calc(var(--vst-z-popover) - 1); --dialog-padding: var(--vst-space-xl)"
                @dialog-close="${this._resetDataShareHost}"
              >
                <h2 slot="header">${getText('Start a Data Sharing Session')}</h2>
                <ga-datashare-host
                  id="datashare_host"
                  ?hidden="${!this.showDataShareHost}"
                  .hostActive="${this.isDataShareHost}"
                  @start-datashare-host="${this.onStartDataShareHost}"
                  slot="content"
                ></ga-datashare-host>
              </vst-ui-dialog>

              <vst-ui-dialog
                style="--dialog-z-index: calc(var(--vst-z-popover) - 1);"
                ?open="${this.showDataShareStatus}"
                @dialog-close="${this._resetDataShareStatus}"
              >
                <h2 slot="header">${getText('Data Sharing Status')}</h2>
                <ga-datashare-status
                  slot="content"
                  @stop-datashare-host="${this.onStopDataShare}"
                  ?hidden="${!this.showDataShareStatus}"
                ></ga-datashare-status>
              </vst-ui-dialog>

              <vst-ui-dialog
                ?open="${this.errorType === 'offline' &&
                (this.isDataShareHost || this.isDataShareClient)}"
                @dialog-close="${this.isDataShareHost ? () => gaPeerStore.resetPeerStatus() : ''}"
              >
                <h2 slot="header">${getText('Connection Lost')}</h2>
                <ga-datashare-disconnected
                  slot="content"
                  @start-new-session="${this.onStartDataShareHost}"
                  @reconnect-data-share-client="${async () =>
                    this.onStartDataShareClient({ detail: { serverid: gaPeerStore.id } })}"
                ></ga-datashare-disconnected>
              </vst-ui-dialog>

              <vst-ui-dialog
                ?open="${this.showSampleExperiments}"
                style="--overflow-y: hidden"
                @dialog-close="${this._resetSampleExperiments}"
              >
                ${this.showSampleExperiments
                  ? html`
                      <h2 slot="header">${getText('Sample Experiments')}</h2>
                      <ga-sample-data
                        slot="content"
                        .experiments="${this.sampleExperiments}"
                        @open-sample-experiment="${this.onOpenSampleFile}"
                      ></ga-sample-data>
                    `
                  : html``}
              </vst-ui-dialog>

              <vst-ui-dialog
                no-close
                no-escape
                ?open=${this._showDataLoggingStatusDialog}
                @dialog-close=${() => {
                  this._handleDataLoggingStatusClose();
                }}
              >
                <h2 slot="header">${this._dataLoggingStatusHeaderText}</h2>
                <ga-data-logging-status
                  slot="content"
                  .isUSB=${this.$services.deviceManager.connectedUsbDevices.length > 0}
                  .status=${this._offlineLoggingStatus}
                  .progressPercentage=${this._remoteRetrievalProgress}
                  @retrieve-data=${this._handleDataLoggingRetrieval}
                  @disconnect=${this._handleDataLoggingDisconnect}
                  @stop-collection=${this._handleStopDataLogging}
                  @discard-logged-data=${this._handleDiscardLoggedData}
                ></ga-data-logging-status>
              </vst-ui-dialog>
            </div>
            <vst-ui-dialog-manager>
              ${this.dialogs.templates.map(template => template.call(this))}
            </vst-ui-dialog-manager>

            ${this._dialogTemplates()}
          `
        : ''}
    `;
  }
}
customElements.define('ga-app', GaApp);
