import { createBgPageComm } from '@services/adapters/bg-page-comm/index.js';
import { createUrlHandler } from '@services/adapters/url-handler/index.js';
import { createDsSourceDiscovery } from '@services/adapters/ds-source-discovery/index.js';
import { GraphicalAnalysis } from './GraphicalAnalysis.js';
import { GraphicalAnalysisAPI } from './GraphicalAnalysisAPI.js';
import { Replay } from './Replay.js';
import { ReplayAPI } from './ReplayAPI.js';
import { RTCDataShareAPI } from './rtc-data-share/RTCDataShareAPI.js';
// import { RTCDataShare } from './rtc-data-share/RTCDataShare.js';
import { PeerConnection } from './PeerConnection.js';

export const initGAServices = ({ dataWorld, dataCollection, rpc }) => {
  const gaAppService = new GraphicalAnalysis({
    api: new GraphicalAnalysisAPI(rpc),
    dataWorld,
  });

  const gaReplayService = new Replay({
    api: new ReplayAPI(rpc),
    dataWorld,
    dataCollection,
  });

  const gaPeerConnection = new PeerConnection({
    api: new RTCDataShareAPI(rpc),
    dataWorld,
    dataCollection,
  });

  // Uncomment the RTCDataShare bits in this file to perform runtime local testing of DataShareServer and Client.
  // const gaTestDataShare = new RTCDataShare({
  //   api: new RTCDataShareAPI(rpc),
  //   dataWorld,
  //   dataCollection,
  // })

  const bgPageComm = createBgPageComm();
  const dsSourceDiscovery = createDsSourceDiscovery();
  const urlHandler = createUrlHandler();

  return {
    gaAppService,
    gaReplayService,
    bgPageComm,
    dsSourceDiscovery,
    gaPeerConnection,
    urlHandler,
    // gaTestDataShare,
  };
};
