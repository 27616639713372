export class ReplayAPI {
  constructor(rpc) {
    this.rpc = rpc;
  }

  /**
   * Request the BE to enter a replay active state, in which some or all of the values in the replay data set columns
   * will be removed to hide them from the FE
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} dataSetId -- UDM id of the data set that replay should manipulate
   * @param {number} columnGroupId -- if non-zero, use corresponding column INSTEAD of default time column for replay.
   */
  enterReplayMode(experimentId, dataSetId, columnGroupId = 0) {
    return this.rpc.sendRequest('enter-replay-mode', { experimentId, dataSetId, columnGroupId });
  }

  /**
   * Update data playback time -- tells the BE to show all data in the replay data set up to and including
   * the specified time stamp
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} timeStamp -- time stamp in seconds. BE will show all data up to and including this time stamp.
   */
  updateDataPlaybackTime(experimentId, timeStamp) {
    return this.rpc.sendRequest('data-replay-update-time', { experimentId, timeStamp });
  }

  /**
   * Request the BE to exit a replay active state.
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {boolean} noFlush -- Option to request that the BE skip updating the FE with any newly revealed column values after exiting
   */
  exitReplayMode(experimentId, noFlush = false) {
    return this.rpc.sendRequest('exit-replay-mode', { experimentId, noFlush });
  }

  /**
   * Update the global replay options in UDM
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} dataSetId -- currently selected dataset
   * @param {boolean} active -- whether the replay is active or not
   * @param {number} rate -- playback rate
   */
  setReplayOptions(experimentId, dataSetId, active, rate) {
    return this.rpc.sendRequest('set-replay-options', { experimentId, dataSetId, active, rate });
  }

  /**
   * Retrieve the current global replay options from UDM
   * @param {number} experimentId - UDM id of the experiment
   */
  getReplayOptions(experimentId) {
    return this.rpc.sendRequest('get-replay-options', { experimentId });
  }

  /**
   * Associate sync times for a dataset-video pair in udm
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} dataSetId -- dataset to set
   * @param {number} videoId -- video to associate with the dataset
   * @param {number} dataSyncTime -- timestamp in dataset to sync
   * @param {number} videoSyncTime -- timestamp in video to sync
   * @param {boolean} enabled -- whether sync is enabled for the data set
   */
  setReplayParams(experimentId, dataSetId, videoId, dataSyncTime, videoSyncTime, enabled) {
    return this.rpc.sendRequest('set-replay-params', {
      experimentId,
      dataSetId,
      videoId,
      dataSyncTime,
      videoSyncTime,
      enabled,
    });
  }

  /**
   * Retrive the replay params for the given dataset
   *
   * @param {number} experimentId - UDM id of the experiment
   * @param {number} dataSetId
   */
  getReplayParams(experimentId, dataSetId) {
    return this.rpc.sendRequest('get-replay-params', { experimentId, dataSetId });
  }

  /**
   * Returns timing information for a passed in list of data set ids.
   * @param {number} experimentId - UDM id of the experiment
   * @param {array} dataSetIds array of data set ids as integers.
   * @param {number} columnGroupId [optional] use the column corresponding to this group id instead of the time column.
   * @returns {object} keyed on data set id (as integers), contains `duration` in seconds and `timeInterval` in seconds.
   */
  fetchTimingInformation(experimentId, dataSetIds, columnGroupId = 0) {
    return this.rpc.sendRequest('fetch-timing-information', {
      experimentId,
      dataSetIds,
      columnGroupId,
    });
  }
}
