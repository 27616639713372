import { posthog } from 'posthog-js';

/**
 * @param {string} posthogProjectKey - public api key for app specific posthog project
 */
export const initAnalytics = posthogProjectKey => {
  posthog.init(posthogProjectKey, {
    api_host: 'https://d1tdellpq3vmcl.cloudfront.net',
    ui_host: 'https://us.posthog.com',
    persistence: 'localStorage',
    person_profiles: 'identified_only',
  });
};

/**
 * Creates super properties for licenseName and licenseKey so they are captured
 * alongside properties from every custom event.
 * @param {string} licenseName
 * @param {string} licenseKey
 */
export const collectLicenseDataForAnalytics = (licenseName, licenseKey) => {
  posthog.register({
    vstLicenseName: licenseName,
    vstLicenseKey: licenseKey,
  });
};

/**
 * @param {Object} platformData
 * @param {string} platformData.osFamily
 * @param {string} platformData.osVersion
 * @param {string} platformData.platformId
 * @param {string} platformData.appVersion
 */
export const capturePlatformAnalyticsData = ({ osFamily, osVersion, platformId, appVersion }) => {
  posthog.capture('vst_platform_used', {
    vstPlatformOs: osFamily,
    vstPlatformOsVersion: osVersion,
    vstPlatformId: platformId,
    vstAppVersion: appVersion,
  });
};

/**
 * @param {import('@api/common/deviceManager/Device.js').Device} device
 * @param {string} device.orderCode
 * @param {string} device.type - connection type.
 */
export const captureDeviceConnectedAnalyticsData = ({ orderCode, type }) => {
  posthog.capture('vst_device_connected', {
    vstDeviceOrderCode: orderCode,
    vstDeviceConnectionType: type,
  });
};

/**
 * @param {import('@api/common/Sensor.js').Sensor} sensor
 * @param {string} sensorAnalyticsData.sensorId
 * @param {string} sensorAnalyticsData.name
 */
export const captureSensorAddedAnalyticsData = ({ name, sensorId }) => {
  posthog.capture('vst_sensor_connected', {
    vstSensorName: name,
    vstSensorId: sensorId,
  });
};

/**
 * @param {Object} sessionConfig
 * @param {string} sessionConfig.imported
 * @param {string} sessionConfig.sessionType
 * @param {string} sessionConfig.sessionSubtype
 * @param {string} sessionConfig.type
 */
export const captureSessionAnalyticsData = ({
  imported = false,
  sessionType = '',
  sessionSubtype = '',
  type = '',
}) => {
  posthog.capture('vst_session_started', {
    vstImportedFromFile: imported,
    vstSessionType: sessionType,
    vstSessionSubtype: sessionSubtype,
    vstSessionTypeName: type,
  });
};
