import EventEmitter from 'eventemitter3';
import { UdmArchive } from '@utils/UdmArchive.js';

export class GraphicalAnalysis extends EventEmitter {
  constructor({ api, dataWorld }) {
    super();
    this.dataWorld = dataWorld;
    this.dataWorld.videoAttachmentImportHook = this.videoAttachmentImportHook.bind(this);
    this.api = api;
    this.videoId = null;

    this.dataWorld.on('session-ended', async () => {
      if (this.dataWorld?.archive?.clearAttachments)
        this.dataWorld.archive.clearAttachments('videos');
      this.videoId = null;
    });

    this.dataWorld.on('view-changed', async views => {
      if (views.videos?.length) this.videoId = views.videos[0].id;
    });

    // make the archive the promise for the creation so we can await it where it must be finished before we start
    // eslint-disable-next-line no-async-promise-executor
    this.dataWorld.archive = new Promise(async resolve => {
      const archive = UdmArchive.create({
        maxDBSize: 2 * 1024 * 1024 * 1024, // example usage (5 MB): 5 * 1024 * 1024,
        leaveAfterPrune: 20,
      });
      await this.dataWorld.blockSynced;
      if (window.__isSessionClient) {
        const attachments = await archive.attachments;
        const attachmentKeys = Object.keys(attachments);
        if (attachmentKeys.length) {
          Promise.all(
            attachmentKeys.map(async key => {
              if (!key.includes('videos')) return;
              const videoAttachment = await archive.getAttachment(key);
              const videoBlob = new Blob([videoAttachment.data], {
                type: videoAttachment.mimeType,
              });

              this.updateVideo(videoBlob);
            }),
          );
        }
      }
      resolve(archive);
    }).then(archive => {
      // then set the archive to the result so after the await, it has the actual archive
      this.dataWorld.archive = archive;
    });
  }

  validateJWT = token => this.api.validateJWT(token);

  get videoUrl() {
    return this._videoUrl;
  }

  async videoAttachmentImportHook(blob) {
    this.updateVideo(blob, true);
  }

  async updateVideo(file, importing = false) {
    if (this._videoUrl) {
      window.URL.revokeObjectURL(this._videoUrl);
    }

    try {
      if (!importing) {
        await this._setVideo(file); // videoId is returned but we're not currently using it
      }
      this._videoUrl = window.URL.createObjectURL(file) || '';
      this.emit('video-src-changed', { src: this._videoUrl });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Adds a video to UdmArchive and sends pathname to UDM store.
   * @param {object} file - file blob
   * @returns {number} videoId - which is the udm ID of the video
   */
  async _setVideo(file) {
    try {
      await this.dataWorld.archive.clearAttachments('videos');
      const { archPath } = await this.dataWorld.archive.addAttachment('videos', file);
      this.videoId = await this.api.setVideo(this.dataWorld.experimentId, archPath); // hold on to the current videoId in this service for now
      this.dataWorld.archive.setAttachmentId(archPath, this.videoId);
      return this.videoId;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  /**
   * Remove video with specfied id.
   * @param {*} videoId udm Id of video. This is obtained as a result of `setVideo()` or by inspecting `videos` array of pageAttributes.
   */
  async removeVideo(videoId = this.videoId) {
    try {
      await this.api.removeVideo(this.dataWorld.experimentId, Math.floor(videoId));
      await this.dataWorld.archive.clearAttachments('videos');
      this.emit('video-src-changed', { src: '' });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Set visibility of video in UDM store.
   * @param {boolean} visible true if video is visible or false if not.
   */
  setVideoVisibility(visible, videoId = this.videoId) {
    return this.api.setVideoVisibility(this.dataWorld.experimentId, visible, videoId);
  }
}
