// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-extraneous-dependencies
import EventEmitter from 'eventemitter3';
import { clone } from 'lodash-es';

import { getText } from '@utils/i18n.js';

let nextId = 0;

export class Sensor extends EventEmitter {
  /**
   * A collection of useful sensor auto-ids.
   */
  static AutoIds = Object.freeze({
    /**
     * Auto-ids for sensors that can be zeroed at the beginning of data
     * collection (excludes BTD sensors which perform zeroing at a much lower
     * level in the back end).
     */
    CanZeroOnCollect: [451],

    /**
     * Auto-ids corresponding to sensors used specifically for Instrumental
     * Analysis.
     */
    Instruments: {
      kCVSCurrentSensorAutoId: 564,
      kCVSVoltageSensorAutoId: 565,
      kPOLOpticalRotationSensorAutoID: 591,
      kPOLIlluminationSensorAutoID: 592,
      kPOLAngleSenorID: 593,
    },
  });

  constructor({
    autoId = -1,
    canZeroOnCollect = false,
    dataMode = null,
    deviceId = '',
    channelId = 0,
    hasX4Mode = false,
    id = nextId++,
    liveSpectrum = [],
    liveValue = 0,
    liveVoltage = 0,
    name = '',
    position = 0,
    sensorId = '',
    sensorInfo = {},
    unitInfo = [],
    units = '',
    wavelength = '',
    x4Mode = false,
    zeroOnCollect = false,
    experimentId = 0,
    reversed = false,
  }) {
    super();
    this.experimentId = experimentId;

    this._autoId = autoId;
    this._canZeroOnCollect = canZeroOnCollect;
    this._dataMode = dataMode;
    this._deviceId = deviceId;
    this._channelId = channelId;
    this._hasX4Mode = hasX4Mode;
    this._id = id;
    this._liveSpectrum = liveSpectrum;
    this._liveValue = liveValue;
    this._liveVoltage = liveVoltage;
    this._name = name;
    this._position = position;
    this._sensorId = sensorId;
    this._sensorInfo = sensorInfo;
    this._unitInfo = unitInfo;
    this._units = units;
    this._wavelength = wavelength; // SA only
    this._zeroOnCollect = zeroOnCollect;
    this._x4Mode = x4Mode;

    this._calibrationReversed = reversed;
    this._calibrationProcesses = [];

    this._calibration = {
      type: this._sensorInfo?.calibration?.type,
      coeffs: clone(this._sensorInfo?.calibration?.coeffs),
    };

    // must be passed into the sensor - consider removing this dependency
    console.assert(Sensor.sensorWorld);
  }

  clone(propOverride) {
    const sensor = new Sensor({
      autoId: this.autoId,
      canZeroOnCollect: this.canZeroOnCollect,
      dataMode: this.dataMode,
      deviceId: this.deviceId,
      channelId: this.channelId,
      hasX4Mode: this.hasX4Mode,
      id: this.id,
      liveSpectrum: this.liveSpectrum,
      liveValue: this.liveValue,
      liveVoltage: this.liveVoltage,
      name: this.name,
      position: this.position,
      sensorId: this.sensorId,
      units: this.units,
      wavelength: this.wavelength,
      zeroOnCollect: this.zeroOnCollect,
      x4Mode: this.x4Mode,
    });

    if (propOverride) {
      Object.keys(propOverride).forEach(key => {
        sensor[key] = propOverride[key];
      });
    }
    return sensor;
  }

  addSamples(float64Samples) {
    this.emit('samples-available', float64Samples);
    if (float64Samples.length > 0) {
      this.liveValue = float64Samples[float64Samples.length - 1];
    }
  }

  async zero() {
    // only works with linear sensors currently
    await Sensor.sensorWorld.zeroSensor(this.experimentId, this.id, this.liveValue);
  }

  // make sure canReverse is true before calling this
  async reverse() {
    // only works with linear sensors currently
    try {
      await Sensor.sensorWorld.reverseSensor(this.experimentId, this.id, this.liveValue);
    } catch (error) {
      throw new Error(error);
    }
  }

  // array of pairs: [[volts,referenceValue], ...]
  async calculateCalibrationCoeffs(pairs) {
    const result = await Sensor.sensorWorld.calculateCalibrationCoeffs(
      this.experimentId,
      this.id,
      pairs,
    );
    return result;
  }

  // pass an array of coefficients to set for the sensor's calibration
  async setCalibrationCoeffs(coeffs, optReverse) {
    // apply reverse if enabled
    if (optReverse || this._calibrationReversed) {
      coeffs.forEach((c, i) => {
        coeffs[i] = -c;
      });
    }

    try {
      await Sensor.sensorWorld.setCalibration(this.experimentId, this.id, coeffs);
      this.updateInternalCalibrationCoeffs(coeffs);

      return {
        calibration: this._calibration,
      };
    } catch (error) {
      throw new Error(error);
    }
  }

  // updates the local copy of the calibration's coefficients
  updateInternalCalibrationCoeffs(coeffs) {
    this._calibration.coeffs = clone(coeffs);
  }

  updateCalibrationProcesses(processes) {
    this._calibrationProcesses = clone(processes);
  }

  clearCalibrationProcesses() {
    this._calibrationProcesses = [];
  }

  getPrecisionForUnit(unit) {
    const info = this.unitInfo?.find(info => info.unit === unit);
    let precision;

    if (info) precision = clone(info.precision);
    if (this.x4Mode) precision.precision += 1;

    return precision;
  }

  /**
   * @returns {Promise<boolean>} Promise that resolves with boolean indicating success
   */
  setModeX4(enable) {
    return Sensor.sensorWorld.setSensorModeX4(this.experimentId, this.id, enable);
  }

  /**
   * @returns {Promise<boolean>} Promise that resolves with boolean indicating success
   */
  setZeroOnCollect(enable) {
    return Sensor.sensorWorld.setSensorZeroOnCollect(this.experimentId, this.id, enable);
  }

  get autoId() {
    let { _autoId: autoId } = this;
    if (this.dds) autoId = this?.dds?.sensorNumber;
    return autoId;
  }

  get canZeroOnCollect() {
    return this._canZeroOnCollect;
  }

  set canZeroOnCollect(canZeroOnCollect) {
    this._canZeroOnCollect = canZeroOnCollect;
  }

  get dataMode() {
    return this._dataMode;
  }

  set dataMode(dataMode) {
    this._dataMode = dataMode;
    this.emit('data-mode-changed', dataMode);
  }

  get deviceId() {
    return this._deviceId;
  }

  set deviceId(deviceId) {
    this._deviceId = deviceId;
    this.emit('device-id-changed', deviceId);
  }

  get channelId() {
    return this._channelId;
  }

  set channelId(channelId) {
    this._channelId = channelId;
  }

  get hasX4Mode() {
    return this._hasX4Mode;
  }

  set hasX4Mode(hasX4Mode) {
    this._hasX4Mode = hasX4Mode;
  }

  get id() {
    return this._id;
  }

  set id(newId) {
    this._id = newId;
  }

  get liveSpectrum() {
    return this._liveSpectrum;
  }

  set liveSpectrum(liveSpectrum) {
    this._liveSpectrum = liveSpectrum;
    this.emit('live-spectrum-changed', liveSpectrum);
  }

  get liveValue() {
    return this._liveValue;
  }

  set liveValue(liveValue) {
    this.lastTimestamp = Date.now();
    this._liveValue = liveValue;
    this.emit('live-value-changed', liveValue);
  }

  get liveVoltage() {
    return this._liveVoltage;
  }

  set liveVoltage(liveVoltage) {
    this._liveVoltage = liveVoltage;
    this.emit('live-voltage-changed', liveVoltage);
  }

  get liveVoltageFormatStr() {
    const formatStr = this.sensorInfo.voltageDecimals;
    return `%.${formatStr}f`;
  }

  get name() {
    return getText(this._name, 'sensormap');
  }

  set name(name) {
    this._name = name;
    this.emit('name-changed', name);
  }

  get position() {
    return this._position;
  }

  set position(position) {
    this._position = position;
    this.emit('position-changed', position);
  }

  get sensorId() {
    return this._sensorId;
  }

  get sensorInfo() {
    return this._sensorInfo;
  }

  get units() {
    return this._units;
  }

  set units(units) {
    this._units = units;
  }

  get unitInfo() {
    return this._unitInfo;
  }

  get wavelength() {
    return this._wavelength;
  }

  set wavelength(wavelength) {
    (async () => {
      try {
        await Sensor.sensorWorld.setSelectedWavelength(this.experimentId, this.id, wavelength);
        this._wavelength = wavelength;
        this.emit('wavelength-changed', wavelength);
      } catch (e) {
        console.error(e);
      }
    })();
  }

  get zeroOnCollect() {
    return this._zeroOnCollect;
  }

  set zeroOnCollect(zeroOnCollect) {
    this._zeroOnCollect = zeroOnCollect;
  }

  get x4Mode() {
    return this._x4Mode;
  }

  set x4Mode(x4Mode) {
    this._x4Mode = x4Mode;
  }

  // computed helpers
  get availableUnits() {
    return this?.unitInfo.map(info => info.unit);
  }

  get calibration() {
    const calibration = this._calibration;
    return {
      type: calibration.type,
      coeffs: clone(calibration.coeffs),
      processes: this._calibrationProcesses,
    };
  }

  get canCalibrate() {
    const { _sensorInfo: sensorInfo } = this;
    const { type } = sensorInfo?.calibration;
    const canCalibrate = sensorInfo?.calibration?.calibrate;
    const isOfType = type === 'linear' || type === 'power' || type === 'modifiedpower';
    return canCalibrate && isOfType;
  }

  get canCalibrateGDX() {
    return this._calibrationProcesses.length > 0;
  }

  get canOnePointCalibrate() {
    return this._sensorInfo?.calibration?.onePoint;
  }

  get canReverse() {
    return this._sensorInfo?.calibration.reverse;
  }

  get canZero() {
    const { sensorInfo } = this;
    const canZero = sensorInfo?.calibration.zero;

    return canZero && ['linear', 'rotary', 'linearposition'].includes(sensorInfo?.calibration.type);
  }

  get groupId() {
    return 100 + this.id;
  }

  set reversed(reversed) {
    this._calibrationReversed = reversed;
  }

  get isReversed() {
    return this._calibrationReversed;
  }

  get triggerable() {
    const { sensorInfo } = this;
    let isTriggerable = false;
    if (['linearposition', 'linear'].includes(sensorInfo.calibration.type)) {
      isTriggerable = true;
    }
    return isTriggerable;
  }
}
