import { VSTAnalysis, VSTAnalysisError } from '@services/analysis/VSTAnalysis.js';
import { vstCurvefitStore } from '@common/mobx-stores/vst-curvefit.store.js';
import { fitType } from '@services/analysis/api/fitType.js';
import { errorType } from '@services/analysis/api/errorType.js';
import { nearlyEqual } from '@utils/helpers.js';
import { getText } from '@utils/i18n.js';

const MAX_CUSTOM_FIT_PARAMETERS = 7;

/**
 * @typedef {Object} AnalysisData data that is fed into certain calculatons.
 * @property {Array<number>} xData array of x values
 * @property {Array<number>} yData array of y values
 */

/**
 * @typedef {Object} AnalysisRange a range object
 * @property {number} min minimum value in range
 * @property {number} max max value.
 */

const makeTypedArrays = dataPairs => {
  const { length } = dataPairs;
  const arr = [];
  const x = new Float64Array(length);
  const y = new Float64Array(length);

  let minX = Infinity;
  let maxX = -Infinity;

  for (let i = 0; i < length; ++i) {
    // eslint-disable-next-line prefer-destructuring
    x[i] = dataPairs[i][0];
    // eslint-disable-next-line prefer-destructuring
    y[i] = dataPairs[i][1];

    if (x[i] < minX) {
      minX = x[i];
    }
    if (x[i] > maxX) {
      maxX = x[i];
    }
  }

  arr.push(maxX);

  return { x, y, baseArray: new Float64Array(arr) };
};

const makePoints = (xArr, yArr) => {
  const { length } = xArr;
  const arr = [];

  // FIXME (@mossymaker): assertion briefly fails when the data is being un-struck
  console.assert(xArr.length === yArr.length);

  for (let i = 0; i < length; ++i) {
    arr.push([xArr[i], yArr[i]]);
  }

  return arr;
};

const makeTypedArraysForRange = (xData, yData, min, max) => {
  const pointPairs = [];

  // get the data for the given range
  if (xData.length > 0 && yData.length > 0) {
    const minLength = Math.min(xData.length, yData.length);
    for (let i = 0; i < minLength; ++i) {
      const x = xData[i];
      const y = yData[i];

      if (!Number.isNaN(x) && !Number.isNaN(y)) {
        // are we in range?
        if ((x > min || nearlyEqual(x, min)) && (x < max || nearlyEqual(x, max))) {
          pointPairs.push([x, y]);
        }
      }
    }
  }

  return makeTypedArrays(pointPairs);
};

export class DataAnalysis {
  constructor({ dataWorld, api }) {
    this.dataWorld = dataWorld;
    this.vstAnalysis = new VSTAnalysis(api);

    this.tempCustomFitHandle = null;
    this.isHydrating = false;
    this.curveFitStore = vstCurvefitStore;

    // TODO: what's a better way to handle this
    vstCurvefitStore._releaseHandle = async handle => {
      return this.vstAnalysis.releaseHandle(handle);
    };

    // eslint-disable-next-line consistent-return
    vstCurvefitStore._updateCustomFit = async (name, expression) => {
      if (!this.isHydrating) {
        return this.dataWorld.setCustomCurveFit(name, expression);
      }
    };
  }

  // pass name === null for temporary
  async parseEquation(type, equation) {
    if (Number.isInteger(this.tempCustomFitHandle)) {
      await this.vstAnalysis.releaseHandle(this.tempCustomFitHandle);
      this.tempCustomFitHandle = null;
    }

    const result = await this.vstAnalysis.parseEquation(equation);

    // special handling for number of parameters/coefficients
    if (!result?.error) {
      if (result.coefficients.length > MAX_CUSTOM_FIT_PARAMETERS) {
        result.error = 'Too may parameters specified';
      }
    }

    if (!result?.error) {
      if (type) {
        await vstCurvefitStore.updateFit({
          type,
          y: equation,
          handle: result.handle,
          coefficients: result.coefficients,
        });
      } else {
        this.tempCustomFitHandle = result.handle;
      }
    }

    return { ...result, type };
  }

  async hydrateCustomFits(customFits) {
    this.isHydrating = true;
    const names = Object.keys(customFits);

    for (const name of names) {
      const expr = customFits[name];
      // eslint-disable-next-line no-await-in-loop
      await this.parseEquation(name, expr);
    }

    this.isHydrating = false;
  }

  // eslint-disable-next-line class-methods-use-this
  getSupportedFits() {
    return vstCurvefitStore.fits;
  }

  async calculateFitByType(type, xid, yid, range) {
    const fit = this.getFit(type);

    return this.calculateFit(type, fit.id, xid, yid, range, fit.handle);
  }

  async calculateFit(type, typeId, xid, yid, range, handle) {
    const { dataWorld } = this;

    const xCol = dataWorld.getColumnById(xid);
    const yCol = dataWorld.getColumnById(yid);

    const xData = xCol ? xCol.filteredValues : [];
    const yData = yCol ? yCol.filteredValues : [];

    const data = makeTypedArraysForRange(xData, yData, range.min, range.max);

    try {
      const response = await this.vstAnalysis.computeCurveFit(
        typeId,
        data.x,
        data.y,
        null,
        null,
        handle,
      );

      const correlation = response.correlation || response?.linear?.correlation || null;
      const fitInfo = vstCurvefitStore.getFitInfo(type, { ...response, correlation });

      const { coefficients, uncertainties } = response;

      return {
        type,
        fitInfo,
        coefficients,
        uncertainties,
      };
    } catch (err) {
      if (err instanceof VSTAnalysisError && err.errorCode === errorType.TOO_FEW_POINTS) {
        return { error: getText('Too few points') };
      }
      console.error(err);
      throw err;
    }
  }

  getFit(type) {
    const emptyFit = {
      type: 'NONE',
      name: 'none',
      coefficients: [],
      id: fitType.NONE,
    };

    let fit = vstCurvefitStore.getFit(type);
    if (!fit && type === 'CUSTOM') {
      fit = {
        type: 'CUSTOM',
        name: '',
        coefficients: [],
        id: fitType.CUSTOM,
        handle: this.tempCustomFitHandle,
      };
    }

    return fit || emptyFit;
  }

  async computeTraceByType(type, coefficients, range, pixelDelta) {
    const fit = this.getFit(type);
    return this.computeTrace(fit.type, fit.id, coefficients, range, pixelDelta, fit.handle);
  }

  async computeTrace(type, typeId, coefficients, range, pixelDelta, handle) {
    function getXVals(_range, _pixelDelta) {
      const length = Math.floor((_range.max - _range.min) / _pixelDelta);
      const xPoints = [];
      let xVal = _range.min;

      for (let i = 0; i < length; ++i) {
        xPoints.push(xVal);
        xVal += _pixelDelta;
      }

      xPoints.push(_range.max);
      return Float64Array.from(xPoints);
    }

    const xValues = getXVals(range, pixelDelta);
    const response = await this.vstAnalysis.calcFitValues(typeId, coefficients, xValues, handle);
    const yValues = response.values;

    return {
      type,
      points: makePoints(xValues, yValues),
    };
  }

  async computeFFT(xid, yid, removeLinear, filterType, range) {
    const { dataWorld } = this;

    const xCol = dataWorld.getColumnById(xid);
    const yCol = dataWorld.getColumnById(yid);

    let xData = Float64Array.from(xCol ? xCol.filteredValues : []);
    let yData = Float64Array.from(yCol ? yCol.filteredValues : []);

    if (range) {
      ({ x: xData, y: yData } = makeTypedArraysForRange(xData, yData, range.min, range.max));
    }

    return await this.vstAnalysis.computeFFT(xData, yData, removeLinear, filterType);
  }

  /**
   * Compute histogram for the given parameters
   *
   * @param {string} baseColumnId Base column ID
   * @param {string} yColumnId Y Column ID
   * @param {number} binWidth Bin width
   * @param {number} binStart Bin start
   * @param {{ min: number, max: number }} range Range, keys for min and max
   * @return  {{ bins: Float64Array, counts: Float64Array }} An object with bins and counts
   */
  async computeHistogram(baseColumnId, yColumnId, binWidth, binStart, range) {
    const { dataWorld } = this;
    const baseColumn = dataWorld.getColumnById(baseColumnId);
    const yColumn = dataWorld.getColumnById(yColumnId);
    const { y: data } = makeTypedArraysForRange(
      baseColumn.filteredValues,
      yColumn.filteredValues,
      range.min,
      range.max,
    );

    return await this.vstAnalysis.computeHistogram(data, binWidth, binStart);
  }

  /**
   * Fetches data from x and y columns and filters out nonfinite values.
   * @param {number} xid x column id
   * @param {number} yid y columm id
   * @param {AnalysisRange} [range] if specified, only points whose x values are
   * contained in the range inclusively will be returned.
   * @returns {AnalysisData} data suitable for integral calculation.
   */
  _preprocessColumnData(xid, yid, range) {
    const { dataWorld } = this;

    const yCol = dataWorld.getColumnById(yid);
    const xCol = dataWorld.getColumnById(xid);

    const rawYData = yCol ? yCol.filteredValues : [];
    const rawXData = xCol ? xCol.filteredValues : [];

    const isInRange = x => x >= range.min && x <= range.max;

    const filteredPoints = makePoints(rawXData, rawYData).filter(([x, y]) => {
      const isFinite = Number.isFinite(x) && Number.isFinite(y);
      return isFinite && (!range || isInRange(x));
    });

    return {
      xData: filteredPoints.map(elem => elem[0]),
      yData: filteredPoints.map(elem => elem[1]),
    };
  }

  // Fetches indices corresponding to the given x-value range.
  static _indicesForRange(data, range) {
    const xDataReversed = data.xData.slice().reverse();

    // find the indices for the x-array elements that fall on the edges of the range if possible
    const first = data.xData.findIndex(elem => nearlyEqual(elem, range.min) || elem > range.min);
    const lastReversedIndex = xDataReversed.findIndex(
      elem => nearlyEqual(elem, range.max) || elem < range.max,
    );
    const last = data.xData.length - lastReversedIndex - 1;

    if (first === -1 || lastReversedIndex === -1) {
      return { valid: false };
    }

    return {
      begin: first,
      end: last,
      valid: true,
    };
  }

  static _performIntregralCalculation(data, indices) {
    const result = {
      integralValue: 0.0,
      integralData: [],
      success: false,
    };

    // If indices are invalid, exit early.
    if (!indices.valid) {
      return result;
    }

    for (let i = indices.begin; i < indices.end; i++) {
      // compute trapezoidal method discrete integral approximation
      result.integralValue +=
        ((data.yData[i] + data.yData[i + 1]) * (data.xData[i + 1] - data.xData[i])) / 2.0;
      result.integralData.push([data.xData[i], data.yData[i]]);
      result.success = true;
    }
    result.integralData.push([data.xData[indices.end], data.yData[indices.end]]);

    return result;
  }

  calculateIntegralAndGetData(xid, yid, range) {
    const data = this._preprocessColumnData(xid, yid, range);
    const indices = DataAnalysis._indicesForRange(data, range);
    return DataAnalysis._performIntregralCalculation(data, indices);
  }

  calculatePeakIntegral(xid, yid, subRange, totalRange) {
    const data = this._preprocessColumnData(xid, yid);
    const subIndices = DataAnalysis._indicesForRange(data, subRange);
    const totalIndices = DataAnalysis._indicesForRange(data, totalRange);
    const integral = DataAnalysis._performIntregralCalculation(data, subIndices);

    if (!integral.success) {
      return { success: false };
    }

    const { begin, end } = totalIndices;
    const { xData, yData } = data;

    // Grab slope and intercept of line that spans the first and last points in the larger selection.
    const slope = (yData[end - 1] - yData[begin]) / (xData[end - 1] - xData[begin]);
    const intercept = yData[end - 1] - slope * xData[end - 1];

    // Use trapezoid rule to integrate the space underneath the line in region as defined by subRange.
    let area = 0.0;
    let maxY = -Number.MAX_VALUE;
    let retentionTime = 0;

    const checkYMax = i => {
      if (yData[i] > maxY) {
        maxY = yData[i];
        retentionTime = xData[i];
      }
    };

    for (let i = subIndices.begin; i < subIndices.end; i++) {
      const y1 = slope * xData[i] + intercept;
      const y2 = slope * xData[i + 1] + intercept;
      area += ((y1 + y2) * (xData[i + 1] - xData[i])) / 2.0;

      checkYMax(i);
    }

    checkYMax(subIndices.end);

    // Subtract off the baseline area.
    integral.integralValue -= area;
    return { ...integral, retentionTime, slope, intercept };
  }

  calculateStatistics(xid, yid, range, isRangeIndexBased) {
    const { dataWorld } = this;

    const xCol = dataWorld.getColumnById(xid);
    const yCol = dataWorld.getColumnById(yid);

    const undefindToNaN = v => (v === undefined ? NaN : v);
    // Hav to spread operator to change empty to undefined, then map undefined to NaN
    const xData = xCol ? [...xCol.filteredValues].map(undefindToNaN) : [];
    const yData = yCol ? [...yCol.filteredValues].map(undefindToNaN) : [];

    const dataLength = Math.min(xData.length, yData.length);

    let valid = false;
    const stats = {
      min: {
        x: Infinity,
        y: Infinity,
        index: 0,
      },
      max: {
        x: -Infinity,
        y: -Infinity,
        index: 0,
      },
      mean: 0,
      stdDev: 0,
      points: [], // points in range
    };

    const updateStatsWPoint = (x, y, index) => {
      valid = true;
      stats.points.push([x, y]);
      stats.mean += y;

      // get trace stats
      if (y < stats.min.y) {
        stats.min.x = x;
        stats.min.y = y;
        stats.min.index = index;
      }
      if (y > stats.max.y) {
        stats.max.x = x;
        stats.max.y = y;
        stats.max.index = index;
      }
    };

    const isValid = value => (typeof value === 'number' ? !Number.isNaN(value) : !!value);

    // iterate through trace values
    if (isRangeIndexBased) {
      // range min/max contain indices, not x-values
      const { min: minIndex, max: maxIndex } = range;

      for (let i = minIndex; i <= maxIndex && i < dataLength; i++) {
        const x = xData[i];
        const y = yData[i];

        if (isValid(x) && isValid(y)) {
          updateStatsWPoint(x, y, i);
        }
      }
    } else {
      // range min/max contain x-values, not indices
      for (let i = 0; i < dataLength; i++) {
        const x = xData[i];
        const y = yData[i];

        if (isValid(x) && isValid(y)) {
          // are we in range
          if (
            (x > range.min || nearlyEqual(x, range.min)) &&
            (x < range.max || nearlyEqual(x, range.max))
          ) {
            updateStatsWPoint(x, y, i);
          }
        }
      }
    }

    if (stats.points.length > 0) {
      stats.mean /= stats.points.length;

      // compute sample standard deviation
      if (stats.points.length > 1) {
        stats.points.forEach(point => {
          const y = point[1];
          const diff = y - stats.mean;
          stats.stdDev += diff * diff;
        });
        stats.stdDev = Math.sqrt(stats.stdDev / (stats.points.length - 1));
      }
    }

    return {
      stats,
      success: valid,
      error: valid ? undefined : new Error('Data points for stats calculation are invalid'),
    };
  }

  // this algorithm uses the same logic as LQ's version
  computeTangentSlope(xid, yid, xPoint) {
    const { dataWorld } = this;

    const result = {
      success: false,
      slope: 0.0,
      error: null,
    };

    const pointsInSlopeCalc = 7; // for now use 7 points to calculate tangent slope, like default derivative
    const maxOffset = (pointsInSlopeCalc - 1) / 2;

    const ascendingXPointPairs = (a = [], b = []) => a[0] - b[0];

    const yCol = dataWorld.getColumnById(yid);
    const xCol = dataWorld.getColumnById(xid);

    const rawYData = yCol ? yCol.filteredValues : [];
    const rawXData = xCol ? xCol.filteredValues : [];

    let traceData;
    traceData = makePoints(rawXData, rawYData).sort(ascendingXPointPairs);
    traceData = traceData.filter(p => Number.isFinite(p[0]) && Number.isFinite(p[1]));
    // eslint-disable-next-line no-param-reassign
    traceData = traceData.filter((p, i, arr) => i === 0 || p[0] !== arr[--i][0]); // ensure we only have one point per x-value

    const center = traceData.findIndex(pair => nearlyEqual(pair[0], xPoint));

    if (center !== -1 && traceData.length >= 2) {
      let totalWeight = 0.0;

      // loop through the pairs of points framing the center point
      for (let offset = 1; offset <= maxOffset; offset++) {
        const leftIndex = Math.max(center - offset, 0);
        const rightIndex = Math.min(center + offset, traceData.length - 1);
        const left = traceData[leftIndex];
        const right = traceData[rightIndex];

        // point pairs closer to the center point get more weight
        const weight = maxOffset - (offset - 1);
        const deltaX = right[0] - left[0];
        const deltaY = right[1] - left[1];

        if (deltaX !== 0) {
          const slopeContribution = deltaY / deltaX;
          result.slope += weight * slopeContribution;
          totalWeight += weight;
        } else {
          result.error = new Error(`Cannot compute tangent with a delta-x of zero`);
          return result;
        }
      }

      if (totalWeight >= 1) {
        result.slope /= totalWeight;
        result.success = true;
        return result;
      }

      result.error = new Error(`Not enough data included for valid tangent slope calculation`);
      return result;
    }

    result.error = new Error(
      `Unable to calculate tangent slope. Either unable to find correct base column point or insufficient data.`,
    );
    return result;
  }

  generateTangentTraceData(xid, yid, xPoint, slope, axis, graphProps) {
    const { accessibilityScale, getAxis } = graphProps;
    const { dataWorld } = this;

    const baseAxis = getAxis('base');
    const yAxis = getAxis(axis);

    const yCol = dataWorld.getColumnById(yid);
    const xCol = dataWorld.getColumnById(xid);

    const rawYData = yCol ? yCol.filteredValues : [];
    const rawXData = xCol ? xCol.filteredValues : [];

    const index = rawXData.findIndex(x => nearlyEqual(x, xPoint));

    // compute graph ranges, diagonal length, and slope in terms of pixels
    const graphBaseRange = baseAxis.range;
    const graphLeftRange = yAxis.range;

    const graphBaseRangePx = baseAxis.rangePx;
    const graphLeftRangePx = yAxis.rangePx;

    const [graphWidth, graphHeight] = [graphBaseRange, graphLeftRange].map(
      range => range.max - range.min,
    );
    const [graphPxWidth, graphPxHeight] = [graphBaseRangePx, graphLeftRangePx].map(
      range => range.max - range.min,
    );

    const verticalPxPerPoint = graphPxHeight / graphHeight;
    const horizontalPxPerPoint = graphPxWidth / graphWidth;
    const slopeInPx = slope * (verticalPxPerPoint / horizontalPxPerPoint);

    const graphPxDiagonal = Math.sqrt(graphPxWidth ** 2 + graphPxHeight ** 2);

    if (index !== -1 && graphWidth > 0 && graphHeight > 0 && !Number.isNaN(slope)) {
      const yPoint = rawYData[index];

      const xPointPx = baseAxis.p2c(xPoint);
      const yPointPx = yAxis.p2c(yPoint);

      const tangentLinesInfo = {
        shortLine: {
          lineWidthFactor: 4,
          pxLength: 42 * accessibilityScale,
        },
        longLine: {
          lineWidthFactor: 1,
          pxLength: 2 * graphPxDiagonal, // long line always extends to the graph edges
        },
      };

      // generate and set data for both short and long tangent line
      const tangentLines = Object.values(tangentLinesInfo).map(line => {
        const slopeAngle = Math.atan(slopeInPx);
        const xPxFromCenter = Math.cos(slopeAngle) * (line.pxLength / 2);
        const yPxFromCenter = Math.sin(slopeAngle) * (line.pxLength / 2);

        const lineDataPx = [
          [xPointPx - xPxFromCenter, yPointPx - yPxFromCenter],
          [xPointPx + xPxFromCenter, yPointPx + yPxFromCenter],
        ];

        const lineData = lineDataPx.map(point => [baseAxis.c2p(point[0]), yAxis.c2p(point[1])]);

        return {
          data: lineData,
          lineWidthFactor: line.lineWidthFactor,
        };
      });

      return {
        // tangent trace object
        axis,
        tangentLines,
      };
    }

    return null;
  }

  /**
   * Converts sparse match array data to a full array of data.
   * @param {Array<number>} baseData Match base data
   * @param {Array<number>} traceData Match trace data
   * @param {Array<number>} timeData Full time column of students's graph match.
   * @returns {Array<number>} An array whose length is equal to timeData, and
   * whose values correspond to the traceData's profile.
   */
  static _matchDataToArray(baseData, traceData, timeData) {
    if (baseData.length !== traceData.length) {
      console.error(
        `Match baseData length (${baseData.length}) must equal traceData length (${traceData.length})`,
      );
      return [];
    }

    let matchIndex = 0;
    let slope = 0;
    let intercept = 0;

    const resultTraceData = timeData.map((currentTime, index) => {
      // We'll re-compute slope and intercept if this is the first pass through
      // this loop OR if the currentTime falls within the next leg of the
      // match data.
      const initialPass = index === 0;
      const bumpMatchIndex = currentTime > baseData[matchIndex + 1];

      if (initialPass || bumpMatchIndex) {
        if (bumpMatchIndex) matchIndex++;

        const x1 = baseData[matchIndex];
        const x2 = baseData[matchIndex + 1];
        const y1 = traceData[matchIndex];
        const y2 = traceData[matchIndex + 1];

        slope = (y2 - y1) / (x2 - x1);
        intercept = y1 - slope * x1;
      }

      return currentTime * slope + intercept;
    });

    return resultTraceData;
  }

  /**
   * Computes graph match RMSE.
   * @param {string} timeColumnId Id for Time column from student data
   * @param {string} positionColumnId Id for student's Position / Velocity
   * @returns {number} the computed RMSE value, or NaN if the value could not
   * be computed (see console for additional info).
   */
  computeGraphMatchRMSE(timeColumnId, positionColumnId) {
    const [matchDataSet] = this.dataWorld.graphMatches;
    if (!matchDataSet) return NaN;
    const matchColumns = matchDataSet.columnIds.map(colId => this.dataWorld.getColumnById(colId));
    const matchBaseData = matchColumns.find(column => column.prefersBase)?.filteredValues ?? [];
    const matchTraceData = matchColumns.find(column => !column.prefersBase)?.filteredValues ?? [];

    // Extract filtered data from each array.
    const [timeData, positionData] = [timeColumnId, positionColumnId].map(
      colId => this.dataWorld.getColumnById(colId)?.filteredValues ?? [],
    );

    // Generate an array of data with the match profile at each value in
    // the timeData array.
    const matchData = DataAnalysis._matchDataToArray(matchBaseData, matchTraceData, timeData);

    // Note: Velocity data sometimes has trailing NaN's at the end. Overall, the
    // lengths of each array should not differ by more than 2 or 3 elements.
    const smallestLength = Math.min(timeData.length, positionData.length, matchData.length);
    let rmse = 0;
    let count = 0;

    for (let i = 0; i < smallestLength; i++) {
      if (
        Number.isFinite(timeData[i]) &&
        Number.isFinite(matchData[i]) &&
        Number.isFinite(positionData[i])
      ) {
        const diff = matchData[i] - positionData[i];
        rmse += diff * diff;
        count++;
      }
    }

    return count > 0 ? Math.sqrt(rmse / count) : NaN;
  }
}
