/* eslint-disable max-classes-per-file */
/**
 * Represents a collection of methods which get called in response to various replay timer events.
 */
export class ReplayTimerDelegate {
  constructor() {
    /**
     * Called when replay begins.
     */
    this.replayStarted = () => {};

    /**
     * Called during replay when the replay time exceeds the `videoDelay` value. Indicates when video playing should begin.
     * @param {*} startTime Time relative to the video's t=0 at which to begin playing.
     * @param {*} rate Rate at which video playback should proceed.
     */
    this.startVideo = () => {};

    /**
     * Called approximately every `reportingTimeInterval` to report the current replay time.
     * @param {*} timeStamp Current replay time stamp, relative to the beginning of experiment's t=0.
     */
    this.reportTimestamp = () => {};

    /**
     * Called when replay ends.
     */
    this.replayEnded = () => {};
  }
}

export class ReplayTimer {
  // TODO: all timingInfo params should clearly contain times corresponding to the data, *not* the run-time playback times
  /**
   * Constructs an instance of ReplayTimer
   * @param {object} delegate Block of event handler methods
   * @param {object} timingInfo object containing timing information
   * @param {number} timingInfo.replayDuration Total length of data to replay in seconds
   * @param {number} timingInfo.videoDelay Time in seconds to wait until `startVideo` delegate method is called. If this value is negative, video playback will begin immdediately and `reportTimestamp` handler will receive negative values counting up through this duration.
   * @param {number} timingInfo.replayRate Playback rate. I.e. 2 will playback twice as fast, 1/2 will playback halfspeed. Negative rates are not yet supported.
   *
   * @param {number} timingInfo.dataInterval Requested interval (with respect to the *data*) at which replay data is updated.
   * Practically, this is the requested difference between arguments of successive 'reportTimestamp' delegate calls.
   *
   @param {number} timingInfo.startTime Time in seconds at which to begin play back.
   */
  constructor(delegate, timingInfo) {
    const {
      replayDuration = 10,
      videoDelay = 0,
      replayRate = 1,
      dataInterval = 0.05,
      startTime = 0.0,
    } = timingInfo;

    this.videoStartDelay = videoDelay;
    this.replayRate = replayRate;
    this.reportingInterval = dataInterval / replayRate;
    this.duration = replayDuration;
    this.startTime = startTime;
    this.delegate = delegate;
  }

  /**
   * Starts replay timer from starting time.
   */
  start() {
    if (!this.timer) {
      const now = Date.now();
      this._playbackTime =
        now - (this.videoStartDelay < 0 ? (this.videoStartDelay * 1000) / this.replayRate : 0);
      this._scheduleTimer();
      this.delegate.replayStarted();
      this._serviceTime();
    }
  }

  /**
   * Stops replay timer.
   */
  stop() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  seekTime(newTimeStamp) {
    this.startTime = newTimeStamp;
    if (this.timer) {
      this.stop();
      this.start();
    }
  }

  _scheduleTimer() {
    this.timer = setTimeout(() => {
      if (this._serviceTime()) {
        this._scheduleTimer();
      }
    }, this.reportingInterval * 1000);
  }

  _now() {
    const elapsedTime = (Date.now() / 1000.0 - this._playbackTime / 1000) * this.replayRate;
    const reportedTime = this.startTime + elapsedTime;
    return { elapsedTime, reportedTime };
  }

  get currentTimestamp() {
    return this._now().reportedTime;
  }

  _serviceTime() {
    let shouldContinue = true;
    const { elapsedTime, reportedTime } = this._now();
    this.delegate.reportTimestamp(reportedTime);

    if (!this._videoStarted && (this.videoStartDelay < 0 || reportedTime >= this.videoStartDelay)) {
      this.delegate.startVideo(reportedTime - this.videoStartDelay, this.replayRate);
      this._videoStarted = true;
    }

    if (elapsedTime >= this.duration - this.startTime) {
      this.delegate.replayEnded();
      this.stop();
      shouldContinue = false;
    }
    return shouldContinue;
  }
}
