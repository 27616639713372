// Events:

// 'before-session-stared'
// 'session-started'
// 'before-session-ended'
// 'session-ended'

// 'collection-preparing'
// 'collection-started'
// 'collection-stopped'

// 'dataset-added'
// 'dataset-removed'

// 'column-added'
// 'column-removed'

// 'imported-graph-state-ready': param dictionary

// 'collection-control-changed':   boolean    (datashare's canControl)
// 'collection-threshold-reached'
// 'collection-prestore-reached'

// The meaning of these are defined by the Session instance
// For DataShare, this represents the connection to the DataShare server
// 'session-connection-error':  error
// 'session-remote-id-changed'

// Convenience Events, for syncing UI
// 'dataset-name-changed': dataSet, name

// 'column-name-changed':   column, name
// 'column-units-changed':  column, units
// 'column-values-changed': column, values

// Auto-Save events
// 'auto-save-completed'
// 'auto-save-failed'

// Misc events:
// 'session-subtype-changed'
// 'user-constants-updated'
// 'rpc-time-warning': rpcId, duration, threshold.

// Meter events:
// 'meter-added'
// 'meter-removed'

import EventEmitter from 'eventemitter3';
import { uniqueId, isUndefined, throttle, unescape, debounce } from 'lodash-es';

import { Column, ColumnDataType } from '@api/common/Column.js';
import { ColumnGroup } from '@api/common/ColumnGroup.js';
import { DataSet } from '@api/common/DataSet.js';
import { Spectrum } from '@api/common/Spectrum.js';

import { eventHandlers } from '@services/dataworld/DataWorld.handlers.js';
import { createSession } from '@services/session/sessionFactory.js';
import { DataCollectionSession } from '@services/session/DataCollectionSession.js';
import { DataShareSession } from '@services/session/DataShareSession.js';
import { ManualSession } from '@services/session/ManualSession.js';
import { EventBinder } from '@utils/EventBinder.js';
import { formatter } from '@utils/formatter.js';
import { getPointSymbolEnum } from '@utils/pointSymbolEnumHelpers.js';
import { sprintf } from '@libs/sprintf.js';
import { autorun } from '@utils/betterAutorun.js';

import { VstEquationParser } from '@common/services/equation/VstEquationParser.js';
import { makeObservable, observable, action, runInAction } from 'mobx';

import {
  findNextMeterPosition,
  incrementMeterPosition,
  decrementMeterPosition,
  MeterPositionCompare,
} from '@utils/meterListHelpers.js';

import {
  getDefaultType,
  getMimeType,
  isKnownTextFormat,
  getExtensionFromPath,
  readFileAsArrayBuffer,
  readFileAsText,
} from '@utils/fileio-helpers.js';
import { getText, isLocaleRationalNumber, parseFloatLocale } from '@utils/i18n.js';
import { createMeasurementFromUdm } from '@common/mobx-stores/Measurement.js';
import { Meter } from '../../mobx-stores/Meter.js';
import { DataMark } from '../../mobx-stores/DataMark.js';
import { Annotation, AnnotationType } from '../../mobx-stores/Annotation.js';
import { ManualFit } from '../../mobx-stores/ManualFit.js';

const AUTOSAVE_POLL_MS = 5000;
const DEFAULT_FILE_NAME = 'Untitled';

/**
 * @typedef UserMetaData
 * @property {string} filepath path of current document.
 * @property {number} age udm document age set at file new / file open session
 * start
 */

// function _setColNameIndex(dataSetId, columns, column) {
//     let colName = column.name.replace(/\s\d+$/, '');
//     let dupes = columns.where({ // get all dupe names within the current data set
//         name: colName,
//         setId: dataSetId
//     });
//
//     if (dupes.length > 1) {
//         dupes.forEach((col, i) => {
//             let index = i+1;
//             col.nameId = `${index}`;
//         });
//     }
//     else if (dupes.length === 1) {
//         if (dupes[0].nameId !== '') {
//             dupes[0].nameId = '';
//         }
//     }
// }

function _getTimeFormatStr(timeUnits, dataCollection) {
  const delta = formatter.convertTimeUnits(dataCollection.timeBasedParams.delta, 's', timeUnits);
  let formatStr = '%.0f';

  if (delta > 0 && delta < 1) {
    const precision = Math.ceil(Math.abs(Math.log10(delta)));
    formatStr = `%.${precision}f`;
  }

  return formatStr;
}

function toUdmId(id) {
  return parseInt(id, 10);
}

function _getTimeFormatPrecision(timeUnits, dataCollection) {
  const delta = formatter.convertTimeUnits(dataCollection.timeBasedParams.delta, 's', timeUnits);
  const precision = delta > 0 && delta < 1 ? Math.ceil(Math.abs(Math.log10(delta))) : 0;

  return precision;
}

/**
 * Get the data set's unique identifying number that's been included in its name
 * @private
 * @param {DataSet}
 * @returns {number|NaN}
 */
function _getDataSetNumberViaMatch(dataSet) {
  const [, translatedNameStem] = getText('Data Set %d').match(/^(.*)%d/); // for example, this would be 'Datensatz ' in German
  const regExpForSetNumber = new RegExp(`^${translatedNameStem}(\\d*)`); // for example, /^Datensatz (\d*)/ in German

  const [, setNumber] = dataSet.name.match(regExpForSetNumber) || [];
  return Number.parseInt(setNumber);
}

export class DataWorld extends EventEmitter {
  constructor(config) {
    super();
    this.api = config.api;

    Object.keys(eventHandlers).forEach(key => this.api.on(key, eventHandlers[key].bind(this)));

    this._queueDispatchDataColumnValuesSettled = debounce(() => {
      this.emit('data-column-values-settled');
    }, 250);
    this._userConstants = [];
    this._blockSynced = false;
    this._isCollecting = false;
    this.session = null; // active session
    this.sensorWorld = config.sensorWorld;
    this.dataCollection = config.dataCollection;
    this.udm = config.udm;
    this.power = config.power;
    this.appManifest = config.appManifest;
    this.popoverManager = config.popoverManager;
    this.automation = config.automation;
    this.ignoreNameChanges = false;
    this.timeUnits = null;
    this._getTimeFormatStr = _getTimeFormatStr; // add this to the object so DataWorld.handlers can access it
    this._getTimeFormatPrecision = _getTimeFormatPrecision;
    this.keepAwakeTimeout = null;

    // used for auto-saving
    this.autoSavePending = false;
    this.autoSaveTimer = null;

    // TODO: disable on fast collection…
    this.enableAutoSave = true;

    // TODO we should probably protect these arrays and only have a getter on them
    this.dataSets = [];
    this.columns = [];
    this.columnGroups = [];
    this.meters = [];
    this.spectrums = [];
    this._measurementTools = [];
    this._graphInfos = {};
    this._graphIdMap = [];

    this.columnGroupIndices = {};
    this._userFileMetaData = {
      filepath: '',
      age: 0,
    };

    this._sessionSubtype = '';
    this._specialDataSetTransactionCounter = 0;

    // For tracking the arrival of base/trace columns
    // in special datasets on import; keyed by dataset id
    this.seenBaseCol = {};
    this.seenTraceCol = {};

    this.videoAttachmentImportHook = false;

    // workaround for connection syncing
    this._overrideExperimentId = null;

    this.dataCollection.on('start-measurements-failed', () => {
      this._notifyCollectionStopped();
    });

    // this is a kluge until we have change units
    this.dataCollection.on('time-units-changed', units => {
      const timeCol = this.columnGroups.find(group => group.type === 'time'); // there should only be one
      if (timeCol) {
        timeCol.units = units;
        const firstCol = timeCol.columns[0];
        if (firstCol) {
          // send the change unit off to the back end
          this.changeColumnUnit(firstCol.id, units);
        }
      }
      this.timeUnits = units; // set this for future runs
    });

    this.dataCollection.on('collection-params-changed', params => {
      if (params.mode === 'time-based' && this.isSessionEmpty) {
        const timeColGroup = this.columnGroups.find(group => group.type === 'time'); // this will return the first time column, but there should only be one in this case
        if (timeColGroup) {
          const precision = _getTimeFormatPrecision(this.timeUnits, this.dataCollection);

          timeColGroup.precision.update({
            precision,
          });
        }
      }

      const passedParams = params.params;

      if (passedParams && passedParams.triggering) {
        this.isTriggeringEnabled = passedParams.triggering.enabled;
        this._isRemoteLoggingEnabled = passedParams.remoteLogging;
      }
    });

    this.dataCollection.on('device-detached', () => {
      if (this.isCollecting) {
        this.stopCollection();
      }
    });

    this.currentDataSet = null;

    // Set up mobx observable properties.
    makeObservable(this, {
      _dataMarks: observable,
      manualFits: observable,
      meters: observable,
      annotations: observable,
      _measurementTools: observable,

      addDataMark: action,
      removeDataMark: action,
      removeGraphManualFit: action,
      _internalCreateDataMark: action,
      _addManualFit: action,
      _cleanupSession: action,
      _internalRegisterAnnotation: action,
      addMeter: action,
      removeMeter: action,
      setMeterPosition: action,
      incrementMeterPosition: action,
      decrementMeterPosition: action,
      addMeasurementTool: action,
      removeMeasurementTool: action,
      _internalRegisterMeasurementTool: action,
    });

    // Useful for debugging meters.
    // autorun(() => {
    //   console.log(`--- Meter list was mutated. New count: ${this.meters.length}`);
    //   this.meters.forEach(m => {
    //     const col = this.getColumnById(m.columnId) ?? { name: '<unknown>' };
    //     console.log(
    //       `\t--- Meter ${m.id} for column ${m.columnId} (${col.name}), position ${m.position}, vis ${m.isVisibleInMeterPane}`,
    //     );
    //   });
    // });
  }

  get _blockSynced() {
    return this.__blockSynced;
  }

  set _blockSynced(value) {
    this.__blockSynced = value;
    this.emit('block-synced', value);
  }

  get blockSynced() {
    if (this.__blockSynced || !window.__isSessionClient) return Promise.resolve(true);
    return new Promise(resolve => {
      this.once('block-synced', value => resolve(value));
    });
  }

  get isCollecting() {
    return this._isCollecting;
  }

  set isCollecting(value) {
    this._isCollecting = value;
    this.emit('is-collecting-changed', value);
  }

  get predictions() {
    return this.getSpecialDataSets().filter(set => set.type === 'prediction');
  }

  get graphMatches() {
    return this.getSpecialDataSets().filter(set => set.type === 'graph-match');
  }

  get experimentId() {
    if (this._overrideExperimentId) return this._overrideExperimentId;
    return this.session?.experimentId;
  }

  setFileIO(fileIO) {
    this.fileIO = fileIO;
  }

  getSession() {
    return this.session;
  }

  _deleteAnnotationsWithNoData() {
    const annotationsToDelete = new Set();
    this.annotations.forEach(annotation => {
      if (
        annotation.type === AnnotationType.POINT &&
        annotation.targetRecords.every(targetRecord => {
          const column = this.getColumnById(targetRecord.columnId);
          const value = column.values[targetRecord.pointIndex];
          return Column.isValueEmpty(value);
        })
      ) {
        annotationsToDelete.add(annotation);
      }

      if (
        annotation.type === AnnotationType.RANGE &&
        annotation.targetRecords.every(targetRecord => {
          const column = this.getColumnById(targetRecord.columnId);
          const values = column.values.slice(
            targetRecord.rangeStartIndex,
            targetRecord.rangeEndIndex + 1,
          );
          return values.every(Column.isValueEmpty);
        })
      ) {
        annotationsToDelete.add(annotation);
      }
    });
    annotationsToDelete.forEach(annotation => this.removeGraphAnnotation(annotation));
  }

  setupSession() {
    try {
      console.warn(
        'FIXME: remove this function -- components need to handle session-started event',
      );

      if (!this.importing) {
        if (this.session.setupSessionFile) {
          this.session.setupSessionFile();
        }
      } else {
        this.importing = false;
      }
    } catch (error) {
      console.error('Failed to setup Session File');
      console.error(error);
    }
  }

  getColumnGroupIndex(type) {
    const indexCache = this.columnGroupIndices;
    const value = !isUndefined(indexCache[type]) ? indexCache[type] : 1;
    return value;
  }

  _incrementColumnGroupIndex(type) {
    const value = this.getColumnGroupIndex(type) + 1;
    const indexCache = this.columnGroupIndices;
    indexCache[type] = value;
    return value;
  }

  // This is a handler for the dw:session-started notification
  async sessionStarted(params) {
    console.assert(this.sessionConfig);

    this.sessionConfig.fileFormat = params.fileFormat;

    // Fetch the document user constants before we try to use them
    this._userConstants = await this.api.getUserConstants(this.experimentId);

    // on import, we need to parse and update all custom calc columns
    if (this.sessionConfig.imported) {
      const customGroups = this.columnGroups.filter(group => group.customEq);
      const updateFuncs = customGroups.map(group => {
        return this.updateEquation(group, null, true);
      });

      await Promise.all(updateFuncs);
    }

    this.emit('session-started', this.sessionConfig);

    // Set the age of the document so that any subsequent user changes will
    // trigger the "Save file?" dialog.
    this.resetDocumentAge();

    // if this file is a native file type, we want to retain this path. If however,
    // sessionFileFullPath is undefined here (new clean session) we need to set
    // it to an empty string so that the previous one will be cleared out
    const filepath = this.sessionConfig.sessionFileFullPath || '';
    this._updateUserFileMetaData(filepath);
  }

  async resetSession(_sessionType) {
    this.startNewSession(_sessionType, undefined, undefined, true);
  }

  /**
   * Resolves the session object
   * @param {string} _sessionType
   * @param {SessionConfig} [_sessionConfig]
   * @param {string} [_sessionSubtype]
   * @return {Promise<DataCollectionSession | DataShareSession | ManualSession>} Promise that resolves with a session
   */
  async startNewSession(
    _sessionType = 'DataCollection',
    _sessionConfig = { type: _sessionType },
    _sessionSubtype = '',
    skipCheck,
  ) {
    let session;
    let sessionType = _sessionType;
    let sessionSubtype = _sessionSubtype;
    const continueNewSession = await this._confirmNewSession();
    if (!continueNewSession) {
      return session;
    }

    let sessionConfig = { ..._sessionConfig };
    if (this.udm.isBlock && this.sessionConfig) {
      sessionConfig = this.sessionConfig ?? sessionConfig;
      sessionType = this.sessionConfig?.sessionType ?? sessionType;
      sessionSubtype = this.sessionConfig?.sessionSubtype ?? sessionSubtype;
    }
    const { sensorWorld } = this;
    const ignoreSensors =
      sessionConfig.ignoreSensors ||
      sessionConfig.type === 'manual' ||
      sessionConfig.type === 'DataShare';

    const _createSession = async () => {
      if (!skipCheck) console.assert(!this.session);
      const { udm } = this;

      try {
        this.session = await createSession({ dataWorld: this, udm }, sessionType, sessionConfig);
        this.sessionName = sessionConfig?.sessionName || getText(DEFAULT_FILE_NAME);

        if (this.session) {
          // Fetch custom calc column function list as needed to initialize parser class function list.
          if (VstEquationParser.functionList.length === 0) {
            const funcs = await this.getCalcColumnFunctions();
            VstEquationParser.initializeFunctions(funcs);
          }

          sessionConfig.initialDataSetName = sprintf(getText('Data Set %d'), 1);

          // Store the file in the session config, and cache the config for
          // use by the sessionStarted() callback
          sessionConfig.sessionType = sessionType;
          sessionConfig.sessionSubtype = sessionSubtype;
          this.sessionSubtype = sessionSubtype;
          this.sessionConfig = sessionConfig;

          this.emit('before-session-started', sessionConfig);

          // We cannot save the session until it's setup in the backend
          await sensorWorld.setIgnoreAddedSensors(this.experimentId, ignoreSensors);
          await this.session.start(sessionConfig);
        }
      } catch (err) {
        this.session = null;
        console.error(err);
      }

      return this.session;
    };

    this.emit('before-session-ended', sessionConfig);

    if (!this.udm.isBlock) await this.closeSession();

    session = await _createSession();

    if (sessionConfig.imported) {
      try {
        const result = await this.getPageAttributes();
        this.emit('view-changed', result.attributes);
      } catch (err) {
        throw new Error(err);
      }
    }
    return session;
  }

  promptCollectingConfirmNewSession() {
    return new Promise(resolve => {
      const detail = {
        resolution: resolve,
        appName: this.appManifest.getAppName(),
      };
      this.emit('prompt-collecting-confirm-new-session', detail);
    });
  }

  async _confirmNewSession() {
    if (this.isCollecting && this.sessionType === 'DataCollection') {
      const confirmation = await this.promptCollectingConfirmNewSession();
      if (confirmation.confirmed) this.stopCollection();
      return confirmation;
    }

    return true;
  }

  async checkForSave() {
    try {
      const newSession = await this._confirmNewSession();
      if (newSession.cancelled) return { cancelled: true };

      const documentAge = await this.udm.getDocumentAge(this.experimentId);
      if (documentAge !== this.userFileMetaData.age && !this.isSessionEmpty) {
        return { save: true };
      }
      return {};
    } catch (error) {
      return { error };
    }
  }

  // call this before finshing closing the session
  _cleanupSession() {
    if (this.session) {
      this.columnGroupIndices = {};

      // copy model objects
      const models = [].concat(this.dataSets, this.columns, this.columnGroups);

      // make sure we remove any columns created outside of the DataStore
      this.dataSets.forEach(dataSet => {
        this.removeInternalDataSet(dataSet.id);
      });

      this.meters = [];
      this.columns = [];
      this.dataSets = [];
      this.columnGroups = [];
      this.spectrums = [];

      this._graphInfos = {};
      this._graphIdMap = [];

      this.currentDataSet = null;
      this.autoSavePending = false;
      this.autoSaveTimer = null;
      this._sessionSubtype = '';
      this.sessionConfig = undefined;

      this._overrideExperimentId = null;

      // Stop all autorun functions.
      Object.values(DataWorld._autorunStopFunctions).forEach(obvs => obvs());
      DataWorld._autorunStopFunctions = {};
      this._dataMarks = [];
      this.manualFits.length = 0;
      this.annotations.length = 0;
      this._measurementTools.length = 0;

      // clean up events
      models.forEach(model => model.off());

      this.isCollecting = false;
      this.emit('session-ended');
    }
  }

  get isSessionEmpty() {
    const dataSets = this.getAllDataSets();
    let isEmpty = false;
    let columns;

    if (dataSets.length < 2 && dataSets[0]) {
      columns = dataSets[0].columnIds;
      isEmpty = true;
      for (let i = 0; i < columns.length && isEmpty; ++i) {
        const column = this.getColumnById(columns[i]);
        isEmpty = column.values.length === 0 && !column.frozen;
      }
    } else if (dataSets.length === 0) {
      // sometimes when in transition from one session to another there are no dataSets
      isEmpty = true;
    }

    return isEmpty;
  }

  isCurrentDataSetEmpty(onlyCheckSensorColumns) {
    if (!this.currentDataSet) {
      return true;
    }

    const curColumnIds = this.currentDataSet.columnIds;

    function columnEmpty(col) {
      let empty = col.values.length === 0;
      if (!empty) {
        empty = col.values.every(v => Number.isNaN(v) || v === null);
      }
      return empty;
    }

    const isEmpty = curColumnIds
      .map(id => this.getColumnById(id))
      .filter(col => (onlyCheckSensorColumns ? col.type === 'sensor' : true))
      .every(col => columnEmpty(col));

    return isEmpty;
  }

  closeSession() {
    const cleanupSession = () => {
      try {
        this._cleanupSession();
      } catch (error) {
        console.error('Error cleaning up session:');
        console.error(error);
      }
    };

    return new Promise((_resolve, _reject) => {
      if (!this.session) {
        // if we don't have a session to close, just resolve
        _resolve();
      }

      const eventBinder = new EventBinder();
      const resolve = () => {
        cleanupSession();
        eventBinder.unbindAll();
        this.sessionClosing = false;
        this.session = null;
        _resolve.apply(this, arguments); // eslint-disable-line
      };
      const reject = () => {
        cleanupSession();
        eventBinder.unbindAll();
        this.sessionClosing = false;
        this.session = null;
        _reject.apply(this, arguments); // eslint-disable-line
      };

      const { session } = this;

      clearTimeout(this.autoSaveTimer);
      this.autoSaveTimer = null;

      (async () => {
        if (session) {
          this.sessionClosing = true;
          try {
            await session.stop();

            // At this point the experimentId is no longer valid
            // (the experiment object has been destroyed on the NM side), so
            // clear it
            const oldExperimentId = session.experimentId;
            session.experimentId = 0;

            this.emit('session-closing');

            // sensors need to be cleared out with the sessionClosing flag set
            // to avoid calling into the backend APIs.
            this.sensorWorld.clearExperiment(oldExperimentId);

            // if there is an active auto-save, wait for it to complete
            resolve();
          } catch (error) {
            console.error(error);
            reject();
          }
        } else {
          resolve();
        }
      })();
    });
  }

  async updateEquation(group, equationString = null, suppressUpdate = false) {
    const equStr = equationString || group.customEq;

    const parsed = this.parseCustomEquation(equStr);
    await this.updateColumnGroup(group.id, {
      calcDependentGroups: group.calcDependentGroups,
      calcCoefficients: group.calcCoefficients,
      calcEquation: group.calcEquation,
      calcCustomEq: {
        equStr,
        parsed,
      },
      suppressUpdate,
    });
  }

  startCollection(params) {
    const session = this.getSession();
    if (session && typeof session.startCollection === 'function') {
      this._notifyCollectionTriggered();
      if (!this.dataCollection.isRemoteLoggingEnabled) this.power.requestWakeLock();
      session
        .startCollection(params)
        .then(() => {
          this.isCollecting = true;
        })
        .catch(err => {
          console.error(err);
          this._notifyCollectionStopped();
        });
    }
  }

  async stopCollection() {
    const session = this.getSession();

    if (session && typeof session.stopCollection === 'function') {
      return session
        .stopCollection()
        .then(() => {
          this.isCollecting = false;
        })
        .catch(() => {
          console.error('stopCollection failed');
        });
    }

    return true;
  }

  async requestAutoSave() {
    if (this.session && !this.autoSavePending && !this.isSessionEmpty) {
      this.autoSavePending = true;
      const { entry } = this.file; // TODO get file entry from fileIO
      const { age } = this.userFileMetaData;

      try {
        const result = await this.autoSaveData({
          filename: entry.name,
          filepath: entry.filepath || entry.fullPath, // fullPath is used by Chrome OS
          age,
        });

        console.assert(result.blob);

        if (APP_ID === 'VA') {
          this.autoSavePending = false;
          return true;
        }

        if (result.age > age) {
          // autosave path for platforms that need the front-end to write to disk
          await this.fileIO.writeFile({
            fileHandle: entry, // TODO: this is used in cordova and chrome
            filepath: entry.filepath, // TODO: this is used for electron
            blob: result.blob,
          });
        }
        this.autoSavePending = false;
        this.emit('auto-save-completed');
        return true;
      } catch (err) {
        this.autoSavePending = false;
        this.emit('auto-save-failed');
        return false;
      }
    }

    return Promise.resolve();
  }

  // TRIGGERING EVENT STUBS
  // DataWorld.prototype.triggerThreshold = function() { // stub
  //     this.emit('collection-threshold-reached');
  // };

  triggerPrestore() {
    // // stub
    this.emit('collection-prestore-reached');
  }

  createAutoSaveTimer() {
    const saveTimeout = () => {
      if (this.enableAutoSave) {
        // only blocking this function here because we use this to do our normal starting saves as well
        this.requestAutoSave();
      }
      this.autoSaveTimer = setTimeout(saveTimeout, AUTOSAVE_POLL_MS);
    };

    if (this.autoSaveTimer) {
      clearTimeout(this.autoSaveTimer);
    }

    this.autoSaveTimer = setTimeout(saveTimeout, AUTOSAVE_POLL_MS);
  }

  // get some metadata for all available regular columns
  // for now, just proxy the Client object
  // bind to this Container object
  // getColumns doesn't include special columns
  getColumns() {
    return this.columns.filter(c => !c.special);
  }

  getSpecialColumns() {
    return this.columns.filter(c => c.special);
  }

  getAllColumns() {
    return this.columns;
  }

  getColumnById(_columnId) {
    let columnId = _columnId;
    if (columnId !== undefined) {
      // NOTE: always convert columnId to a string because we only deal with them as strings from here on out.
      columnId = `${_columnId}`;
    }

    return this.getAllColumns().find(column => column.id === columnId);
  }

  getColumnForGroupAndSet(groupId, setId) {
    if (groupId === undefined || groupId === null) {
      return null;
    }

    // we must use '==' here, not '===', as === will not trigger the property
    // accessor, so if the property has not been acessed before we get here,
    // it will simply be undefined. In contrast, == will call the accessor for
    // if necessary.
    return this.getAllColumns().find(newCol => newCol.setId == setId && newCol.groupId == groupId); // eslint-disable-line
  }

  // TODO: remove this method in favor of using getColumnForGroupAndSet()
  getColumnForSet(column, setId = this.currentDataSet?.id) {
    if (!column) {
      return null;
    }

    // we must use '==' here, not '===', as === will not trigger the property
    // accessor, so if the property has not been acessed before we get here,
    // it will simply be undefined. In contrast, == will call the accessor for
    // if necessary.
    return this.getAllColumns().find(
      // eslint-disable-next-line eqeqeq
      newCol => newCol.setId == setId && newCol.groupId == column.groupId,
    ); // eslint-disable-line
  }

  getColumnsForSet(setId) {
    const groups = this.getColumnGroups();
    const columns = [];

    groups.forEach(group => {
      group.columns.forEach(column => {
        if (column.setId === setId) {
          columns.push(column);
        }
      });
    });

    return columns;
  }

  /**
   * Returns a list of columns contained by a data set, preserving the order in which they have been inserted.
   * @param {String} setId data set ID whose columns are to be returned
   * @returns {Array} of correctly ordered columns
   */
  getOrderedColumnsForSet(setId) {
    const dataSet = this.getDataSetByID(setId);
    if (!dataSet) return [];
    return dataSet.columnIds.map(colId => this.getColumnById(colId));
  }

  getDataSets() {
    return this.dataSets.filter(ds => ds.type === 'regular');
  }

  getSpecialDataSets() {
    return this.dataSets.filter(ds => ds.type !== 'regular');
  }

  getAllDataSets() {
    return this.dataSets;
  }

  // get special datasets of given type
  getDataSetsOfType(type) {
    return this.dataSets.filter(ds => ds.type === type) || [];
  }

  getDataSetByID(dataSetId) {
    // NOTE: always convert dataSetId to a string because we only deal with them as strings from here on out.
    return this.dataSets.find(ds => ds.id === `${dataSetId}`);
  }

  getDataSetByForeignId(foreignDataSetId) {
    // NOTE: always convert dataSetId to a string because we only deal with them as strings from here on out.
    return this.dataSets.find(ds => ds.foreignId === `${foreignDataSetId}`);
  }

  getColumnGroupById(groupId) {
    return this.columnGroups.find(group => group.id === groupId);
  }

  /**
   * @returns [ColumnGroup[]] - array of all column groups
   */
  getColumnGroups() {
    return this.columnGroups;
  }

  getPageAttributes(params = {}) {
    return this.api.getPageAttributes({ experimentId: this.experimentId, ...params });
  }

  async setExperimentName(name) {
    try {
      if (await this.archive) {
        await this.archive.setExportName(name);
      }

      this.sessionName = name;
      return this.api.setExperimentName({ experimentId: this.experimentId, name });
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  calculateSparkLine(baseColId, traceColId, pixelWidth) {
    return this.api.calculateSparkLineForColumn({
      experimentId: this.experimentId,
      baseColId: toUdmId(baseColId),
      traceColId: toUdmId(traceColId),
      pixelWidth,
    });
  }

  // There should be only one DataShareSource in the document, so
  // the sourceId can be omitted; however, if the sourceId is known,
  // then the data retrieval is more efficient
  getDataShareSource(_experimentId, _sourceId) {
    const experimentId = _experimentId || this.experimentId;
    const sourceId = parseInt(_sourceId);
    return this.api.getDataShareSource({ experimentId, sourceId });
  }

  /**
   * Update the name of a data set to be it's default name followed by a passed suffix
   * @param {string} setId
   * @param {string} nameSuffix The string to append to the default name
   */
  applyDataSetNameSuffix(setId, nameSuffix = '') {
    const dataSet = this.getDataSetByID(setId) || {};
    const dsNumber = _getDataSetNumberViaMatch(dataSet);

    if (dsNumber) {
      const defaultName = sprintf(getText('Data Set %d'), dsNumber);
      this.updateDataSet(setId, { name: `${defaultName}${nameSuffix}` });
    }
  }

  /**
   * Get a unique name containing the next availible set number for a regular data set
   * @private
   * @returns {string}
   */
  _getNextRegularSetName() {
    const regularSetCount = this.dataSets.filter(ds => ds.type === 'regular').length;
    const dataSetNumbers = this.dataSets
      .map(ds => _getDataSetNumberViaMatch(ds))
      .filter(num => Number.isFinite(num));

    const nextDataSetNumber = Math.max(...dataSetNumbers, regularSetCount) + 1;
    return sprintf(getText('Data Set %d'), nextDataSetNumber);
  }

  createNewDataSet(params = {}) {
    const properties = { experimentId: this.experimentId, ...params };

    if (properties.foreignId) {
      properties.foreignId = toUdmId(params.foreignId);
    }

    if (!properties.name) {
      properties.name = this._getNextRegularSetName();
    }

    if (properties.nameTag) {
      properties.name = `${properties.name} ${properties.nameTag}`;
    }

    return this.api.createNewDataSet(properties);
  }

  createNewColumn(params = {}) {
    const properties = { experimentId: this.experimentId, ...params };

    if (params.foreignId) {
      properties.foreignId = toUdmId(params.foreignId);
    }
    if (params.foreignGroupId) {
      properties.foreignGroupId = toUdmId(params.foreignGroupId);
    }
    if (params.foreignSetId) {
      properties.foreignSetId = toUdmId(params.foreignSetId);
    }

    return this.api.createNewColumn(properties);
  }

  createManualColumnGroup({
    name,
    units,
    precision,
    automaticPrecision,
    sourceColumnId,
    dataType = ColumnDataType.NUMERIC,
    errorBarColumnId,
    errorBarType,
    errorBarValue,
    metered = false,
  } = {}) {
    return this.api
      .createManualColumnGroup({
        experimentId: this.experimentId,
        name,
        unit: units,
        precision,
        automaticPrecision,
        previousId: toUdmId(sourceColumnId),
        dataType,
        errorBarColumnId,
        errorBarType,
        errorBarValue,
        metered,
      })
      .then(result => {
        this._incrementColumnGroupIndex('manual');
        const columnGroup = this.getColumnGroupById(result.groupId);

        return columnGroup;
      });
  }

  createCalcColumnGroup({
    name,
    units,
    precision,
    automaticPrecision,
    sourceColumnId,
    calcEquation,
    calcDependentGroups,
    calcCoefficients,
    calcCustomEq,
    errorBarColumnId,
    errorBarType,
    errorBarValue,
    metered = false,
  } = {}) {
    return this.api
      .createUserCalcColumnGroup({
        experimentId: this.experimentId,
        name,
        unit: units,
        previousId: toUdmId(sourceColumnId),
        precision,
        automaticPrecision,
        calcEquation,
        calcDependentGroups,
        calcCoefficients,
        calcCustomEq,
        errorBarColumnId,
        errorBarType,
        errorBarValue,
        metered,
      })
      .then(result => {
        this._incrementColumnGroupIndex('calc');
        const columnGroup = this.getColumnGroupById(result.groupId);
        return columnGroup;
      });
  }

  removeColumnGroup(groupId) {
    const params = {
      experimentId: this.experimentId,
      groupId: parseInt(groupId),
    };
    return this.api.removeColumnGroup(params).then(result => {
      return result;
    });
  }

  async updateColumnAppearance(columnId, color, symbol) {
    const props = {};
    if (color) props.color = color;
    if (symbol) props.symbol = getPointSymbolEnum(symbol);

    try {
      await this.changeColumnProperties(columnId, props);

      const column = this.getColumnById(columnId);
      // update the props internally since this will not trigger a notification from the backend
      if (props.color) column.color = props.color;
      if (props.symbol) column.symbol = props.symbol;
    } catch (error) {
      console.error(error);
    }
  }

  updateColumnCell(_columnId, rowIndex, value) {
    const columnId = parseInt(_columnId);

    const column = this.getColumnById(columnId);
    if (column && column.editable) {
      column.setValuesByRow([rowIndex], [value]);

      return this.updateColumnValues(columnId, column.values, [rowIndex]);
    }

    return Promise.reject(new Error(`Cannot update cell for columnId= ${columnId}`));
  }

  checkHasStruckRowsForDataSet(dataSetId) {
    return this.getColumnsForSet(dataSetId).some(column => column.hasStruckRows);
  }

  checkHasStruckRows() {
    return this.columns.some(column => column.hasStruckRows);
  }

  /**
   * Strike through rows
   *
   * @param {number} dataSetId
   * @param {number} row - beginning row to strike through
   * @param {number} count - number or rows to strike
   *
   */
  strikeRows(dataSetId, row, count) {
    return this.api.strikeRows({
      experimentId: this.experimentId,
      dataSetId: toUdmId(dataSetId),
      row,
      count,
      strikethrough: true,
    });
  }

  /**
   * Unstrike struckthrough rows
   *
   * @param {number} dataSetId
   * @param {number} row - beginning row to strike through
   * @param {number} count - number or rows to strike
   *
   */
  unstrikeRows(dataSetId, row, count) {
    return this.api.strikeRows({
      experimentId: this.experimentId,
      dataSetId: toUdmId(dataSetId),
      row,
      count,
      strikethrough: false,
    });
  }

  async unstrikeAllRowsForSet(dataSetId) {
    const columns = this.getColumnsForSet(dataSetId);
    if (columns) {
      const count = Math.max(...columns.map(column => column.values.length));
      await this.unstrikeRows(dataSetId, 0, count);
    }
  }

  /**
   * Unstrike all rows across all datasets
   */
  unstrikeAllRows() {
    return this.api.unstrikeAllRows({ experimentId: this.experimentId });
  }

  updateDataSet(id, params) {
    console.assert(id); // must pass a dataSetId
    return this.api.updateDataSetProperties({
      experimentId: this.experimentId,
      id: toUdmId(id),
      params,
    });
  }

  /**
   * Update column with values for the given rows
   * @param {string} id Column's ID
   * @param {Array<string|number>} values Values
   * @param {number[]} rows Updated rows
   * @param {boolean} trim
   * @return {Promise} A promise that resolves when the update is complete
   */
  async updateColumnValues(id, values, rows, trim = false) {
    console.assert(id); // must pass a columnId

    const valuesArray = [];
    rows.forEach(row => {
      valuesArray.push([row, values[row]]);
    });

    const column = this.getColumnById(id);
    const hasRowsWithDeletedValues = rows.some(
      row => values[row] === undefined && column.values[row] !== undefined,
    );

    await this.api.updateColumnValues({
      experimentId: this.experimentId,
      id: toUdmId(id),
      values: valuesArray,
      trim,
    });

    // When there are deleted rows, find annotations where the range has no data
    // and delete them
    if (hasRowsWithDeletedValues) this._deleteAnnotationsWithNoData();
  }

  changeColumnDataType(id, type) {
    return this.api.changeColumnDataType({
      experimentId: this.experimentId,
      id: parseInt(id),
      type,
    });
  }

  notifyCollectionPreparing() {
    this.isCollecting = this.isTriggeringEnabled && !this._isRemoteLoggingEnabled;
    this.emit('collection-preparing');
  }

  _notifyCollectionTriggered() {
    this.emit('collection-triggered');
  }

  _notifyCollectionStarted() {
    this.isCollecting = true;
    this.emit('collection-started');
  }

  _notifyCollectionStopped() {
    this.isCollecting = false;
    this.power.releaseWakeLock();
    this.emit('collection-stopped');
  }

  /**
   * Add event data
   *
   * @param {string} _id column id of the event column
   * @param {string|number} _value value to set, if the value is null or undefined, a selected event is added
   * @param {array} readings list of columnId/value pairs, e.g. [[id, value], ...]
   * @param {boolean} [isAlphanumericEnabled] whether alphanumeric values are enabled
   * @returns {Promise} promise that resolves when a response is received
   */
  addEventData(_id, _value, readings, isAlphanumericEnabled) {
    console.assert(_id); // must pass a columnId
    const id = toUdmId(_id);
    let value;

    if (isLocaleRationalNumber(_value)) {
      value = parseFloatLocale(_value);
    } else if (_value && isAlphanumericEnabled) {
      value = _value;
    } else {
      value = null;
    }

    const readingsArray = [];

    readings.forEach(item => {
      readingsArray.push([toUdmId(item.column.id), item.value]);
    });

    return this.api.addEventData({
      experimentId: this.experimentId,
      id,
      value,
      readings: readingsArray,
    });
  }

  changeColumnUnit(id, newUnit) {
    if (this.blockInfoSyncing) return;
    console.assert(id); // must pass a columnId
    this.api.changeColumnUnit({
      experimentId: this.experimentId,
      id: toUdmId(id),
      unit: newUnit,
    });
  }

  changeColumnProperties(columnId, properties = {}) {
    const id = toUdmId(columnId);

    if (id === 0) {
      return Promise.reject(new Error('changeCalled called before UDM id available.'));
    }

    return this.api.changeColumnProperties({
      experimentId: this.experimentId,
      id,
      ...properties,
    });
  }

  async addGraph(properties = {}) {
    const _properties = { experimentId: this.experimentId, ...properties };

    if (properties.baseColumnId) {
      _properties.baseColumnId = toUdmId(_properties.baseColumnId);
    }
    // Get new udmId and add it to the infos cache:
    const udmId = await this.api.addGraph(_properties);
    const { graphId } = udmId;

    this._graphInfos[graphId] = _properties;

    return udmId;
  }

  getGraphUdmIds() {
    return Object.keys(this._graphInfos);
  }

  /**
   * Pairs a graphId with a udmId so that Internet Data Sharing can map graph IDs.
   * @param {String} graphId textual graph id, e.g. 'graph_1', 'graph_2', 'graph_3'.
   * @param {Number} udmId ID assigned by UDM.
   */
  registerGraphId(graphId, udmId) {
    const index = this._graphIdMap.findIndex(m => m.graphId === graphId);
    const entry = { graphId, udmId };
    if (index >= 0) {
      this._graphIdMap[index] = entry;
    } else {
      this._graphIdMap.push(entry);
    }
    // Sort this array in case our initialization / file-open sequence gets re-ordered.
    this._graphIdMap.sort((s1, s2) => s1.graphId >= s2.graphId);
  }

  /**
   * Unregisters a graph id, previously registered with `registerGraphId()`.0
   * @param {*} graphId textual graph id, e.g. 'graph_1' et al.
   */
  clearGraphId(graphId) {
    const index = this._graphIdMap.findIndex(m => m.graphId === graphId);
    if (index >= 0) {
      this._graphIdMap.splice(index, 1);
    }
  }

  get registeredGraphIds() {
    return [...this._graphIdMap];
  }

  changeGraphProperties(graphUdmId, properties = {}) {
    if (this.importing || this.sessionClosing) {
      return Promise.resolve();
    }

    const graphId = toUdmId(graphUdmId);

    if (graphId === 0) {
      return Promise.reject(new Error('changeGraphProperties called before UDM id available.'));
    }

    const _properties = {
      experimentId: this.experimentId,
      graphId,
      ...properties,
    };

    if (properties.baseColumnId) {
      _properties.baseColumnId = toUdmId(properties.baseColumnId);
    }

    return this.api.changeGraphProperties(_properties);
  }

  addGraphTraces(graphUdmId, traces = []) {
    let tracesValid = true;
    traces.forEach(t => {
      if (t.experimentId !== this.session.experimentId) {
        console.error(
          `attemtped to add traces for stale exp ${t.experimentId} (currently ${this.session.experimentId}`,
        );
        tracesValid = false;
      }
    });

    if (!tracesValid) {
      return Promise.reject();
    }

    const graphId = toUdmId(graphUdmId);

    return this.api.addGraphTraces({
      experimentId: this.experimentId,
      graphId,
      traces,
    });
  }

  removeGraphTraces(graphUdmId, traces = []) {
    if (this.sessionClosing) {
      return Promise.resolve();
    }

    let tracesValid = true;
    traces.forEach(t => {
      if (t.experimentId !== this.session.experimentId) {
        console.error(
          `attemtped to remove traces for stale exp ${t.experimentId} (currently ${this.session.experimentId}`,
        );
        tracesValid = false;
      }
    });

    if (!tracesValid) {
      return Promise.reject();
    }

    return this.api.removeGraphTraces({
      experimentId: this.experimentId,
      graphId: toUdmId(graphUdmId),
      traces,
    });
  }

  addGraphCurveFit(graphUdmId, params = {}) {
    const curveFitParams = { ...params };
    const graphId = toUdmId(graphUdmId);

    let fitId;
    if (params.fitId) {
      fitId = toUdmId(params.fitId);
    }

    if (params.baseColumnId) {
      curveFitParams.baseColumnId = toUdmId(params.baseColumnId);
    }
    if (params.traceColumnId) {
      curveFitParams.traceColumnId = toUdmId(params.traceColumnId);
    }

    return this.api.addGraphCurveFit({
      experimentId: this.experimentId,
      graphId,
      fitId,
      ...curveFitParams,
    });
  }

  removeGraphCurveFit(graphUdmId, fitId) {
    return this.api.removeGraphCurveFit({
      experimentId: this.experimentId,
      fitId: toUdmId(fitId),
    });
  }

  addGraphIntegral(graphUdmId, params = {}) {
    const integralParams = { ...params };

    const graphId = toUdmId(graphUdmId);

    let integralId;
    if (params.integralId) {
      integralId = toUdmId(params.integralId);
    }

    if (params.baseColumnId) {
      integralParams.baseColumnId = toUdmId(params.baseColumnId);
    }
    if (params.traceColumnId) {
      integralParams.traceColumnId = toUdmId(params.traceColumnId);
    }

    return this.api.addGraphIntegral({
      experimentId: this.experimentId,
      graphId,
      integralId,
      ...integralParams,
    });
  }

  removeGraphIntegral(graphUdmId, integralId) {
    return this.api.removeGraphIntegral({
      experimentId: this.experimentId,
      integralId: toUdmId(integralId),
    });
  }

  /**
   * Add a manual fit for the given graph
   *
   * @param {number} graphUdmId
   * @param {import('@common/mobx-stores/ManualFit.js').ManualFitParams} params hash of manual fit props
   * @return {ManualFit}
   */
  async addGraphManualFit(graphUdmId, params) {
    const { valueRange: range } = this.getColumnById(params.baseColumnId);
    const manualFit = new ManualFit({
      ...params,
      graphId: toUdmId(graphUdmId),
      baseColumnId: toUdmId(params.baseColumnId),
      yColumnId: toUdmId(params.yColumnId),
      range,
    });
    try {
      // Create a UDM entry for this new manual fit's properties.
      manualFit.id = await this.api.addManualFit(this.experimentId, manualFit.udmExport);
      this._addManualFit(manualFit);
    } catch (error) {
      console.error(`Could not create manual fit: ${error.message}`);
      return null;
    }

    return manualFit;
  }

  async removeGraphManualFit(manualFit) {
    const id = toUdmId(manualFit.id);

    // Remove from udm
    await this.api.removeManualFit(this.experimentId, id);

    // Cancel autorun
    const stopperFn = DataWorld._autorunStopFunctions[id];
    if (stopperFn) {
      stopperFn();
      delete DataWorld._autorunStopFunctions[id];
    }

    this.manualFits.remove(manualFit);
  }

  /**
   * Adds manual fit to list, and begins observing its properties for changes.
   * @param {ManualFit} manualFit
   */
  _addManualFit(manualFit) {
    this.manualFits.push(manualFit);
    const stopFunc = autorun(async firstTime => {
      const { experimentId } = this;
      const info = manualFit.udmExport;

      if (!experimentId || firstTime) return;

      try {
        await this.api.updateManualFit(experimentId, info);
      } catch (error) {
        console.error(`Manual fit autorun: got error trying to update id ${info.id ?? '???'}`);
        console.error(error);
      }
    });

    DataWorld._autorunStopFunctions[manualFit.id] = stopFunc;
  }

  addGraphPeakIntegral(graphUdmId, params = {}) {
    const graphId = toUdmId(graphUdmId);

    let integralId = 0;
    if (params.integralId) {
      integralId = toUdmId(params.integralId);
    }

    if (params.baseColumnId) {
      params.baseColumnId = toUdmId(params.baseColumnId);
    }

    if (params.traceColumnId) {
      params.traceColumnId = toUdmId(params.traceColumnId);
    }

    if (params.leftmostId) {
      params.leftmostId = toUdmId(params.leftmostId);
    }

    if (params.rightmostId) {
      params.rightmostId = toUdmId(params.rightmostId);
    }

    return this.api.addGraphPeakIntegral({
      experimentId: this.experimentId,
      graphId,
      integralId,
      ...params,
    });
  }

  removeGraphPeakIntegral(integralId) {
    return this.api.removeGraphPeakIntegral({
      experimentId: this.experimentId,
      integralId: toUdmId(integralId),
    });
  }

  addGraphStats(graphUdmId, params = {}) {
    const statsParams = { ...params };

    const graphId = toUdmId(graphUdmId);
    let statsId;
    if (params.statsId) {
      statsId = toUdmId(params.statsId);
    }

    if (params.baseColumnId) {
      statsParams.baseColumnId = toUdmId(params.baseColumnId);
    }
    if (params.traceColumnId) {
      statsParams.traceColumnId = toUdmId(params.traceColumnId);
    }

    return this.api.addGraphStats({
      experimentId: this.experimentId,
      graphId,
      statsId,
      ...statsParams,
    });
  }

  removeGraphStats(graphUdmId, statsId) {
    return this.api.removeGraphStats({
      experimentId: this.experimentId,
      statsId: toUdmId(statsId),
    });
  }

  /**
   * Sets info box position and collapsed state.
   * @param {(number|string)} helperId udm ID of the curve fit, integral, or statistics (et al.) box.
   * @param {import('../api/DataWorldAPI.js').InfoBox} info info containing position and collapsed state.
   * @param {boolean} showUncertainty [optional: for curve fit info boxes] show or hide uncertainty field.
   * @returns {Promise} then() / await if success.
   * @throws {Error} if operation fails.
   */
  setInfoBoxInfo(helperId, infoBox, showUncertainty) {
    if (!this.experimentId) return Promise.resolve();
    return this.api.setInfoBoxInfo({
      experimentId: this.experimentId,
      id: toUdmId(helperId),
      infoBox,
      showUncertainty,
    });
  }

  /**
   * Creates a new annotation and adds it to udm. This will also add a listener
   * that will push any changes to the annotation to udm automatically.
   * @param {number} graphUdmId identifer of the parent graph
   * @param {import('../../mobx-stores/Annotation.js').RawAnnotationData} params initial parameters for the annotations.
   * @returns {Annotation?} newly created annotation or null if the annotation
   * could not be created (refer to console).
   */
  async addGraphAnnotation(graphUdmId, params = {}) {
    const annotationParams = { ...params };

    const graphId = toUdmId(graphUdmId);

    if (params.udmId) {
      annotationParams.annotationId = toUdmId(params.udmId);
    }

    const response = await this.api.addGraphAnnotation({
      experimentId: this.experimentId,
      graphId,
      ...annotationParams,
    });

    if (response === undefined || response === 0) {
      console.error('Could not create annotation.');
      return null;
    }
    const newAnnotation = new Annotation({
      ...params,
      id: response,
      graphUdmId: graphId,
    });
    this._internalRegisterAnnotation(newAnnotation);
    return newAnnotation;
  }

  /**
   * Register a freshly minted annotation object. This is used internally. Do
   * not call directly.
   * @param {Annotation} annotation instance that was either created by
   * `addGraphAnnotation()` or from udm annotation data on file->open.
   * @private
   */
  _internalRegisterAnnotation(annotation) {
    this.annotations.push(annotation);

    const stopFunc = autorun(async firstTime => {
      const info = { experimentId: this.experimentId, ...annotation.udmExport };

      // autorun() calls this function once when it first gets called. If we are
      // in the middle of File->Open, the experiementId will be invalid, so
      // we can just exit.
      if (!this.experimentId || firstTime) return;

      try {
        await this.api.updateGraphAnnotation(info);
      } catch (error) {
        console.error(`Anotation autorun: got error trying to update id ${info.id ?? '???'}`);
        console.error(error);
      }
    });

    DataWorld._autorunStopFunctions[annotation.id] = stopFunc;
  }

  /**
   * Returns all annotations that live on a particular graph.
   * @param {number} graphUdmId id of the graph you're inspecting.
   * @returns {Annotation[]} array of Annotations that make an appearances on
   * the graph.
   */
  getAnnotationsForGraph(graphUdmId) {
    return this.annotations.filter(annotation => annotation.containsGraph(graphUdmId));
  }

  /**
   * Returns all annotations that contain a particular column target
   * @param {number} columnId udm id of the column you're interested in.
   * @returns {Annotation[]} array of annotations that target that column.
   */
  getAnnotationsForColumn(columnId) {
    return this.annotations.filter(annotation => annotation.containsTargetColumn(columnId));
  }

  /**
   * Remove and unregister an annotation
   * @param {Annotation} annotation annotation to remove
   */
  async removeGraphAnnotation(annotation) {
    const id = toUdmId(annotation.id);

    // Remove from udm
    await this.api.removeGraphAnnotation({
      experimentId: this.experimentId,
      annotationId: id,
    });

    // Cancel autorun
    const stopper = DataWorld._autorunStopFunctions[id];
    if (stopper) {
      stopper();
      delete DataWorld._autorunStopFunctions[id];
    }

    // Remove from the list.
    runInAction(() => this.annotations.remove(annotation));
  }

  // id is optional
  createInternalDataSet(params = {}) {
    const opts = { ...params };
    let nameChange = false;

    if (opts.type === 'regular' && !opts.name) {
      opts.name = this._getNextRegularSetName();
      nameChange = true;
    }

    const dataSet = new DataSet(opts);

    this.dataSets.push(dataSet);
    if (dataSet.type === 'regular') {
      this.currentDataSet = dataSet;
    }

    // TODO: cleanup these two events
    this.emit('data-set-added', { addedDataSet: dataSet });
    this.emit('dataset-added', dataSet);

    if (nameChange) {
      this.updateDataSet(params.id, { name: opts.name });
    }

    return dataSet;
  }

  createInternalGroup(params) {
    const { beforeSibling, siblingId } = params;
    delete params.siblingId;
    delete params.beforeSibling;

    const columnGroup = new ColumnGroup(params);

    if (siblingId) {
      const siblingPosition = this.columnGroups.findIndex(group => group.id === siblingId);
      if (siblingPosition < 0) console.warn(`Could not find column group with id ${siblingId}`);

      if (siblingPosition >= 0) {
        const insertAt = beforeSibling ? siblingPosition - 1 : siblingPosition + 1;
        this.columnGroups.splice(insertAt, 0, columnGroup);
      }
    } else {
      this.columnGroups.push(columnGroup);
    }

    this.emit('column-group-added', columnGroup);
    ['name', 'units', 'format-string', 'smaxy'].forEach(propName => {
      columnGroup.on(`${propName}-changed`, (value, oldValue) => {
        this.emit(`column-group-${propName}-changed`, columnGroup, value, oldValue);
      });
    });

    if (!this.importing && params.calcCustomEqNeedsParsing) {
      this.updateEquation(columnGroup);
    }
  }

  /**
   * Parses custom equation string in its canonical form (columns represened by group ids).
   * @param {String} equString equation string
   */
  parseCustomEquation(equStr) {
    // The canonical string already uses group ids, so the translation is merely
    // a reflection
    const getColumnGroupId = id => {
      return parseInt(id);
    };

    const parser = new VstEquationParser(this._userConstants);
    const result = parser.parse(equStr);
    parser.processResult(result, getColumnGroupId);

    return result;
  }

  /**
   * Translates custom expression in its canonical form (columns represented by group ids) to a
   * user friendly form (columns represented by group names).
   * @param {String} equStr equation string
   */
  translateCustomEquationFromCanonical(equStr) {
    const getColumnGroupName = id => {
      let name = id;
      const group = this.getColumnGroups()
        .filter(g => g.id === id)
        .at(0);
      if (group) name = group.name;
      return name;
    };

    const updateCustomEq = customEq => {
      const regex = RegExp('"[^"]+"', 'g');
      let array;
      let result = '';

      let index = 0;

      // eslint-disable-next-line no-cond-assign
      while ((array = regex.exec(customEq)) !== null) {
        const match = array[0];
        const start = regex.lastIndex - match.length;
        const end = regex.lastIndex;

        result += customEq.substring(index, start);

        const idStr = match.substring(1, match.length - 1);

        const id = parseInt(idStr);
        if (Number.isNaN(id)) {
          result += match;
        } else {
          const name = getColumnGroupName(id);
          result += `"${name}"`;
        }
        index = end;
      }

      if (index !== customEq.length) {
        result += customEq.substring(index);
      }

      return result;
    };

    return updateCustomEq(equStr);
  }

  /**
   * Translates custom expression in a user-friendly form (columns represented by names ids) to a
   * canonical form (columns represented by group ids).
   * @param {String} equStr equation string
   */
  translateCustomEquationToCanonical(equStr) {
    const getColumnGroupId = name => {
      let id = -1;
      const group = this.getColumnGroups()
        .filter(g => {
          return g.name === name;
        })
        .at(0);
      if (group) id = group.id;
      return id;
    };

    const updateCustomEq = customEq => {
      const regex = RegExp('"[^"]+"', 'g');
      let array;
      let result = '';

      let index = 0;

      // eslint-disable-next-line no-cond-assign
      while ((array = regex.exec(customEq)) !== null) {
        const match = array[0];
        const start = regex.lastIndex - match.length;
        const end = regex.lastIndex;

        result += customEq.substring(index, start);

        const idStr = match.substring(1, match.length - 1);
        const id = parseInt(idStr);

        if (Number.isNaN(id)) {
          const id = getColumnGroupId(idStr);

          if (id !== -1) {
            result += `"${id}"`;
          } else {
            result += match;
          }
        } else {
          result += match;
        }
        index = end;
      }

      if (index !== customEq.length) {
        result += customEq.substring(index);
      }

      return result;
    };

    return updateCustomEq(equStr);
  }

  updateInternalGroup(id, params) {
    const columnGroup = this.getColumnGroupById(id);
    const oldEq = columnGroup.customeEq || false;
    const newEq = params.calcCustomEq?.equStr || false;
    const needsParsing = params.calcCustomEqNeedsParsing || (oldEq && newEq && oldEq !== newEq);
    columnGroup.update(params);

    if (!this.importing && needsParsing) {
      this.updateEquation(columnGroup);
    }

    this.emit('column-group-updated', columnGroup);
  }

  removeInternalGroup(id) {
    const columnGroup = this.getColumnGroupById(id);
    this.columnGroups = this.columnGroups.filter(group => group.id !== id);
    columnGroup.off();
    this.emit('column-group-removed', columnGroup);
  }

  updateColumnGroup(groupId, properties) {
    if (!this.experimentId) return Promise.resolve();
    const columnGroup = this.getColumnGroupById(groupId);
    const _properties = { ...properties };

    if (!columnGroup) {
      return Promise.reject(new Error(`Failed to find group for groupId= ${groupId}`));
    }

    if (_properties.errorBarColumnId) {
      _properties.errorBarColumnId = toUdmId(_properties.errorBarColumnId);
    }

    // Note: we cannot update the client-side group properties synchronously here, as the final version of
    // the properties might not match the properties here, i.e., name can get mangled to prevent duplicates,
    // so we need to wait for the 'dw:data-group-properties-changed event', MEG-1726
    // columnGroup.update(_properties);

    // It seems like a good idea to check for units have a non-empty string in it
    // but it will break pH unit conversion because at least for now that is an empty string
    if (columnGroup.type === 'sensor' && _properties.units !== undefined) {
      this.sensorWorld.changeSensorUnit(this.experimentId, columnGroup.sensorId, _properties.units);
    }

    return this.api.updateColumnGroup({
      experimentId: this.experimentId,
      groupId,
      properties: _properties,
    });
  }

  updateColumnGroupByColumn(columnId, properties) {
    const column = this.getColumnById(columnId);

    if (!column) {
      return Promise.reject(new Error(`Failed to find column for columnId= ${columnId}`));
    }

    return this.api.updateColumnGroup({
      experimentId: this.experimentId,
      groupId: column.groupId,
      properties,
    });
  }

  // create a column in the current dataset
  // id is required
  createInternalColumnForDataSet(dataSet, params) {
    const siblingId = params.siblingId ? `${params.siblingId}` : '0';
    const { beforeSibling } = params;
    let _params = {};

    if (typeof params === 'string') {
      const id = params;
      _params = { id };
    } else {
      _params = { ...params };
    }

    if (_params.type === 'time') {
      _params.title = getText('Time');
    }

    if (_params.id !== undefined) {
      // NOTE: always convert column id to a string because we only deal with them as strings from here on out.
      _params.id = `${_params.id}`;
    }

    if (_params.meterId !== undefined) {
      _params.meterId = `${_params.meterId}`;
    }

    if (_params.setId !== undefined) {
      _params.setId = `${_params.setId}`;
    }

    console.assert(dataSet);
    console.assert(_params.id);

    // precision requires special handling
    const { precision } = _params;
    delete _params.precision;

    // the previous id is not a column prop
    delete _params.siblingId;
    delete _params.beforeSibling;

    const column = new Column(_params);

    /*
      Here we are throttling the color change to prevent possible collisions of multiple changes
      coming in from multiple source (multiple viewports). When that happens it causes an infinite
      loop of color changes. In testing, anything less than 300ms caused the infinite loop. I gave
      500ms here to give some padding for potentially slower devices.
    */
    const throttledColorChange = throttle(() => {
      if (this.blockInfoSyncing) return;
      this.changeColumnProperties(column.id, { color: column.color });
    }, 500);
    column.on('color-changed', throttledColorChange);

    if (_params.foreignId) column.foreignId = _params.foreignId;

    if (_params.foreignGroupId) column.foreignGroupId = _params.foreignGroupId;

    dataSet.addColumn(column.id, siblingId, beforeSibling);

    const siblingPosition = this.columns.findIndex(col => col.id === siblingId);
    if (siblingPosition >= 0) {
      const insertAt = Math.max(0, beforeSibling ? siblingPosition - 1 : siblingPosition + 1);
      this.columns.splice(insertAt, 0, column);
    } else {
      this.columns.push(column);
    }

    this.emit('column-added', column);
    ['values', 'strikethrough', 'color', 'format-string', 'updated-rows'].forEach(propName => {
      column.on(`${propName}-changed`, value => {
        this.emit(`column-${propName}-changed`, column, value);
        if (propName === 'updated-rows') {
          this.updateColumnValues(column.id, column.values, column.updatedRows);
        }
      });
    });

    if (precision) {
      column.group.update({ precision });
    }

    // On import, we have to process the values in special datasets.
    // We can only do this once the column values arrived, so here we
    // set up the appropriate values-changed handler
    if (this.importing) {
      // Although the column objects are created in the correct order,
      // the value updates are batched and might arrive in any order,
      // so we need to wait till we got one of these events for both
      // the base and trace columns -- we used the seenBaseCol and
      // seenTraceCol trackers for this

      // reset the column trackers when the first column is added
      if (dataSet.columnIds.length === 1) {
        this.seenBaseCol[dataSet.id] = false;
        this.seenTraceCol[dataSet.id] = false;
      }

      if (dataSet.type === 'prediction') {
        // wait for the values
        const _onPredictionValuesChanged = () => {
          const baseColId = dataSet.columnIds[0];

          if (baseColId === column.id) {
            this.seenBaseCol[dataSet.id] = true;
          }

          if (dataSet.columnIds.length < 2) {
            return;
          }

          const traceColId = dataSet.columnIds[1];

          if (traceColId === column.id) {
            this.seenTraceCol[dataSet.id] = true;
          }

          if (!this.seenBaseCol[dataSet.id] || !this.seenTraceCol[dataSet.id]) {
            return;
          }

          // remove the tracker values for this dataset now that we have seen both
          // columns
          delete this.seenTraceCol[dataSet.id];
          delete this.seenBaseCol[dataSet.id];

          const id = uniqueId('prediction-');

          const predOpts = {
            name: dataSet.name,
            id,
            baseColId,
            traceColId,
            onDocumentLoad: true,
          };

          this.createPrediction([], predOpts);
        };

        column.once('values-changed', _onPredictionValuesChanged);
      } else if (dataSet.type === 'spectrum') {
        // wait for the values
        const _onSpectrumValuesChanged = () => {
          if (dataSet.columnIds.length < 2) {
            return;
          }

          const [baseColId, traceColId] = dataSet.columnIds.filter(
            id => this.getColumnById(id).group.isSpectrumGroup,
          );

          if (baseColId === column.id) {
            this.seenBaseCol[dataSet.id] = true;
          }

          if (traceColId === column.id) {
            this.seenTraceCol[dataSet.id] = true;
          }

          if (!this.seenBaseCol[dataSet.id] || !this.seenTraceCol[dataSet.id]) {
            return;
          }

          // remove the tracker values for this dataset now that we have seen both
          // columns
          delete this.seenTraceCol[dataSet.id];
          delete this.seenBaseCol[dataSet.id];

          const points = [];
          const baseColVals = this.getColumnById(baseColId).values;
          const traceColVals = this.getColumnById(traceColId).values;
          const len = Math.min(baseColVals.length, traceColVals.length);
          const id = uniqueId('spectrum-');

          for (let i = 0; i < len; ++i) {
            points[i] = [baseColVals[i], traceColVals[i]];
          }

          const specOpts = {
            points,
            name: dataSet.name,
            id,
            baseColId,
            traceColId,
            onDocumentLoad: true,
          };

          this.createSpectrum(specOpts);
        };
        column.once('values-changed', _onSpectrumValuesChanged);
      } else if (dataSet.type === 'graph-match') {
        // wait for values
        const _onGraphMatchValuesChanged = () => {
          const baseColId = dataSet.columnIds[0];

          if (baseColId === column.id) {
            this.seenBaseCol[dataSet.id] = true;
          }

          if (dataSet.columnIds.length < 2) {
            return;
          }

          const traceColId = dataSet.columnIds[1];

          if (traceColId === column.id) {
            this.seenTraceCol[dataSet.id] = true;
          }

          if (!this.seenBaseCol[dataSet.id] || !this.seenTraceCol[dataSet.id]) {
            return;
          }

          delete this.seenTraceCol[dataSet.id];
          delete this.seenBaseCol[dataSet.id];

          const id = uniqueId('graph-match-');

          const gmOpts = {
            id,
            name: dataSet.name,
            matchType: this.getMatchTypeForColumn(traceColId),
            dataSetId: dataSet.id,
            baseColId,
            traceColId,
            onDocumentLoad: true,
          };

          this.createGraphMatch([], gmOpts);
        };

        column.once('values-changed', _onGraphMatchValuesChanged);
      }
    }

    return column;
  }

  async getSessionGUIDs() {
    return this.api.getSessionGUIDs(this.experimentId);
  }

  async setSessionGUID(sessionGUID) {
    return this.api.setSessionGUIDs({
      experimentId: this.experimentId,
      sessionGUID,
    });
  }

  async setForeignSessionGUID(foreignSessionGUID) {
    return this.api.setSessionGUIDs({
      experimentId: this.experimentId,
      foreignSessionGUID,
    });
  }

  /**
   * @param {number} dataSetId - dataSetId
   */
  async setLatestDataSet(dataSetId) {
    await this.api.setLatestDataSet(this.experimentId, toUdmId(dataSetId));
    this.currentDataSet = this.getDataSetByID(dataSetId);
  }

  /**
   * @param {Array<object>} updates
   * @param {number} updates.dataSetId - dataSetId
   * @param {number} updates.groupId - groupId
   * @param {Array<rows>} updates.rows
   * @param {Array<number>} updates.values
   * @param {Boolean} updates.wholeColumnFlag
   */
  async streamToColumns(updates) {
    // eslint-disable-next-line no-param-reassign
    updates = updates.map(update => ({
      ...update,
      dataSetId: toUdmId(update.dataSetId),
      groupId: toUdmId(update.groupId),
      rows: update.rows ?? [],
      values: update.values.map(v => (v !== null ? v : NaN)),
    }));

    return this.api.streamToColumns(this.experimentId, updates);
  }

  /**
   * Removes a column from DataWorld bookkeeping and notifies listeners.
   * @param {number|string} _columnId udm or datasharing identifier for column
   * to remove.
   * @param {boolean} [forceRemoveGroup=false] if true, will delete the column's
   * group, including removing it from the back end.
   * @returns {boolean} true if the column was actually removed, else false.
   */
  removeInternalColumn(_columnId, forceRemoveGroup = false) {
    let columnId = _columnId;
    this.emit('removing-column', columnId);

    if (columnId !== undefined) {
      // NOTE: always convert columnId to a string because we only deal with them as strings from here on out.
      columnId = `${columnId}`;
    }

    let removed = false;
    const column = this.getColumnById(columnId);
    if (column) {
      const dataSet = this.getDataSetByID(column.setId);
      if (dataSet) {
        dataSet.removeColumn(columnId);
      } else {
        console.warn(`unable to find data set for column id= ${columnId}`);
      }

      this.columns = this.columns.filter(col => col.id !== columnId);
      column.eventNames().forEach(eventName => column.removeAllListeners(eventName));
      this.emit('column-removed', column);

      this.ignoreNameChanges = true;
      // _setColNameIndex(dataSet.id, this.columns, column);
      this.ignoreNameChanges = false;

      if (forceRemoveGroup) {
        this.removeColumnGroup(column.groupId);
      }
      removed = true;
    }

    return removed;
  }

  removeInternalDataSet(dataSetId) {
    let removed = false;
    const dataSet = this.getDataSetByID(dataSetId);
    if (dataSet) {
      // remove all the columns for the dataSet
      dataSet.columnIds.forEach(columnId => {
        this.removeInternalColumn(columnId);
      });

      this.dataSets = this.dataSets.filter(ds => ds.id !== dataSetId);
      removed = true;
      this.emit('dataset-removed', dataSetId);
      dataSet.off();

      const { currentDataSet } = this;
      if (currentDataSet && currentDataSet.id === dataSetId) {
        this.currentDataSet = null;
      }
    }
    return removed;
  }

  setupSpectrum(spectrum) {
    return new Promise((resolve, reject) => {
      const eventBinder = new EventBinder();
      const colIds = [];
      let dataSetId = 0;

      const _addNewDataSet = () =>
        new Promise((resolve, reject) => {
          let colCount = 0;
          let resolvedCols = false;

          const _columnBinder = eventBinder.on(this, 'column-added', newCol => {
            // FIXME -- need some way to verify these are our columns, but unfortunately
            // These events trigger before the createNewDataSet promise is completed, so we
            // don't know the ds id
            colIds[colCount] = newCol.id;
            colCount++;

            if (colCount === 2) {
              _columnBinder.off();
              resolvedCols = true;

              if (dataSetId !== 0) {
                resolve(colIds);
              }
            }
          });

          this.createNewDataSet({
            type: 'spectrum',
            name: spectrum.name,
          })
            .then(result => {
              dataSetId = result.datasetId;

              if (resolvedCols) {
                resolve(colIds);
              }

              // timeout in the event the dataset never gets created
              setTimeout(() => {
                if (!resolvedCols) {
                  _columnBinder.off();
                  reject(new Error('Timed out'));
                }
              }, 3000);
            })
            .catch(error => {
              _columnBinder.off();
              reject(error);
            });
        });

      _addNewDataSet()
        .then(() => {
          spectrum.dataSetId = parseInt(dataSetId);
          spectrum.baseColId = parseInt(colIds[0]);
          spectrum.traceColId = parseInt(colIds[1]);

          if (spectrum.points) {
            const baseColumn = this.getColumnById(colIds[0]);
            const column = this.getColumnById(colIds[1]);

            console.assert(column && baseColumn);

            const rows = [];
            const values = [];
            const wavelengths = [];

            for (let i = 0; i < spectrum.points.length; ++i) {
              const [x, y] = spectrum.points[i];

              rows.push(i);
              wavelengths[i] = x;
              values[i] = y;
            }

            // We have to clear the old rows here, otherwise the updated-rows notification
            // will not trigger when we assign new values (I suspect this is because the rows array tends
            // to hold identical contents between multiple runs).
            baseColumn.values = [];
            baseColumn.updatedRows = [];

            column.values = [];
            column.updatedRows = [];

            baseColumn.values = wavelengths;
            baseColumn.updatedRows = rows;

            column.values = values;
            column.updatedRows = rows;
          }

          resolve(spectrum);
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  }

  setupSpecialDataSet(points, params) {
    return new Promise((resolve, reject) => {
      const eventBinder = new EventBinder();
      const colIds = [];
      let dataSetId = 0;

      const _addNewDataSet = () =>
        new Promise((resolve, reject) => {
          let colCount = 0;
          let resolvedCols = false;
          const transactionNumber = ++this._specialDataSetTransactionCounter;

          const _columnBinder = eventBinder.on(this, 'column-added', newCol => {
            // Check if this is one of the column we're interested in.
            if (newCol.context === transactionNumber) {
              colIds[colCount] = newCol.id;
              colCount++;

              if (colCount === 2) {
                _columnBinder.off();
                resolvedCols = true;

                if (dataSetId !== 0) {
                  resolve(colIds);
                }
              }
            }
          });

          const config = {
            type: params.type,
            name: params.name,
            context: transactionNumber,
          };

          if (params.matchGroupId) {
            config.matchGroupId = params.matchGroupId;
          }

          this.createNewDataSet(config)
            .then(result => {
              dataSetId = result.datasetId;

              const dataSet = this.getDataSetByID(dataSetId);

              if (dataSet) {
                eventBinder.on(this, 'dataset-name-changed', set => {
                  params.name = set.name;
                });
              }

              if (resolvedCols) {
                resolve({
                  dataSetId,
                  colIds,
                });
              }

              // timeout in the event the dataset never gets created
              setTimeout(() => {
                if (!resolvedCols) {
                  _columnBinder.off();
                  reject(new Error('Timed out'));
                }
              }, 3000);
            })
            .catch(error => {
              _columnBinder.off();
              reject(error);
            });
        });

      _addNewDataSet()
        .then(({ dataSetId, colIds: columnIds }) => {
          if (points) {
            const baseColumn = this.getColumnById(columnIds[0]);
            const traceColumn = this.getColumnById(columnIds[1]);

            console.assert(traceColumn && baseColumn);

            const rows = [];
            const values = [];
            const times = [];

            for (let i = 0; i < points.length; ++i) {
              const [x, y] = points[i];

              rows.push(i);
              times[i] = x;
              values[i] = y;
            }

            // We have to clear the old rows here, otherwise the updated-rows notification
            // will not trigger when we assign new values (I suspect this is because the rows array tends
            // to hold identical contents between multiple runs).
            baseColumn.values = [];
            baseColumn.updatedRows = [];

            traceColumn.values = [];
            traceColumn.updatedRows = [];

            baseColumn.values = times;
            baseColumn.updatedRows = rows;

            traceColumn.values = values;
            traceColumn.updatedRows = rows;
          }

          resolve({ dataSetId, columnIds });
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  }

  createSpectrum(options) {
    return new Promise((resolve, reject) => {
      const name = options.name || getText('Spectrum');
      const id = options.id || uniqueId('spectrum-');
      const points = options.points || [];
      const onDocumentLoad = options.onDocumentLoad || false;

      const params = {
        points,
        name,
        id,
        traceColId: parseInt(options.traceColId) || undefined,
        baseColId: parseInt(options.baseColId) || undefined,
      };

      const spectrum = new Spectrum(params);

      if (!onDocumentLoad) {
        // This sets up the backend part (which on document load is already done)
        this.setupSpectrum(spectrum)
          .then(spectrum => {
            this.spectrums = [];
            this.spectrums.push(spectrum);
            this.emit('spectrum-created', spectrum);
            resolve(spectrum);
          })
          .catch(err => {
            console.error(`Failed to set up spectrum: ${err}`);
            reject(err);
          });
      } else {
        this.spectrums.push(spectrum);
        resolve(spectrum);
      }
    });
  }

  createPrediction(points, options) {
    return new Promise((resolve, reject) => {
      const count = this.predictions.length + 1;
      const name = options.name || `${getText('Prediction')} ${count}`;
      const id = options.id || uniqueId('prediction-');
      const onDocumentLoad = options.onDocumentLoad || false;

      const params = {
        name,
        id,
        traceColId: parseInt(options.traceColId) || undefined,
        baseColId: parseInt(options.baseColId) || undefined,
        type: 'prediction',
      };

      if (!onDocumentLoad) {
        // This sets up the backend part (which on document load is already done)
        this.setupSpecialDataSet(points, params)
          .then(({ columnIds }) => {
            resolve(columnIds[1]);
          })
          .catch(err => {
            console.error(`Failed to set up prediction: ${err}`);
            reject(err);
          });
      } else {
        resolve(params.traceColId);
      }
    });
  }

  /** @param {import('@api/common/DataSet.js').DataSetType} type */
  auxDataSets(type) {
    return this.dataSets.filter(ds => ds.type === type);
  }

  /**
   * Creates an "auxiliary" data set, which is defined as a dataset with two columns that are populated at the time of creation.
   * @param { String } type e.g. 'fft' or 'histogram'
   * @param { Array } baseVals x-values
   * @param { Array } traceVals y-values
   * @param { Object} options data set options
   * @returns { Number} udm id of newly created data set.
   */
  createAuxDataSet(type, baseVals, traceVals, options = {}) {
    const count = Math.min(baseVals.length, traceVals.length);
    const points = [];
    for (let i = 0; i < count; ++i) {
      points.push([baseVals[i], traceVals[i]]);
    }

    return new Promise((resolve, reject) => {
      const dsName = type === 'fft' ? getText('FFT') : getText('Histogram');
      const count = this.auxDataSets(type).length + 1;
      const name = options.name || `${dsName} ${count}`;
      const id = options.id || uniqueId(`${type}-`);
      const onDocumentLoad = options.onDocumentLoad || false;

      const params = {
        name,
        id,
        traceColId: parseInt(options.traceColId) || undefined,
        baseColId: parseInt(options.baseColId) || undefined,
        type,
      };

      if (!onDocumentLoad) {
        // This sets up the backend part (which on document load is already done)
        this.setupSpecialDataSet(points, params)
          .then(({ dataSetId, columnIds }) => {
            resolve({ dataSetId, columnIds });
          })
          .catch(err => {
            reject(err);
          });
      } else {
        resolve();
      }
    });
  }

  async removeAuxDataSet(type, auxColumnTraceId) {
    const dataSet = this.auxDataSets(type).find(ds => ds.columnIds[1] === auxColumnTraceId);
    if (dataSet) await this.removeDataSet(dataSet.id);
  }

  createGraphMatch(points, options) {
    return new Promise((resolve, reject) => {
      const { matchType, name } = options;
      const id = options.id || uniqueId('graphMatch-');
      const onDocumentLoad = options.onDocumentLoad || false;

      console.assert(matchType);
      console.assert(name);

      const params = {
        name,
        id,
        traceColId: parseInt(options.traceColId) || undefined,
        baseColId: parseInt(options.baseColId) || undefined,
        matchGroupId: parseInt(options.matchGroupId),
        type: 'graph-match',
      };

      if (!onDocumentLoad) {
        // always delete the previous graph match, since we only support one
        if (this.graphMatches.length) {
          this.removeDataSet(this.graphMatches[0].id).catch(err => reject(err));
        }
        // This sets up the backend part (which on document load is already done)
        this.setupSpecialDataSet(points, params)
          .then(({ columnIds }) => {
            resolve({ traceColId: columnIds[1], matchType });
          })
          .catch(err => {
            console.error(`Failed to set up prediction: ${err}`);
            reject(err);
          });
      } else {
        resolve({ traceColId: params.traceColId, matchType });
      }
    });
  }

  removeGraphMatches() {
    return new Promise((resolve, reject) => {
      const promises = [];
      this.graphMatches.forEach(graphMatch => {
        promises.push(this.removeData(graphMatch.id));
      });

      Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  async removeDataSet(id) {
    const response = await this.api.removeDataSet({
      experimentId: this.experimentId,
      datasetId: toUdmId(id),
    });

    this.emit('dataset-removed', id);
    return Promise.resolve(response);
  }

  async clearDataSet(id) {
    return this.api.clearDataSet({
      experimentId: this.experimentId,
      datasetId: toUdmId(id),
    });
  }

  async clearAllDataSets() {
    return this.api.clearAllDataSets(this.experimentId);
  }

  async prepareDataSetForStream(dataSetId, length) {
    return this.api.prepareDataSetForStream({
      experimentId: this.experimentId,
      datasetId: toUdmId(dataSetId),
      length,
    });
  }

  getMatchTypeForColumn(columnId) {
    const col = this.getColumnById(columnId);

    let matchType = null;

    if (this.sensorWorld.isMotionDetector(col.sensorId)) {
      matchType = 'Position';
    } else if (col.type === 'calc' && col.sensorMapId.startsWith('CC_MDV')) {
      matchType = 'Velocity';
    }

    return matchType;
  }

  getMatchTypeForColumnGroup(groupId) {
    const group = this.getColumnGroupById(groupId);
    return this.getMatchTypeForColumn(group.columns[0].id);
  }

  getGraphMatchColumns() {
    const columns = [];
    const matchTypes = [];

    if (this.canCollect) {
      this.currentDataSet?.columnIds.forEach(colId => {
        const column = this.getColumnById(colId);
        const matchType = this.getMatchTypeForColumn(colId);

        if (matchType && matchTypes.indexOf(matchType) < 0) {
          matchTypes.push(matchType); // we only allow one of the same column types
          columns.push(column);
        }
      });
    }
    return columns;
  }

  // GRAPH MATCH POINT PAIR GENERATOR - CURRENTLY ONLY WORKS FOR MOTION DETECTOR
  generateMatchPoints(columnId, srcColumnGroupId) {
    const points = [];
    const matchType = this.getMatchTypeForColumnGroup(srcColumnGroupId);

    function getRandomPointByRange(min, max, prevPoint) {
      const minimumDelta = 0.4;
      const point = Math.random() * (max - min) + min;

      if (prevPoint && Math.abs(prevPoint - point) < minimumDelta) {
        return getRandomPointByRange(min, max, prevPoint);
      }

      return point;
    }

    if (matchType) {
      const column = this.getColumnById(columnId);
      const { units } = column;
      const SLOPE = units === 'ft' || units === 'ft/s' ? 3.2808 : 0;
      const isVelocity = matchType.toUpperCase() === 'VELOCITY';

      const params = this.dataCollection.timeBasedParams;
      const { delta } = params;
      const duration = formatter.convertTimeUnits(params.duration, 's', params.units);

      const baseRange = {
        min: 0,
        max: duration,
      };

      const leftRange = {
        min: column.range.min,
        max: column.range.max,
      };

      if (delta > 0 && duration > 0) {
        const pos = [];
        let xStep = duration / 3;
        let padding = 0;

        // Velocity graph range is -2 to 2m, but we need to generate positions that maintain reasonable distances over 10 seconds
        if (isVelocity) {
          leftRange.min = 0;
          leftRange.max = SLOPE > 0 ? 2 * SLOPE : 2;
          xStep = duration / 3.33;
        } else {
          padding = column.units === 'ft' ? 0.82021 : 0.25;
        }

        // We will generate 4 position values for both position and velocity matches
        // If the automation config has point values, use them instead of random values
        const automationPoints = this.automation.graphMatchPoints;

        for (let i = 0; i < 4; ++i) {
          pos[i] =
            automationPoints.length >= 4
              ? automationPoints[i]
              : getRandomPointByRange(leftRange.min + padding, leftRange.max - padding, pos[i - 1]);

          if (!isVelocity) {
            points.push([baseRange.min + xStep * i, pos[i]]);
          }
        }

        if (isVelocity) {
          /* Convert the positions to velocities. */
          // Always starts at v = 0
          points.push([0, 0]);

          let vel = (pos[1] - pos[0]) / xStep;
          points.push([baseRange.min + duration * 0.03, vel]);
          points.push([baseRange.min + duration * 0.33, vel]);

          vel = (pos[2] - pos[1]) / xStep;
          points.push([baseRange.min + duration * 0.36, vel]);
          points.push([baseRange.min + duration * 0.66, vel]);

          vel = (pos[3] - pos[2]) / xStep;
          points.push([baseRange.min + duration * 0.69, vel]);
          points.push([baseRange.min + duration, vel]);
        }
      }
    }

    return points;
  }

  autoSaveData(options = {}) {
    const defaultType = getDefaultType();

    const format = options.type || defaultType;
    const decimal = options.decimal || '.';

    const properties = {
      experimentId: this.experimentId,
      name: options.filename,
      format,
      decimal,
      age: options.age,
      filepath: options.filepath,
    };

    return this.api.exportData(properties).then(async response => {
      if ((await this.archive) && response.fileString) {
        return this.archive.writeXml(response.fileString).then(() => ({ age: response.age }));
      }

      if (response.fileString) {
        const blob = new Blob([response.fileString], {
          type: getMimeType(),
        });

        return Promise.resolve({
          blob,
          age: response.age,
        });
      }

      if (response.age > 0) {
        return Promise.resolve({
          age: response.age,
        });
      }

      return Promise.reject();
    });
  }

  // eslint-disable-next-line consistent-return
  async exportData(options = {}) {
    const type = options.type || getDefaultType();
    const decimal = options.decimal || '.';
    const { fileHandle } = options;

    const properties = {
      experimentId: this.experimentId,
      name: options.filename,
      format: type,
      decimal,
      filepath: options.filepath,
    };

    if (type === getDefaultType() && properties.name) await this.setExperimentName(properties.name);

    const response = await this.api.exportData(properties);

    if (!response.fileString) {
      throw new Error(`fileString wasn't returned`);
    }

    let contentToWrite = response.fileString;
    if (await this.archive) {
      await this.archive.writeXml(response.fileString);
      contentToWrite = await this.archive.exportDocument(); // buffer
    }

    const blob = new Blob([contentToWrite], {
      type: getMimeType(type),
    });

    if (!options.skipWrite)
      await this.fileIO.writeFile({
        fileHandle, // TODO: this is used in cordova and chrome
        filepath: properties.filepath, // TODO: this is used for electron
        blob,
      });

    if (type === getDefaultType()) this._updateUserFileMetaData(properties.filepath, response.age);

    return {
      age: response.age,
      content: response.fileString,
      contentToWrite,
      contentBlob: blob,
      filepath: properties.filepath,
      mimetype: getMimeType(type),
    };
  }

  checkRecoveryFile() {
    return this.api.checkRecoveryFile(this.experimentId);
  }

  async _doImport(file, filepath, importParams) {
    try {
      this.importing = true;

      const importResponse = await this.api.importData(
        importParams.path,
        importParams.format,
        importParams.content,
      );

      const { params, experimentId } = importResponse;
      const dataShareSourceResult = await this.getDataShareSource(experimentId);
      const sessionParams = {
        imported: true,
        sessionFileType: importParams.type,
        sessionFileFullPath: filepath,
        sessionName: file.name,
        experimentId: importResponse.experimentId,
      };

      if (params.specMode) {
        // Currently DC is tagged with experimentId in startSession(), but
        // that's too late for the below call; for now tag DC here and deal
        // with this better when redesigning the experiment setup sequence.
        this.dataCollection.experimentId = experimentId;
        await this.dataCollection.setSpectrumDataMode(params.specMode);
      }

      let sessionType = params.sessionType !== 'unknown' ? params.sessionType : undefined;
      const sessionSubtype = params.sessionSubtype || '';
      sessionParams.type = params.mode || '';

      if (dataShareSourceResult) {
        sessionParams.sourceId = dataShareSourceResult.attributes.sourceId;
        sessionParams.sourceName = dataShareSourceResult.attributes.name;
        sessionParams.sourceURI = dataShareSourceResult.attributes.uri;
        sessionType = 'DataShare';
      }

      // Restore data marks. This will sync our _dataMark array (DataMark mobx stores)
      // with what, if anything, is in the file.
      const dataMarkInfo = await this.api.fetchAllDataMarks(experimentId);
      dataMarkInfo.forEach(dmi => this._internalCreateDataMark(dmi));

      // Restore annotations in a similar fashion.
      const annotationInfo = await this.api.fetchGraphAnnotations(experimentId);
      annotationInfo.forEach(annoInfo =>
        this._internalRegisterAnnotation(new Annotation(annoInfo)),
      );

      // Restore manual fits.
      const manualFits = await this.api.fetchAllManualFits(experimentId);
      manualFits.forEach(fitInfo => {
        // Since manual fit doesn't store a range, we need to set that up here.
        const { valueRange: range } = this.getColumnById(fitInfo.baseColumnId);
        this._addManualFit(new ManualFit({ ...fitInfo, range }));
      });

      // Restore measurement tools.
      const { udm } = this;
      const _measurementTools = await udm.fetchObjects(
        experimentId,
        udm.objectTypes.MEASUREMENT_TOOL,
      );
      _measurementTools.forEach(toolInfo => {
        const measurement = createMeasurementFromUdm(toolInfo);
        this._internalRegisterMeasurementTool(measurement);
      });

      await this.startNewSession(sessionType, sessionParams, sessionSubtype);
      await this.dataCollection.setCollectionParams(params.mode, params.params, true);

      this.importing = false;
      this._updateUserFileMetaData(filepath);
      return true;
    } catch (err) {
      throw new Error(`Failed to close old session: ${err}`);
    }
  }

  /**
   *
   * @param {File} file File object
   * @param {String} filepath String
   * @param {*} params type, path, format (csv)
   * @returns {Promise} Promise<{ videoAttachment: Blob||undefined }>
   */
  async _importFromArchive(file, filepath) {
    // we can do this because we made it a promise that resolves to the thing
    console.assert(await this.archive, 'awaiting archival setup');
    // We only allow single video, so clear any others from the archive first
    await this.archive.clearAttachments('videos');
    const arrayBuffer = await readFileAsArrayBuffer(file);
    const buffer = new Uint8Array(arrayBuffer);
    console.assert(filepath);
    console.assert(file.name);
    console.assert(buffer);
    const xml = await this.archive.load({
      path: filepath,
      name: file.name,
      buffer,
    });
    console.assert(xml, `no xml was parsed from the archive ${filepath}`);
    const videoAttachmentPath = await this.archive
      .getAttachmentPaths()
      .find(att => att.startsWith('videos/'));

    if (videoAttachmentPath) {
      const videoAttachment = await this.archive.getAttachment(videoAttachmentPath);
      const blob = new Blob([videoAttachment.data], { type: videoAttachment.mimeType });
      return { xml, videoAttachment: blob };
    }

    return { xml, videoAttachment: undefined };
  }

  importDataThrottled(options = {}, interval = 500) {
    if (this.lastImportTimeStamp) {
      const now = Date.now();
      if (now < this.lastImportTimeStamp + interval) {
        return Promise.reject(new Error('Import within throttle threshold'));
      }
    }

    return this.importData(options);
  }

  async importData(options = {}) {
    const { fileData } = options;
    const type = options.type || getDefaultType();

    this.lastImportTimeStamp = Date.now();

    // the returned promise resolves with a boolean set to true on success, false on cancel or failure
    const readFile = async ({ file, filepath }) => {
      console.assert(file);
      const isCsv = file.name.toLowerCase().endsWith('.csv');

      const params = {
        type,
        path: file.name,
        format: isCsv ? 'csv' : '',
      };

      try {
        const filePathParts = filepath.split('/');
        const fileNameFromPath = filePathParts[filePathParts.length - 1];
        const knownTextFormat = isKnownTextFormat({
          mimetype: file.type,
          extension: getExtensionFromPath(
            fileNameFromPath.includes('.') ? fileNameFromPath : file.name,
          ),
        });

        await this.closeSession();

        // AMBL|IMBL|SMBL
        if (knownTextFormat) {
          const content = await readFileAsText(file);
          return this._doImport(file, filepath, { ...params, content });
        }

        const { xml, videoAttachment } = await this._importFromArchive(file, filepath);
        if (!xml) throw new Error(`no xml was parsed from ${filepath}`);

        await this._doImport(file, filepath, { ...params, content: xml });

        if (this.videoAttachmentImportHook && videoAttachment) {
          await this.videoAttachmentImportHook(videoAttachment);
        }

        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    };

    const continueNewSession = await this._confirmNewSession();
    if (!continueNewSession) return false;

    // resolves with a boolean -- true if the file was opened, false if not
    // i.e., we popped up the file/session dialog, and the user canceled it
    return readFile(fileData);
  }

  /**
   * @deprecated looks like no one uses this anymore?
   */
  getDocumentMetaData(options) {
    const format = options.type || '';

    return new Promise((resolve, reject) => {
      if (options.file === undefined) {
        resolve({});
      }

      options.file.entry.file(file => {
        const reader = new FileReader();

        reader.onerror = e => {
          console.error(`Loading file failed: ${e}`);
          reject(e);
        };

        reader.onload = e => {
          const path = options.file.entry.name;
          const content = e.target.result;

          if (!content) {
            reject(new Error(`File "${path}" has no content!`));
          } else {
            this.api
              .getDocumentMetaData({
                experimentId: this.experimentId,
                path,
                format,
                content,
              })
              .then(response => {
                resolve(response);
              })
              .catch(() => {
                console.error(`failed to load document at: ${path}`);
              });
          }
        };

        reader.readAsText(file);
      });
    });
  }

  /**
   * Getter for read-only property.
   * @type {UserMetaData}
   * @public
   * @readonly
   */
  get userFileMetaData() {
    return this._userFileMetaData;
  }

  /**
   * Private setter for user meta-data.
   * TODO: (jkelly) could these just be pulled out into
   * two properties of DataWorld directly?
   * @param {string} [filepath] file path of the current experiment.
   * @param {number} [age] document age from udm used to determine whether to
   * show the "Save file?" dialog to the user.
   * @private
   */
  _updateUserFileMetaData(filepath, age = null) {
    if (filepath != null) {
      this._userFileMetaData.filepath = filepath;
    }

    if (age != null) {
      this._userFileMetaData.age = age;
    }
  }

  /**
   * Reset the document age. Fetches the current doc age from UDM and sets
   * it as the baseline for determining whether to prompt a user to save the
   * file. This should only be used early in file-import / session-start-up for
   * cases where async back-end tasks would otherwise complete AFTER the
   * initial establishment of the baseline.
   */
  async resetDocumentAge() {
    // Block until all tasks in the back end worker queue have finished.
    await this.api.syncToBackEnd(this.experimentId);

    const age = await this.udm.getDocumentAge(this.experimentId);
    this._updateUserFileMetaData(null, age);
  }

  /**
   * [Getter] Session sub type is a string value that is persisted through UDM. It is for any extra
   * app-specific purpose you wish. It is in use by the following app(s):
   *
   * IA -- data collection "MODE", which is one of 'ia-gc', 'ia-cvs-cv', 'ia-cvs-be', 'ia-cvs-ocp', 'ia-pol-orm', 'ia-pol-ki'
   */
  get sessionSubtype() {
    return this._sessionSubtype;
  }

  /**
   * [Setter] Session sub type is a string value that is persisted through UDM. It is for any extra
   * app-specific purpose you wish. It is in use by the following app(s):
   *
   * IA -- data collection "MODE", which is one of 'ia-gc', 'ia-cvs-cv', 'ia-cvs-be', 'ia-cvs-ocp', 'ia-pol-orm', 'ia-pol-ki'
   * @param {string} newValue -- the new value you are setting. This will propagate the changes to the back end.
   */
  set sessionSubtype(newValue) {
    if (newValue !== this._sessionSubtype) {
      this._sessionSubtype = newValue;
      this.api.setSessionSubtype({
        experimentId: this.experimentId,
        subtype: newValue,
      });
      this.emit('session-subtype-changed');
    }
  }

  convertToVideoAbsoluteTime(adjustedTime) {
    return this.api.convertVideoTime(true, adjustedTime);
  }

  convertToVideoAdjustedTime(absoluteTime) {
    return this.api.convertVideoTime(false, absoluteTime);
  }

  /**
   * Stores the Notes text in udm.
   * @param {string} text
   * @returns {Promise} resolves when call completes, rejects if there's an err.
   */
  setNotesText(text) {
    return this.api.setNotesState({
      experimentId: this.experimentId,
      text,
      visible: true,
    });
  }

  /**
   * Fetches the Notes text from udm store.
   * @returns {Promise<string>} the Notes text
   */
  async getNotesText() {
    const text = (await this.api.getNotesState(this.experimentId))?.text || '';
    return unescape(text);
  }

  setCustomCurveFit(name, expression) {
    return this.api.setCustomCurveFit({
      experimentId: this.experimentId,
      name,
      expression,
    });
  }

  getCustomCurveFits() {
    return this.api.getCustomCurveFits(this.experimentId);
  }

  getCalcColumnFunctions() {
    return this.api.getCalcColumnFunctions(this.experimentId);
  }

  getUserConstants() {
    return this._userConstants;
  }

  /**
   * @param {ConstantProps} constant
   * @returns {promise<number>} that resolves to UDM id of the constant
   *
   * For any of the optinal properties of ConstantProps not supplied values will be reset to the UDM defaults
   */
  addUserConstant(constant) {
    // Check the constant name has the $ prefix -- this is a constrain imposed by the
    // FE parser grammar so validate it here.
    if (!constant.name || !constant.name.charAt || constant.name.charAt(0) !== '$') {
      return Promise.reject(new Error('constant name must begin with $'));
    }

    return this.api.addUserConstant(this.experimentId, constant).then(constantId => {
      this._userConstants.push({ constantId, ...constant });
      this.emit('user-constants-updated');
      return constantId;
    });
  }

  /**
   * @param {number} constantId UDM id of the constant to change
   * @param {ConstantProps} constant
   * @returns {promise<>}
   *
   * For any of the optinal properties of ConstantProps not supplied values will be reset to the UDM defaults
   */
  changeUserConstant(constantId, constant) {
    // Check the constant name has the $ prefix -- this is a constrain imposed by the
    // FE parser grammar so validate it here.
    if (!constant.name || !constant.name.charAt || constant.name.charAt(0) !== '$') {
      return Promise.reject(new Error('constant name must begin with $'));
    }
    return this.api.changeUserConstant(this.experimentId, constantId, constant).then(() => {
      const i = this._userConstants.findIndex(c => c.constantId === constantId);
      if (i >= 0) {
        this._userConstants[i] = { constantId, ...constant };
        this.emit('user-constants-updated');
      }
    });
  }

  /**
   * @param {number} constantId UDM id of the constant to delete
   * @returns {promise<>}
   */
  deleteUserConstant(constantId) {
    return this.api.deleteUserConstant(this.experimentId, constantId).then(() => {
      const i = this._userConstants.findIndex(c => c.constantId === constantId);
      if (i >= 0) {
        this._userConstants.splice(i, 1);
        this.emit('user-constants-updated');
      }
    });
  }

  /**
   * Adds an auxiliary graph to UDM.
   * @param { String } parentGraphId id of graph that will own or contain the new auxiliary graph.
   * @param { String } auxGraphType 'fft' or 'histogram'.
   * @param { Object } auxInfo contains either`fftInfo` or `histogramInfo` (or both, but expect undefined results) which determines what kind of auxiliary graph to create.
   * @returns
   */
  addAuxGraph(parentGraphId, auxGraphType, auxInfo) {
    return this.api.addAuxGraph(this.experimentId, parentGraphId, auxGraphType, auxInfo);
  }

  /**
   * Updates the data of an existing auxiliary graph.
   * @param { String } auxGraphId identifier of the aux graph
   * @param { Object } auxInfo new info contains either `fftInfo` or `histogramInfo` objects
   * @returns true or false depending on success of operation.
   */
  updateAuxGraphInfo(auxGraphId, auxInfo) {
    return this.api.updateAuxGraphInfo(this.experimentId, auxGraphId, auxInfo);
  }

  /**
   * Remove an auxiliary graph from the back end.
   * @param {*} auxGraphId id of aux graph to be removed.
   */
  deleteAuxGraph(auxGraphId) {
    return this.api.deleteAuxGraph(this.experimentId, auxGraphId);
  }

  /**
   * Returns an info dictionary containing graph info for a corresponding udmId.
   * @param {Number} udmId id of a graph info that was cached on file open.
   * @returns {Object} object containing persisted grpah info.
   */
  getInfoForGraphId(udmId) {
    return this._graphInfos[udmId];
  }

  /**
   * Add data mark(s) to non-time column(s) in a particular data set or optionally specified columns.
   * DataMarks are managed by mobx, so you can observe them and react appropriately. They are also being observed for changes which propagate
   * to the back end persistence store, automatically -- you do not have to do this explicitly.
   * @param { Number } rowIndex row number corresponding to
   * @param {*} dataSource If Array of columnIds, will create DataMark for each. If a single dataSetId, will create dataMarks for all non-time columns in the data set.
   * @param {*} graphId Optional graphId. If nonzero, will add datamark as shown and un-collapsed on the corresponding graph.
   * @param {Boolean} isCollected true if this mark was added during collection, false if not.
   * @returns {Array<DataMark>} newly created DataMark instances. Client may keep references to these and call methods on them directly and they will update the store automatically.
   */
  async addDataMark(rowIndex, dataSource, graphIds = [], isCollected = true) {
    const graphAppearanceInfos = graphIds.map(graphUdmId => {
      return {
        graphId: parseInt(graphUdmId),
        isShown: true,
        isCollapsed: false,
        position: { x: -1, y: -1 },
      };
    });

    const info = {
      experimentId: this.experimentId,
      id: 0,
      dataSetId: parseInt(dataSource),
      rowIndex,
      type: isCollected ? DataMark.COLLECTED : DataMark.USER_TAGGED,
      appearanceInfo: graphAppearanceInfos,
    };

    const datamarkId = await this.api.addDataMark(info);
    return this._internalCreateDataMark({ ...info, id: datamarkId });
  }

  /**
   * Creates a data mark from expected infomation. This is specifically used by data sharing clients.
   * @param {Object} info
   * @returns {DataMark} a brand new shiny data mark object.
   */
  async createDataMarkForDataSharing(info) {
    const datamarkId = await this.api.addDataMark({ ...info, experimentId: this.experimentId });
    return this._internalCreateDataMark({ ...info, id: datamarkId });
  }

  /**
   * Remove a datamark.
   * @param {DataMark} dataMark remove a data mark from the store and stop observing it.
   */
  async removeDataMark(dataMark) {
    // Remove from store
    await this.api.removeDataMark({
      experimentId: this.experimentId,
      id: dataMark.id,
    });

    // Call and then delete the autorun stop function for the dataMark.
    DataWorld._autorunStopFunctions[dataMark.id]();
    delete DataWorld._autorunStopFunctions[dataMark.id];

    // Remove from the list.
    const index = this._dataMarks.findIndex(dm => dm.id === dataMark.id);
    if (index >= 0) {
      // This method is declared as a mobx action but I think since it's async, it requires an explicit action?
      runInAction(() => this._dataMarks.splice(index, 1));
    }
  }

  /**
   * Fetches a data mark with the passed-in identifier.
   * @param {Number} dataMarkId udm ID of the given data mark.
   * @returns {DataMark?} data mark with the given id, or NULL.
   */
  getDataMarkWithId(dataMarkId) {
    return this._dataMarks.find(m => m.id === dataMarkId);
  }

  /**
   * Returns graphs associated with a graph id.
   * @param {number} graphId
   * @returns {DataMark[]} returns an array of datamarks that appear on the
   * given graph.
   */
  getDataMarksForGraph(graphId) {
    const bleh = this._dataMarks.filter(dm => dm.containsGraph(graphId));
    return bleh;
  }

  /**
   * Creates and adds DataMark from passed-in info.
   * @private
   * @param {Object} peristenceInfo contains requisite information for creating a DataMark.
   */
  _internalCreateDataMark(peristenceInfo) {
    const dataMark = new DataMark(peristenceInfo);
    this._dataMarks.push(dataMark);

    // Observe changed properties:
    const obvs = autorun(async firstTime => {
      const info = { experimentId: this.experimentId, ...dataMark.udmExport };

      if (!this.experimentId || firstTime) return;

      try {
        await this.api.updateDataMark(info);
      } catch (error) {
        console.warn(`Datamark autorun: got error trying to update mark id ${info.id ?? '???'}`);
      }
    });

    // Record the autorun stop function:
    DataWorld._autorunStopFunctions[dataMark.id] = obvs;

    return dataMark;
  }

  /**
   * @private use the dataMarks accessor.
   */
  _dataMarks = [];

  /**
   * @type {Object<number, function>} Contains autorun stop functions, keyed by udmId.
   * @private
   */
  static _autorunStopFunctions = {};

  /**
   * @type {Annotation[]} observable array of Annotation objects.
   */
  annotations = [];

  /** @type {ManualFit[]} list of manual fits */
  manualFits = [];

  /**
   * Returns raw udm data from the persistence store.
   * @returns {[Object]} array of raw udm data which can be used to construct Meter object from persisted information.
   * @see `Meter.udmImport()`
   */
  getStoredMeterInfo(expId = this.experimentId) {
    return this.api.getAllMeters(expId);
  }

  /**
   * Creates a Meter instance. This Meter instance is not registered -- remember to call `addMeter()` to do so.
   * @param {MeterProps} props for intializing the new meter.
   * @deprecated Please use `new Meter(props)` instead.
   * @returns {Meter}
   */
  // TODO consider removing this or renaming it createMeterInstance
  // eslint-disable-next-line class-methods-use-this
  createMeter(props = {}) {
    return new Meter(props);
  }

  /**
   * a check to see if an incoming meter already exits
   * @param {Meter} existingMeter the existing meter
   * @param {Meter} checkMeter the incoming meter to check
   * @returns {Boolean}
   */
  dupeMeterCheck(existingMeter, checkMeter) {
    const exisitingColumn = this.getColumnById(existingMeter.columnId);
    const checkColumn = this.getColumnById(checkMeter.columnId);
    return (
      existingMeter.id === checkMeter.id ||
      (exisitingColumn?.groupId &&
        Number(exisitingColumn.groupId) === Number(checkColumn.groupId)) ||
      Number(existingMeter.columnId) === Number(checkMeter.columnId)
    );
  }

  /**
   * Add meter to internal registry and persistence store
   * @param {Meter} meterInstance
   * @returns {Meter} the meter instance you passed in, with updated `id` property.
   */
  async addMeter(meterInstance, skipUDM = false) {
    // Check that meter doesn't already exist before adding it.
    const exisitingMeter = this.meters.find(meter => this.dupeMeterCheck(meter, meterInstance));
    if (exisitingMeter) {
      console.warn(`Meter ${meterInstance.id} already exists in list. Ignoring.`);
      return exisitingMeter;
    }

    if (!skipUDM) {
      const newId = await this.api.addMeter({
        experimentId: this.experimentId,
        ...meterInstance.udmExport,
      });
      meterInstance.id = newId;
    }

    this.setMeterPosition(meterInstance);

    if (!this.meters.some(meter => this.dupeMeterCheck(meter, meterInstance))) {
      runInAction(() => this.meters.push(meterInstance));

      this.emit('meter-added', meterInstance);
    }

    const observer = autorun(async firstTime => {
      // eslint-disable-next-line no-unused-vars
      const info = { experimentId: this.experimentId, ...meterInstance.udmExport };

      if (firstTime) return;

      try {
        await this.updateMeter(meterInstance);
      } catch (error) {
        console.error(error);
      }
    });

    DataWorld._autorunStopFunctions[meterInstance.id] = observer;

    return meterInstance;
  }

  /**
   * Main bottleneck for changing the visibility of a single meter. This will
   * make sure to update the meter's and column group's visibility in a
   * well-ordered and hopefully clean fashion, without worrying about
   * round-tripping the visibility state through UDM etc.
   * @param {number} meterId
   * @param {boolean} visibility
   */
  async userSetMeterVisibility(meterId, visibility) {
    const meterInstance = this.getMeterById(meterId);
    if (!meterInstance) {
      console.warn(`userSetMeterVisibility() could not find meter with id ${meterId}`);
      return;
    }

    const oldVis = meterInstance.isVisibleInMeterPane;
    // Set the new value on the meter and store it.
    meterInstance.overrideMeterVisibility(
      visibility ? Meter.VisibilityOverride.FORCE_SHOWN : Meter.VisibilityOverride.FORCE_HIDDEN,
    );
    await this.updateMeter(meterInstance);

    // Make sure previously invisible meters get a position value.
    if (!oldVis && visibility) this.setMeterPosition(meterInstance);

    // Find a group with the matching id, and
    const { group } = this.getAllColumns().find(column => column.meterId === meterId);
    if (group) {
      await this.updateColumnGroup(group.id, { metered: visibility });
    }
  }

  /**
   * Re-computes a meter's `position`. This will both correctly set the position
   * based on visibility etc., but will also store these changes in udm.
   * @param {import('@common/mobx-stores/Meter.js').Meter} meterInstance
   */
  setMeterPosition(meterInstance) {
    //  meters with position keep them. e.g. restoring meter on file open
    if (!meterInstance.isVisibleInMeterPane) {
      meterInstance.position = null;
    } else if (!meterInstance.position) {
      meterInstance.position = findNextMeterPosition(this.meters);
    }
    this.updateMeter(meterInstance);
  }

  /**
   * Swaps positions between specified meter and meter with next highest position
   * @param {import('@api/common/mobx-stores/Meter.js').Meter} meter
   */
  incrementMeterPosition(meter) {
    // User has changed visibility/position. Change the meter rule to reflect this.
    if (incrementMeterPosition(meter, this.meters))
      this.meters = [...this.meters.sort(MeterPositionCompare)];
  }

  /**
   * Swaps positions between specified meter and meter with next lowest position
   * @param {import('@common/mobx-stores/Meter.js').Meter} meter
   */
  decrementMeterPosition(meter) {
    // User has changed visibility/position. Change the meter rule to reflect this.
    if (decrementMeterPosition(meter, this.meters))
      this.meters = [...this.meters.sort(MeterPositionCompare)];
  }

  /**
   * Update meter
   * @param {import('@common/mobx-stores/Meter.js').Meter} meterInstance Meter whose fields
   * have changed and need to be stored in udm.
   * @returns {Promise} either succeeds or throws.
   */
  updateMeter(meterInstance) {
    return this.api.updateMeter({
      experimentId: this.experimentId,
      ...meterInstance.udmExport,
    });
  }

  _updateAllMeters() {
    this.meters.forEach(meter => this.updateMeter(meter));
  }

  /**
   * Unregister a meter from the interal registry and remove it from the persistence store.
   * @param {number} id identifier of the meter.
   */
  removeMeter(id) {
    /*
     * In the new world, sensors, and hence meters, are associated with an experiment, so we need to
     * remove them on experiment closing, but we don't need to (should not) call into the backend.
     */
    const meterInstance = this.getMeterById(id);
    if (!meterInstance) {
      console.warn(`Cannot remove meter with id ${id} as it is not in the list.`);
      return;
    }

    meterInstance.removeAllBindings();
    this.meters = this.meters.filter(meter => meter.id !== id);
    if (!this.sessionClosing) {
      this.api.removeMeter({
        experimentId: this.experimentId,
        id,
      });
    }
    this.emit('meter-removed', meterInstance);
  }

  /**
   * Remove all meters that were registered with `addMeter()`
   */
  removeAllMeters() {
    const ids = this.meters.map(m => m.id);
    ids.forEach(id => this.removeMeter(id));
    console.assert(this.meters.length === 0);
  }

  /**
   * Fetch a registered meter from the internal registry.
   * @param {number} id identifier of the Meter instance.
   * @returns {Meter} will return null if not found.
   */
  getMeterById(id) {
    return this.meters.find(meter => id === meter.id);
  }

  getMeterByColumnId(columnId) {
    return this.meters.find(meter => meter.columnId === columnId);
  }

  /**
   * Returns number of points used for derivative calculations
   * @returns {Promise<Number>}.
   * @throws {Error} describes what went wrong.
   */
  getDerivativePoints() {
    return this.api.getDerivativePoints(this.experimentId);
  }

  /**
   * Sets number of points to use for derivative calculations
   * @returns {Promise<>}.
   * @throws {Error} describes what went wrong.
   */
  setDerivativePoints(derivativePoints) {
    return this.api.setDerivativePoints({
      experimentId: this.experimentId,
      derivativePoints: parseInt(derivativePoints),
    });
  }

  /**
   * Returns number of points used for smoothing operations
   * @returns {Promise<Number>}.
   * @throws {Error} describes what went wrong.
   */
  getSmoothingPoints() {
    return this.api.getSmoothingPoints(this.experimentId);
  }

  /**
   * Sets number of points to use for smoothing operations
   * @returns {Promise<>}.
   * @throws {Error} describes what went wrong.
   */
  setSmoothingPoints(smoothingPoints) {
    return this.api.setSmoothingPoints({
      experimentId: this.experimentId,
      smoothingPoints: parseInt(smoothingPoints),
    });
  }

  /**
   * Fetches layout properties stored in the UDM Page section.
   * @param {number } experimentId UDM id of the experiment
   * @returns {Promise<import('../api/DataWorldAPI.js').LayoutFlags>} flags
   * which can be used to update the vstLayoutStore singleton on file->open.
   * @throws {Error} describes what went wrong.
   */
  getLayoutProperties() {
    return this.api.getLayoutProperties(this.experimentId);
  }

  /**
   * Stores layout state, e.g. from `vstLayoutStore` in the udm document Page
   * section.
   * @param {number} experimentId UDM id of the experiment
   * @param {import('../api/DataWorldAPI.js').LayoutFlags} flags containing
   * layout state.
   * @returns {Promise<>} success indicates
   * @throws {Error} describes what went wrong.
   */
  setLayoutProperties(flags) {
    return this.api.setLayoutProperties(this.experimentId, flags);
  }

  /**
   * Sets or clears a column's 'frozen' property. Passing in true will "freeze"
   * a column: the column will not be automatically deleted when its associated
   * sensor is disconnected. Passing in false, will allow the column to be
   * automatically deleted when its sensor is removed, the default behavior.
   *
   * Note this property only applies to empty sensor columns, and only affects
   * automatic behavior. Columns may still be explicitly deleted. This property
   * does not persist.
   *
   * @param {number | string} columnId udmId of the column to freeze.
   * @param {bool} freeze true to freeze the column and false to thaw the
   * column.
   * @returns {Promise<>}
   * @throws {Error} describing what went wrong.
   */
  freezeColumn(columnId, freeze = true) {
    // The frozen value will roundtrip back to us and update the column's
    // `frozen` property, so that it may be inspected anywhere in the front end.
    return this.api.freezeColumn(toUdmId(columnId), freeze, this.experimentId);
  }

  /**
   * Gets measurement tools of a specific type.
   * @param {import ('../../mobx-stores/Measurement.js').MeasurementType } type
   * of object to return.
   * @returns {import ('../../mobx-stores/Measurement.js').Measurement [] } array
   * of measurements of the specific type.
   * @note Implementation guarantees that ordering of returned items remains
   * consistent across subsequent calls.
   */
  getMeasurementToolsByType(type) {
    return this._measurementTools.filter(tool => tool.type === type);
  }

  /**
   * Creates and adds measurment tool to the udm store.
   * @param {object} initialValues values for setting up the new measurement.
   */
  async addMeasurementTool(initialValues) {
    const { udm } = this;
    const measurement = createMeasurementFromUdm(initialValues);
    measurement.udmId = await udm.addObject(
      this.experimentId,
      udm.objectTypes.MEASUREMENT_TOOL,
      measurement.toUDM(),
    );
    this._internalRegisterMeasurementTool(measurement);
  }

  /**
   * Removes a measurement tool from the store.
   * @param {Measurement} measurement tool to remove.
   */
  removeMeasurementTool(measurement) {
    const { udmId } = measurement;
    this._measurementTools = this._measurementTools.filter(tool => tool.udmId !== udmId);
    DataWorld._autorunStopFunctions[udmId]();
    delete DataWorld._autorunStopFunctions[udmId];
    this.udm.removeObject(this.experimentId, udmId);
  }

  /**
   * Add measurement to list AND sets up property observation.
   * @param {Measurement} measurement
   * @private
   */
  _internalRegisterMeasurementTool(measurement) {
    this._measurementTools.push(measurement);
    DataWorld._autorunStopFunctions[measurement.udmId] = autorun(firstTime => {
      const udmData = measurement.toUDM();
      if (!firstTime) this.udm.updateObject(this.experimentId, measurement.udmId, udmData);
    });
  }

  /**
   * Find the id of any sensor which is associated with a column. If the
   * column itself doesn't have a valid sensorId, then optionally will look for
   * sensors of adjacent calculation dependent columns. This will only search
   * adjacent columns, e.g. it won't iterate all calc column dependencies.
   * @param {string} columnId column whose sensor id or adjacent dependent
   * sensor id to look for.
   * @param {boolean} [includeCalcColumns] if true, search calc dependent
   * columns.
   * @returns {number} sensor id, or 0 if no sensor id could be found.
   */
  getSensorIdForColumn(columnId, includeCalcColumns = true) {
    const column = this.getColumnById(columnId);
    if (!column) return 0;

    const { group, sensorId } = column;

    if (sensorId || !includeCalcColumns) return sensorId;

    return (
      group.calcDependentGroups
        .map(groupId => this.getColumnGroupById(groupId))
        .find(group => group.sensorId !== 0)?.sensorId ?? 0
    );
  }
}

Object.defineProperties(DataWorld.prototype, {
  sessionSourceName: {
    get() {
      let name = '';
      let match;

      if (this.session && this.session instanceof DataShareSession) {
        if (this.session.client.sourceName) {
          name = this.session.client.sourceName;
        } else if (this.session.client.serverURL) {
          match = this.session.client.serverURL.match(/^\s*(http:\/\/)?\s*([^:]+):?(\d+)?$/);
          if (match && match.length === 4) {
            // eslint-disable-next-line prefer-destructuring
            name = match[2];
          }
        }
      }
      return name;
    },
  },
  sessionHostAddress: {
    get() {
      let hostAddress = '';
      if (this.session && this.session instanceof DataShareSession) {
        hostAddress = this.session.client.serverURL;
      }
      return hostAddress;
    },
  },

  sessionType: {
    // TODO tie this to sessionFactory so that we can get names based on available actual sessiontypes.
    get() {
      let sessionType = null;
      if (this.session) {
        if (this.session instanceof DataCollectionSession) {
          sessionType = 'DataCollection';
        } else if (this.session instanceof DataShareSession) {
          sessionType = 'DataShare';
        } else if (this.session instanceof ManualSession) {
          sessionType = 'ManualEntry';
        }
      }

      return sessionType;
    },
  },
  canControl: {
    get() {
      return this._canControl;
    },
    set(canControl) {
      this._canControl = canControl;
    },
  },
});

/**
 * @typedef {Object} MeterProps
 * @param color {string} color string
 * @param enabled {string} is meter enabled
 * @param visible {string} is meter visible TODO (jk) are visible and enabled the same thing? And is visible redundant, e.g. couldn't we just delete the meter?
 * @param id {number} udm id of the meter
 * @param name {string} name of the meter
 * @param position {number} position the meter appears in the list. TODO (jk) we need to assign this number based on the order of the BlockInfo meter list.
 * @param units {string} units to display with the meter
 * @param value {string} current live value of the meter.
 * @param wavelength {string} SA property describing what at what wavelength the value was captured at
 * @param size {string} size, one of 'medium' or 'large'
 * @param sensorAutoId {number} auto-id of the sensor (used for matching meters with sensors)
 * @param sensorName {string} name of the sensor (used for matching meters with sensors)
 * @param sensorId {string} sensor's id.
 * @param columnId {string} associated column id.
 */

/**
 * @typedef {Object} SessionConfig
 * @property {string} type
 * @property {Boolean} [ignoreSensors]
 * @property {Boolean} [imported]
 * @property {Number} [fileFormat]
 * @property {string} [sessionFileFullPath]
 * @property {string} [sessionFileType]
 * @property {string} [sessionName] restored from file import or set inside startNewSession
 * @property {string} [sessionSubtype] set inside startNewSession
 * @property {string} [sessionType] set inside startNewSession
 * @property {string} [initialDataSetName] set inside startNewSession
 */

/** @typedef {Object} ConstantProps
 * @property {string} constant.name the name of the constant
 * @property {number} constant.value value of the constant
 * @property {number} [constant.increment] increment value of the constant (when adjusted with a spinner)
 * @property {number} [constant.digits] number of precision digits
 * @property {boolean} [constant.editable] whether the constant is user editable
 * @property {string} [constant.units] the units of the constant
 */
